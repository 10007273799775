import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { groupBy, isEmpty, uniqBy } from "lodash";
import { useGetFlightSellingPrice } from "./getFlightSellingPrice";
import { Currency } from "../../../Reducers/objects/currency";
import { ProductPrice } from "../../Itinerary/objects/productPrice";
import { AppState } from "../../../Reducers/Reducers";
import { isProductPackaged } from "./isProductPackaged";
import { isProductSamePackage } from "./isProductSamePackage";

type Prices = {
    totalCost: {
        currency: Currency | undefined,
        cost: number,
    }[],
    totalCartCost: number,
    totalInsurance: number,
    margin: {
        selling_currency: Currency | null,
        purchase: number;
        markup: boolean;
        purchase_converted: number;
        selling: number;
        value: number;
        percent: number;
        currency: null;
        rate_type: string;
        ids: number[];
    },
    surcom: {
        static: number;
        purchase: number;
        selling: number;
        value: number;
        percent: number;
        currency: Currency | null;
    },
    flightTaxes: number,
    hasBookedItems: number,
    itemsCount: number,
    advancePayment: number,
    newProduct: unknown,
    modifiedProduct: unknown,
    paymentDone: number,
    vat: number
}

export function useCartTotalPrices(unFormated?: boolean, agency?: boolean): Prices {
    const { t, i18n } = useTranslation();
    const cars = useSelector((state: AppState) => state.cars_search.cart);
    const accommodations = useSelector((state: AppState) => state.accommodation.sort_cart);
    const manual_products = useSelector((state: AppState) => state.cart.manual_item_list);
    const pois = useSelector((state: AppState) => state.poi.cart);
    const assistances = useSelector((state: AppState) => state.cart.assistance_cart);
    const transfers = useSelector((state: AppState) => state.transfers.cart);
    const flights = useSelector((state: AppState) => state.flight.cart);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const user = useSelector((state: AppState) => state.user.user);
    const currency_list = useSelector((state: AppState) => state.base.currency_list);
    const currency = useSelector((state: AppState) => state.trip.currency);
    const all_data = useSelector((state: AppState) => state.trip.all_data);
    const packagesFromProducts = useMemo(() => {
        return uniqBy(
            accommodations?.map((item) => {
                return {
                    stack_number: item.stack_number,
                    stack_info_id: item.stack_info_id
                };
            }).concat(
                cars?.map((item) => {
                    return {
                        stack_number: item.stack_number,
                        stack_info_id: item.stack_info_id
                    };
                }) ?? []
            ).concat(
                manual_products?.map((item) => {
                    return {
                        stack_number: item.stack_number,
                        stack_info_id: item.stack_info_id
                    };
                }) ?? []
            ).concat(
                pois?.map((item) => {
                    return {
                        stack_number: item.stack_number,
                        stack_info_id: item.stack_info_id
                    };
                }) ?? []
            ).concat(
                assistances?.map((item) => {
                    return {
                        stack_number: item.stack_number,
                        stack_info_id: item.stack_info_id
                    };
                }) ?? []
            ).concat(
                transfers?.map((item) => {
                    return {
                        stack_number: item.stack_number,
                        stack_info_id: item.stack_info_id
                    };
                }) ?? []
            ).concat(
                flights?.map((item) => {
                    return {
                        stack_number: item.stack_number,
                        stack_info_id: item.stack_info_id
                    };
                }) ?? []
            ).filter((packageItem) => {
                const stackInfo = trip?.stack_info?.find((item) => {
                    return isProductSamePackage(
                        {
                            stack_number: item.stack_number,
                            stack_info_id: item.id
                        },
                        packageItem
                    );
                });
                return isProductPackaged({
                    product: packageItem,
                    stackInfos: trip?.stack_info ?? null
                }) &&
                (
                    !isProductPackaged({
                        product: packageItem,
                        stackInfos: trip?.stack_info ?? null,
                        connected: true
                    }) ||
                    (
                        stackInfo &&
                        (stackInfo.is_available || (stackInfo.booking_status && stackInfo.booking_status.status_booking === 'CONFIRMED')) &&
                        stackInfo.is_displayed &&
                        (stackInfo.booking_status === null || (stackInfo.booking_process_state !== 'CANCEL' && stackInfo.booking_status.status_booking !== 'CANCELLED'))
                    )
                );
            }) ?? [],
            (item) => `${item.stack_number}-${item.stack_info_id}`
        );
    }, [
        accommodations,
        cars,
        manual_products,
        pois,
        assistances,
        transfers,
        flights
    ]);
    const [getFlightSellingPrice] = useGetFlightSellingPrice();

    return useMemo(() => {
        const getMargin = (tmp_margin: any, tmp_surcom: any, prices: any, id?: any) => {
            const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
            const client_id = JSON.parse(localStorage.getItem('config') ?? '{}').client_id;
            if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i].is_surcom) {
                        tmp_surcom.purchase += parseFloat(prices[i].purchase_price) * parseFloat(prices[i].factor_used);
                        tmp_surcom.selling += parseFloat(prices[i].selling_price);
                        tmp_surcom.currency = currency_list.find(currency => currency.id === prices[i].selling_currency);
                        tmp_surcom.percent = parseFloat(prices[i].percentage_between);
                        break;
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i].master_price) {
                        tmp_margin.purchase += parseFloat(prices[i].purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i].purchase_price) * parseFloat(prices[i].factor_used);
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i].purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i].selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i].selling_currency);
                        tmp_margin.percent = parseFloat(prices[i].percentage_between);
                        tmp_margin.ids.push(id);
                        break;
                    }
                }
                if (!tmp_margin.ids.includes(id)) {
                    for (let i = 0; i < prices.length; i++) {
                        if (prices[i].owner === user?.client) {
                            tmp_margin.purchase += parseFloat(prices[i].purchase_price);
                            tmp_margin.purchase_converted += parseFloat(prices[i].purchase_price) * parseFloat(prices[i].factor_used);
                            tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i].purchase_currency);
                            tmp_margin.selling += parseFloat(prices[i].selling_price);
                            tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i].selling_currency);
                            tmp_margin.percent = parseFloat(prices[i].percentage_between);
                            tmp_margin.ids.push(id);
                            break;
                        }
                    }
                }
            } else {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i].owner === user?.client || (agency && prices[i].owner !== client_id)) {
                        tmp_margin.purchase += parseFloat(prices[i].purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i].purchase_price) * parseFloat(prices[i].factor_used);
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i].purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i].selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i].selling_currency);
                        tmp_margin.percent = parseFloat(prices[i].percentage_between);
                        tmp_margin.id = prices[i].id;
                        break;
                    }
                }
            }
        };
    
        const priceToDisplay = (price: any) => {
            return {
                cost: parseFloat(price.selling_price),
                currency: currency_list.find(currency => currency.id === price.selling_currency)
            };
        };
    
        const getPrice = (prices: any, is_tva = true) => {
            const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
            if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
                if (['visiteurs', 'volonline'].includes(quotation_code)) {
                    for (let i = 0; i < prices.length; i++) {
                        if (prices[i].is_network) {
                            return priceToDisplay(prices[i]);
                        }
                    }
                } else if (is_tva) {
                    for (let i = 0; i < prices.length; i++) {
                        if (prices[i].is_tva) {
                            return priceToDisplay(prices[i]);
                        }
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i].is_surcom) {
                        return priceToDisplay(prices[i]);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i].master_price) {
                        return priceToDisplay(prices[i]);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i].owner === user?.client) {
                        return priceToDisplay(prices[i]);
                    }
                }
            } else {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i].is_tva) {
                        return priceToDisplay(prices[i]);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i].owner === user?.client) {
                        return priceToDisplay(prices[i]);
                    }
                }
            }
            return {
                cost: 0,
                currency: currency
            };
        };
    
        const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

        let total_cost: {
            currency: Currency | undefined,
            cost: number
        }[] = [];
        let total_manual_products: any = [];
        let total_accommodations: any = [];
        let total_cars: any = [];
        let total_pois: any = [];
        let total_assistances: any = [];
        let total_transfers: any = [];
        let total_flights: any = [];
        let flight_taxes = 0;
        let nb_items = 0;
        let has_booked_items = 0;
        let tmp_margin = {
            purchase: 0,
            markup: false,
            purchase_converted: 0,
            selling_currency: null,
            selling: 0,
            value: 0,
            percent: 0,
            currency: null,
            rate_type: 'PER',
            ids: []
        };
        let tmp_surcom = {
            static: 0,
            purchase: 0,
            selling: 0,
            value: 0,
            percent: 0,
            currency: null
        };
        let new_product: any = [];
        let modified_product: any = [];
        let advance_payment = 0;
        let payment_done = 0;
        let tva = 0;
        let totalInsurance = 0;
        let adjustment_product = manual_products?.find(product => product.product_type === 20);
        let status_contract_confirmed_date = adjustment_product === undefined ? window.moment(trip?.status_contract_confirmed_date ?? undefined).local() : window.moment(adjustment_product.modified_date);
        
        //add global prices
        const customCostFlightPrices = trip?.prices_flight?.filter((price) => {
            return !isEmpty(price) &&
                   !isProductPackaged({
                       product: price,
                       stackInfos: trip?.stack_info ?? null
                   }) && price.id;
        }) ?? [];
        const customCostTerrestrialPrices = trip?.prices_terrestrial?.filter((price) => {
            return !isEmpty(price) &&
                   !isProductPackaged({
                       product: price,
                       stackInfos: trip?.stack_info ?? null
                   }) && price.id;
        }) ?? [];
        if (customCostFlightPrices.length > 0) {
            let price = getPrice(customCostFlightPrices);
            getMargin(tmp_margin, tmp_surcom, customCostFlightPrices, `${trip?.id}-flight`);
            let matched = false;
            total_cost.map((currency_cost: any) => {
                if (currency_cost.currency.id === price.currency?.id) {
                    matched = true;
                    currency_cost.cost += price.cost + (trip?.flight_taxes ? parseFloat(trip.flight_taxes) : 0);
                }
            });
            if (!matched) {
                total_cost.push({
                    cost: price.cost + (trip?.flight_taxes ? parseFloat(trip.flight_taxes) : 0),
                    currency: price.currency
                });
            }
        }
        if (customCostTerrestrialPrices.length > 0) {
            let price = getPrice(customCostTerrestrialPrices);
            getMargin(tmp_margin, tmp_surcom, customCostTerrestrialPrices, `${trip?.id}-terrestrial`);
            let matched = false;
            total_cost.map((currency_cost: any) => {
                if (currency_cost.currency.id === price.currency?.id) {
                    matched = true;
                    currency_cost.cost += price.cost;
                }
            });
            if (!matched) {
                total_cost.push({
                    cost: price.cost,
                    currency: price.currency
                });
            }
        }
        const hasCustomCost = (customCostFlightPrices.length > 0 || customCostTerrestrialPrices.length > 0);
        
        //add package prices if there is no global price
        const packageFlightPrices = trip?.prices_flight?.filter((price) => {
            return !isEmpty(price) &&
                   isProductPackaged({
                       product: price,
                       stackInfos: trip?.stack_info ?? null
                   }) && price.id &&
                   packagesFromProducts.findIndex((packageItem) => {
                       return isProductSamePackage(
                           packageItem,
                           price
                       );
                   }) >= 0;
        }) ?? [];
        const packageTerrestrialPrices = trip?.prices_terrestrial?.filter((price) => {
            return !isEmpty(price) &&
                   isProductPackaged({
                       product: price,
                       stackInfos: trip?.stack_info ?? null
                   }) && price.id &&
                   packagesFromProducts.findIndex((packageItem) => {
                       return isProductSamePackage(
                           packageItem,
                           price
                       );
                   }) >= 0;
        }) ?? [];

        if (
            packageFlightPrices.length > 0 &&
            !hasCustomCost
        ) {
            const grouppedPrices = groupBy(
                packageFlightPrices,
                (item) => `${item.stack_number}-${item.stack_info_id}`
            );
            const pricesGroup = Object.values(grouppedPrices);
            for (const item of pricesGroup) {
                let price = getPrice(item);
                getMargin(tmp_margin, tmp_surcom, item, `${trip?.id}-flight`);
                let matched = false;
                total_cost.map((currency_cost: any) => {
                    if (currency_cost.currency.id === price.currency?.id) {
                        matched = true;
                        currency_cost.cost += price.cost + (trip?.flight_taxes ? parseFloat(trip.flight_taxes) : 0);
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost + (trip?.flight_taxes ? parseFloat(trip.flight_taxes) : 0),
                        currency: price.currency
                    });
                }
            }
        }

        if (packageFlightPrices.length > 0 || customCostFlightPrices.length > 0) {
            if (trip?.flight_taxes) {
                flight_taxes = parseFloat(trip.flight_taxes);
            }
        }

        if (packageTerrestrialPrices.length > 0 && !hasCustomCost) {
            const grouppedPrices = groupBy(
                packageTerrestrialPrices,
                (item) => `${item.stack_number}-${item.stack_info_id}`
            );
            const pricesGroup = Object.values(grouppedPrices);
            for (const item of pricesGroup) {
                let price = getPrice(item);
                getMargin(tmp_margin, tmp_surcom, item, `${trip?.id}-terrestrial`);
                let matched = false;
                total_cost.map((currency_cost: any) => {
                    if (currency_cost.currency.id === price.currency?.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost,
                        currency: price.currency
                    });
                }
            }
        }

        if (
            (trip?.prices_stack_product?.length ?? 0) > 0 &&
            packagesFromProducts.length > 0 &&
            !hasCustomCost
        ) {
            let price = getPrice(trip!.prices_stack_product);
            getMargin(tmp_margin, tmp_surcom, trip!.prices_stack_product);
            let matched = false;
            total_cost.map((currency_cost) => {
                if (currency_cost.currency!.id === price.currency!.id) {
                    matched = true;
                    currency_cost.cost += price.cost;
                }
            });
            if (!matched) {
                total_cost.push({
                    cost: price.cost,
                    currency: price.currency
                });
            }
        }

        //add products prices
        accommodations?.map(accommodation => {
            accommodation.rooms.map(room => {
                if (room.booking_process_state === 'NOTHING' && room.booking_status && room.booking_status.status_booking === 'CONFIRMED') {
                    has_booked_items++;
                }
                if (
                    (room.is_available || (room.booking_status && room.booking_status.status_booking === 'CONFIRMED')) &&
                    room.is_displayed &&
                    !room.is_hidden_for_traveler &&
                    !room.is_optional &&
                    (room.booking_status === null || (room.booking_process_state !== 'CANCEL' && room.booking_status.status_booking !== 'CANCELLED'))
                ) {
                    nb_items++;
                    let tva_price = room.prices.find((price) => price.is_tva);
                    if (tva_price) {
                        tva += (parseFloat(tva_price.selling_price) - parseFloat(tva_price.purchase_price));
                        if ((window.moment.utc(tva_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: room.hotel[0]?.name + " - " + room.description,
                                price: {
                                    cost: tva_price,
                                    adjustment: room.prices.find((price) => price.master_price),
                                    currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                                }
                            });
                        }
                    } else {
                        let tmp_price = room.prices.find((price) => price.master_price);
                        if (tmp_price) {
                            if ((window.moment.utc(tmp_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                                modified_product.push({
                                    label: room.hotel[0]?.name + " - " + room.description,
                                    price: {
                                        cost: tmp_price,
                                        adjustment: tmp_price,
                                        currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                    }
                                });
                            }
                        }
                    }
                    if (
                        (
                            !isProductPackaged({
                                product: room,
                                stackInfos: trip?.stack_info ?? null
                            }) ||
                            isProductPackaged({
                                product: room,
                                stackInfos: trip?.stack_info ?? null,
                                for_price: true
                            })
                        ) &&
                        !hasCustomCost
                    ) {
                        let price = getPrice(room.prices);
                        getMargin(tmp_margin, tmp_surcom, room.prices, room.id);
                        let matched = false;
                        total_cost.map((currency_cost: any) => {
                            if (currency_cost.currency.id === price.currency?.id) {
                                matched = true;
                                currency_cost.cost += price.cost;
                            }
                        });
                        if (!matched) {
                            total_cost.push({
                                cost: price.cost,
                                currency: price.currency
                            });
                        }
                        matched = false;
                        total_accommodations.map((currency_cost: any) => {
                            if (currency_cost.currency.id === price.currency?.id) {
                                matched = true;
                                currency_cost.cost += price.cost;
                            }
                        });
                        if (!matched) {
                            total_accommodations.push({
                                cost: price.cost,
                                currency: price.currency
                            });
                        }
                        if (room.additional_products) {
                            room.additional_products.map((additional_product) => {
                                let price = getPrice(additional_product.prices);
                                getMargin(tmp_margin, tmp_surcom, additional_product.prices, additional_product.id);
                                let matched = false;
                                total_cost.map((currency_cost: any) => {
                                    if (currency_cost.currency.id === price.currency?.id) {
                                        matched = true;
                                        currency_cost.cost += price.cost;
                                    }
                                });
                                if (!matched) {
                                    total_cost.push({
                                        cost: price.cost,
                                        currency: price.currency
                                    });
                                }
                                matched = false;
                                total_accommodations.map((currency_cost: any) => {
                                    if (currency_cost.currency.id === price.currency?.id) {
                                        matched = true;
                                        currency_cost.cost += price.cost;
                                    }
                                });
                                if (!matched) {
                                    total_accommodations.push({
                                        cost: price.cost,
                                        currency: price.currency
                                    });
                                }
                            });
                        }
                    }
                }
            });
        });
        cars?.map(car => {
            if (car.booking_process_state === 'NOTHING' && car.booking_status && car.booking_status.status_booking === 'CONFIRMED') {
                has_booked_items++;
            }
            if (
                (car.is_available || (car.booking_status && car.booking_status.status_booking === 'CONFIRMED')) &&
                car.is_displayed &&
                !car.is_hidden_for_traveler &&
                !car.is_optional &&
                (car.booking_status === null || (car.booking_process_state !== 'CANCEL' && car.booking_status.status_booking !== 'CANCELLED'))
            ) {
                nb_items++;
                //if (!car.is_cancellable) {
                //has_not_cancellable_items++;
                //}
                let tva_price = car.prices.find((price) => price.is_tva);
                let calc_tva = 0;
                if (tva_price) {
                    calc_tva = (parseFloat(tva_price.selling_price) - parseFloat(tva_price.purchase_price));
                    tva += calc_tva;
                    if ((window.moment.utc(tva_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                        modified_product.push({
                            label: car.name,
                            price: {
                                cost: tva_price,
                                adjustment: car.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = car.prices.find((price) => price.master_price);
                    if (tmp_price) {
                        if ((window.moment.utc(tmp_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: car.name,
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                }
                            });
                        }
                    }
                }
                if (
                    (
                        !isProductPackaged({
                            product: car,
                            stackInfos: trip?.stack_info ?? null
                        }) ||
                        isProductPackaged({
                            product: car,
                            stackInfos: trip?.stack_info ?? null,
                            for_price: true
                        })
                    ) && !car.catalog_price &&
                    !hasCustomCost
                ) {
                    let price = getPrice(car.prices);
                    getMargin(tmp_margin, tmp_surcom, car.prices, car.id);
                    let matched = false;
                    total_cost.map((currency_cost: any) => {
                        if (currency_cost.currency.id === price.currency?.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                    matched = false;
                    total_cars.map((currency_cost: any) => {
                        if (currency_cost.currency.id === price.currency?.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_cars.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                }
            }
        });
        manual_products?.map(manual_product => {
            if (
                (manual_product.is_available || (manual_product.booking_status && manual_product.booking_status.status_booking === 'CONFIRMED')) &&
                (manual_product.is_displayed || ['cercledesvoyages', 'terreslointaines'].includes(quotation_code)) &&
                !manual_product.is_hidden_for_traveler &&
                !manual_product.is_optional &&
                (manual_product.booking_status === null || (manual_product.booking_process_state !== 'CANCEL' && manual_product.booking_status.status_booking !== 'CANCELLED'))
            ) {
                nb_items++;
                //if ((window.moment.utc(manual_product.created_date).isAfter(window.moment.utc(status_contract_confirmed_date)) || window.moment.utc(manual_product.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                let tva_price = manual_product.prices.find((price) => price.is_tva);
                let calc_tva = 0;
                if (tva_price) {
                    calc_tva = parseFloat(tva_price.selling_price) - parseFloat(tva_price.purchase_price);
                    tva += calc_tva;
                    if (manual_product.product_type !== 18 && manual_product.product_type !== 20 && (window.moment.utc(tva_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date))) && manual_product.is_displayed) {
                        modified_product.push({
                            label: manual_product.name,
                            price: {
                                cost: tva_price,
                                adjustment: manual_product.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = manual_product.prices.find((price) => price.master_price);
                    if (tmp_price) {
                        if (manual_product.product_type !== 18 && manual_product.product_type !== 20 && (window.moment.utc(tmp_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: manual_product.name,
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                }
                            });
                        }
                    }
                }
                if (
                    (
                        !isProductPackaged({
                            product: manual_product,
                            stackInfos: trip?.stack_info ?? null
                        }) ||
                        isProductPackaged({
                            product: manual_product,
                            stackInfos: trip?.stack_info ?? null,
                            for_price: true
                        })
                    ) &&
                    !hasCustomCost
                ) {
                    if (manual_product.taxes) {
                        flight_taxes += parseFloat(manual_product.taxes);
                    }
                    let price = getPrice(manual_product.prices);
                    getMargin(tmp_margin, tmp_surcom, manual_product.prices, manual_product.id);
                    let matched = false;
                    total_cost.map((currency_cost: any) => {
                        if (currency_cost.currency.id === price.currency?.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                            if (manual_product.taxes) {
                                currency_cost.cost += parseFloat(manual_product.taxes);
                            }
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: price.cost + (manual_product.taxes ? parseFloat(manual_product.taxes) : 0),
                            currency: price.currency
                        });
                    }
                    matched = false;
                    if (manual_product.product_type === 8) {
                        totalInsurance += price.cost;
                    }
                    if ((user?.client_full?.type === 2 && (manual_product.creator?.client as any)?.type !== 2) || ['cercledesvoyages'].includes(quotation_code)) {
                        switch (manual_product.product_type) {
                            case 0:
                            case 7:
                                total_accommodations.map((currency_cost: any) => {
                                    if (currency_cost.currency.id === price.currency?.id) {
                                        matched = true;
                                        currency_cost.cost += price.cost;
                                    }
                                });
                                if (!matched) {
                                    total_accommodations.push({
                                        cost: price.cost,
                                        currency: price.currency
                                    });
                                }
                                break;
                            case 2:
                                total_cars.map((currency_cost: any) => {
                                    if (currency_cost.currency.id === price.currency?.id) {
                                        matched = true;
                                        currency_cost.cost += price.cost;
                                    }
                                });
                                if (!matched) {
                                    total_cars.push({
                                        cost: price.cost,
                                        currency: price.currency
                                    });
                                }
                                break;
                            case 4:
                                total_transfers.map((currency_cost: any) => {
                                    if (currency_cost.currency.id === price.currency?.id) {
                                        matched = true;
                                        currency_cost.cost += price.cost;
                                    }
                                });
                                if (!matched) {
                                    total_transfers.push({
                                        cost: price.cost,
                                        currency: price.currency
                                    });
                                }
                                break;
                            case 6:
                                let master_price = manual_product.prices.find((price) => price.master_price);
                                total_flights.map((currency_cost: any) => {
                                    if (currency_cost.currency.id === price.currency?.id) {
                                        matched = true;
                                        currency_cost.cost += price.cost + (parseFloat(manual_product.taxes ?? '0') * (master_price ? parseFloat(master_price.factor_used) : 1));
                                    }
                                });
                                if (!matched) {
                                    total_flights.push({
                                        cost: price.cost + (parseFloat(manual_product.taxes ?? '0') * (master_price ? parseFloat(master_price.factor_used) : 1)),
                                        currency: price.currency
                                    });
                                }
                                break;
                            case 8:
                                total_assistances.map((currency_cost: any) => {
                                    if (currency_cost.currency.id === price.currency?.id) {
                                        matched = true;
                                        currency_cost.cost += price.cost;
                                    }
                                });
                                if (!matched) {
                                    total_assistances.push({
                                        cost: price.cost,
                                        currency: price.currency
                                    });
                                }
                                break;
                            case 11:
                            case 12:
                                total_pois.map((currency_cost: any) => {
                                    if (currency_cost.currency.id === price.currency?.id) {
                                        matched = true;
                                        currency_cost.cost += price.cost;
                                    }
                                });
                                if (!matched) {
                                    total_pois.push({
                                        cost: price.cost,
                                        currency: price.currency
                                    });
                                }
                                break;
                            default:
                                total_manual_products.map((currency_cost: any) => {
                                    if (currency_cost.currency.id === price.currency?.id) {
                                        matched = true;
                                        currency_cost.cost += price.cost;
                                        if (manual_product.taxes) {
                                            currency_cost.cost += parseFloat(manual_product.taxes);
                                        }
                                    }
                                });
                                if (!matched) {
                                    total_manual_products.push({
                                        cost: price.cost + (manual_product.taxes ? parseFloat(manual_product.taxes) : 0),
                                        currency: price.currency
                                    });
                                }
                                break;
                        }
                    } else {
                        total_manual_products.map((currency_cost: any) => {
                            if (currency_cost.currency.id === price.currency?.id) {
                                matched = true;
                                currency_cost.cost += price.cost;
                                if (manual_product.taxes) {
                                    currency_cost.cost += parseFloat(manual_product.taxes);
                                }
                            }
                        });
                        if (!matched) {
                            total_manual_products.push({
                                cost: price.cost + (manual_product.taxes ? parseFloat(manual_product.taxes) : 0),
                                currency: price.currency
                            });
                        }
                    }
                }
            }
        });
        pois?.map(poi => {
            if (poi.booking_process_state === 'NOTHING' && poi.booking_status && poi.booking_status.status_booking === 'CONFIRMED') {
                has_booked_items++;
            }
            if (
                (poi.is_available || (poi.booking_status && poi.booking_status.status_booking === 'CONFIRMED')) &&
                poi.is_displayed &&
                !poi.is_hidden_for_traveler &&
                !poi.is_optional &&
                (poi.booking_status === null || (poi.booking_process_state !== 'CANCEL' && poi.booking_status.status_booking !== 'CANCELLED'))
            ) {
                nb_items++;
                let tva_price = poi.prices.find((price) => price.is_tva);
                let calc_tva = 0;
                if (tva_price) {
                    calc_tva = ((parseFloat(tva_price.selling_price) - parseFloat(tva_price.purchase_price)));
                    tva += calc_tva;
                    if ((window.moment.utc(tva_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                        modified_product.push({
                            label: poi.custom_product?.title,
                            price: {
                                cost: tva_price,
                                adjustment: poi.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = poi.prices.find((price) => price.master_price);
                    if (tmp_price) {
                        if ((window.moment.utc(tmp_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: poi.custom_product?.title,
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                }
                            });
                        }
                    }
                }
                if (
                    (
                        !isProductPackaged({
                            product: poi,
                            stackInfos: trip?.stack_info ?? null
                        }) ||
                        isProductPackaged({
                            product: poi,
                            stackInfos: trip?.stack_info ?? null,
                            for_price: true
                        })
                    ) &&
                    !hasCustomCost
                ) {
                    let price = getPrice(poi.prices);
                    getMargin(tmp_margin, tmp_surcom, poi.prices, poi.id);
                    let matched = false;
                    total_cost.map((currency_cost: any) => {
                        if (currency_cost.currency.id === price.currency?.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                    matched = false;
                    total_pois.map((currency_cost: any) => {
                        if (currency_cost.currency.id === price.currency?.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_pois.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                }
            }
        });
        assistances?.map(assistance => {
            if (assistance.booking_process_state === 'NOTHING' && assistance.booking_status && assistance.booking_status.status_booking === 'CONFIRMED') {
                has_booked_items++;
            }
            if (
                (assistance.is_available || (assistance.booking_status && assistance.booking_status.status_booking === 'CONFIRMED')) &&
                assistance.is_displayed &&
                !assistance.is_hidden_for_traveler &&
                !assistance.is_optional &&
                (assistance.booking_status === null || (assistance.booking_process_state !== 'CANCEL' && assistance.booking_status.status_booking !== 'CANCELLED'))
            ) {
                nb_items++;
                let tva_price = assistance.prices.find((price) => price.is_tva);
                let calc_tva = 0;
                if (tva_price) {
                    calc_tva = parseFloat(tva_price.selling_price) - parseFloat(tva_price.purchase_price);
                    tva += calc_tva;
                    if ((window.moment.utc(tva_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                        modified_product.push({
                            label: t('cart-material.assistance'),
                            price: {
                                cost: tva_price,
                                adjustment: assistance.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = assistance.prices.find((price) => price.master_price);
                    if (tmp_price) {
                        if ((window.moment.utc(tmp_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: t('cart-material.assistance'),
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                }
                            });
                        }
                    }
                }
                if (
                    (
                        !isProductPackaged({
                            product: assistance,
                            stackInfos: trip?.stack_info ?? null
                        }) ||
                        isProductPackaged({
                            product: assistance,
                            stackInfos: trip?.stack_info ?? null,
                            for_price: true
                        })
                    ) &&
                    !hasCustomCost
                ) {
                    let price = getPrice(assistance.prices);
                    getMargin(tmp_margin, tmp_surcom, assistance.prices, assistance.id);
                    let matched = false;
                    total_cost.map((currency_cost: any) => {
                        if (currency_cost.currency.id === price.currency?.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                    matched = false;
                    total_assistances.map((currency_cost: any) => {
                        if (currency_cost.currency.id === price.currency?.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_assistances.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                    if (assistance.product_type === 8) {
                        totalInsurance += price.cost;
                    }
                }
            }
        });
        transfers?.map(transfer => {
            if (transfer.booking_process_state === 'NOTHING' && transfer.booking_status && transfer.booking_status.status_booking === 'CONFIRMED') {
                has_booked_items++;
            }
            if (
                (transfer.is_available || (transfer.booking_status && transfer.booking_status.status_booking === 'CONFIRMED')) &&
                transfer.is_displayed &&
                !transfer.is_hidden_for_traveler &&
                !transfer.is_optional &&
                (transfer.booking_status === null || (transfer.booking_process_state !== 'CANCEL' && transfer.booking_status.status_booking !== 'CANCELLED'))
            ) {
                nb_items++;
                let tva_price = transfer.prices.find((price) => price.is_tva);
                let calc_tva = 0;
                if (tva_price) {
                    calc_tva = (parseFloat(tva_price.selling_price) - parseFloat(tva_price.purchase_price));
                    tva += calc_tva;
                    if ((window.moment.utc(tva_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                        modified_product.push({
                            label: transfer.is_custom ? transfer.custom_product?.title : transfer.name,
                            price: {
                                cost: tva_price,
                                adjustment: transfer.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = transfer.prices.find((price) => price.master_price);
                    if (tmp_price) {
                        if ((window.moment.utc(tmp_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: transfer.is_custom ? transfer.custom_product?.title : transfer.name,
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                }
                            });
                        }
                    }
                }
                if (
                    (
                        !isProductPackaged({
                            product: transfer,
                            stackInfos: trip?.stack_info ?? null
                        }) ||
                        isProductPackaged({
                            product: transfer,
                            stackInfos: trip?.stack_info ?? null,
                            for_price: true
                        })
                    ) &&
                    !hasCustomCost
                ) {
                    let price = getPrice(transfer.prices);
                    getMargin(tmp_margin, tmp_surcom, transfer.prices, transfer.id);
                    let matched = false;
                    total_cost.map((currency_cost: any) => {
                        if (currency_cost.currency.id === price.currency?.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                    matched = false;
                    total_transfers.map((currency_cost: any) => {
                        if (currency_cost.currency.id === price.currency?.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_transfers.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                }
            }
        });
        flights?.map(flight => {
            if (flight.booking_process_state === 'NOTHING' && flight.booking_status && flight.booking_status.status_booking === 'CONFIRMED') {
                has_booked_items++;
            }
            if (
                (flight.is_available || (flight.booking_status && flight.booking_status.status_booking === 'CONFIRMED')) &&
                flight.is_displayed &&
                !flight.is_hidden_for_traveler &&
                !flight.is_optional &&
                (flight.booking_status === null || (flight.booking_process_state !== 'CANCEL' && flight.booking_status.status_booking !== 'CANCELLED'))
            ) {
                nb_items++;
                if (
                    (
                        !isProductPackaged({
                            product: flight,
                            stackInfos: trip?.stack_info ?? null
                        }) ||
                        isProductPackaged({
                            product: flight,
                            stackInfos: trip?.stack_info ?? null,
                            for_price: true
                        })
                    ) &&
                    !hasCustomCost
                ) {
                    let master_price = flight.prices.find((price: ProductPrice) => price.master_price);
                    flight_taxes += parseFloat(flight.taxes) * (master_price ? parseFloat(master_price.factor_used) : 1);
                    let price = getPrice(flight.prices);
                    let tva_price = flight.prices.find((price: ProductPrice) => price.is_tva);
                    if (tva_price) {
                        tva += parseFloat(tva_price.selling_price) - parseFloat(tva_price.purchase_price);
                    }
                    getMargin(tmp_margin, tmp_surcom, flight.prices, flight.id);
                    for (const item of flight.paid_option_price) {
                        getMargin(tmp_margin, tmp_surcom, item.prices, flight.id);
                    }
                    let matched = false;
                    const flightPrice = getFlightSellingPrice(flight, ((user?.client_full?.type === 1 || user?.client_full?.type === 3) && quotation_code === "visiteurs"));
                    total_cost.map((currency_cost: any) => {
                        if (currency_cost.currency.id === price.currency?.id) {
                            matched = true;
                            currency_cost.cost += flightPrice[0]?.cost ?? 0;
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: flightPrice[0]?.cost ?? 0,
                            currency: price.currency
                        });
                    }
                    matched = false;
                    total_flights.map((currency_cost: any) => {
                        if (currency_cost.currency.id === price.currency?.id) {
                            matched = true;
                            currency_cost.cost += flightPrice[0]?.cost ?? 0;
                        }
                    });
                    if (!matched) {
                        total_flights.push({
                            cost: flightPrice[0]?.cost ?? 0,
                            currency: price.currency
                        });
                    }
                    if (tva_price) {
                        if ((window.moment.utc(tva_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: t("global.flight") + " " + (flight.outbounds.length === 1 ? t('global.one_way') : t('global.multi_destination')),
                                price: {
                                    cost: tva_price,
                                    adjustment: flight.prices.find((price: ProductPrice) => price.master_price),
                                    currency: currency_list.find(currency => currency.id === tva_price.selling_currency)
                                }
                            });
                        }
                    } else {
                        let tmp_price = flight.prices.find((price: ProductPrice) => price.master_price);
                        if (tmp_price) {
                            if ((window.moment.utc(tmp_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                                modified_product.push({
                                    label: t("global.flight") + " " + (flight.outbounds.length === 1 ? t('global.one_way') : t('global.multi_destination')),
                                    price: {
                                        cost: tmp_price,
                                        adjustment: tmp_price,
                                        currency: currency_list.find(currency => currency.id === tmp_price.selling_currency)
                                    }
                                });
                            }
                        }
                    }
                    //if (window.moment.utc(flight.created_date).isAfter(window.moment.utc(status_contract_confirmed_date))) {
                    //let tmp_price = flight.prices[GetPricesId(flight.prices, user.client, user, true)];
                    //new_product.push({
                    //label: t("global.flight") + " " + (flight.outbounds.length === 1 ? t('global.one_way') : t('global.multi_destination')),
                    //price: {
                    //cost: parseFloat(tmp_price.purchase_price) + parseFloat(flight.taxes) + option_cost,
                    //currency: currency_list.find(currency => currency.id === tmp_price.selling_currency)
                    //}
                    //});
                    //}
                }
            }
        });
        let cost = 0;
        total_cost.map(((currency_cost: any) => {
            cost += parseFloat(currency_cost.cost);
        }));

        if (all_data && all_data.payments_done && all_data.payments_done?.length !== 0) {
            all_data.payments_done?.map((payment) => {
                payment_done += parseFloat(payment.amount);
            });
        }
        advance_payment = trip?.expected_deposit ? parseFloat(trip.expected_deposit) : 0;
        if (!unFormated) {
            total_cost.map((currency_cost: any) => {
                currency_cost.cost = (currency_cost.cost).toLocaleString(i18n.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            });
        }
        tmp_margin.value = parseFloat((tmp_margin.selling - tmp_margin.purchase_converted).toFixed(2));
        tmp_margin.markup = Math.abs(tmp_margin.percent - (100 - (tmp_margin.purchase_converted / tmp_margin.selling) * 100)) > Math.abs(tmp_margin.percent - ((tmp_margin.value / tmp_margin.purchase_converted) * 100));
        if (tmp_margin.value !== 0) {
            if (['cercledesvoyages', 'terreslointaines'].includes(quotation_code)) {
                tmp_margin.percent = parseFloat(((tmp_margin.value / cost) * 100).toFixed(2));
            } else if (quotation_code === 'americavoyages' || tmp_margin.markup) {
                tmp_margin.percent = parseFloat(((tmp_margin.value / tmp_margin.purchase_converted) * 100).toFixed(2));
            } else {
                tmp_margin.percent = parseFloat(((tmp_margin.value / tmp_margin.selling) * 100).toFixed(2));
            }
        }
        tmp_surcom.static = tmp_surcom.value;
        tmp_surcom.value = tmp_surcom.selling - tmp_surcom.purchase;
        tmp_surcom.percent = (tmp_surcom.value / (tmp_surcom.selling === 0 ? 1 : tmp_surcom.selling)) * 100;
        return {
            totalCost: total_cost,
            totalCartCost: cost,
            totalInsurance,
            margin: tmp_margin,
            surcom: tmp_surcom,
            flightTaxes: flight_taxes,
            hasBookedItems: has_booked_items,
            itemsCount: nb_items,
            advancePayment: parseFloat(advance_payment.toFixed(2)),
            newProduct: new_product,
            modifiedProduct: modified_product,
            paymentDone: parseFloat(payment_done.toFixed(2)),
            vat: tva
        };
    }, [
        t,
        cars,
        accommodations,
        manual_products,
        pois,
        assistances,
        transfers,
        flights,
        trip,
        user,
        currency_list,
        currency,
        all_data,
        i18n.language,
        packagesFromProducts,
        getFlightSellingPrice
    ]);
}
