import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { WithRouterProps } from "react-router";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Paper,
    Stack,
    Tab,
    Tabs,
    Typography,
    styled
} from "@mui/material";
import {
    Add,
    Check,
    Close,
    Edit,
    Save,
} from "@mui/icons-material";
import { keyBy, mapValues } from "lodash";
import { ProviderQuotationNavbar } from "./ProviderQuotationNavbar";
import { ProviderQuotationCurrencyPicker } from "./ProviderQuotationCurrencyPicker";
import { ProviderQuotationProductStatus } from "./objects/providerQuotationProductStatus";
import { CartConstructionContents } from "../CartMaterial/CartConstructionContents";
import { CartConstructionItineraryContentStepper } from "../CartMaterial/CartConstructionItineraryContentStepper";
import { ProviderQuotationSideContent, QuotationTab } from "./providerQuotationSideContent";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { CartTextsConstructionWrapper } from "../CartMaterial/CartTextsConstructionWrapper";
import { CartConstructionContentsFormSync } from "../CartMaterial/CartConstructionContentsFormSync";
import { ProviderProducts } from "./providerProducts";
import { ProviderTrip } from "./providerTrip";
import CartManualProductForm from "../CartMaterial/CartManualProductForm";
import CartAccommodationCancellationDetail from '../CartMaterial/CartAccommodationCancellationDetail';
import { ProviderContext } from "../CartMaterial/utils/providerContext";
import { useProductCurrencyChange } from "./network/productCurrencyChange";
import { useShowError } from "../Utils/showError";
import { useItineraryContentFetch } from "../CartMaterial/network/itineraryContentFetch";
import { usePackagedProducts } from "../CartMaterial/utils/packagedProducts";
import { useGetPrice } from "../CartMaterial/utils/getPrice";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { useProductPriceChange } from "./network/productPriceChange";
import { useProviderQuotationProducts } from "./utils/providerQuotationProducts";
import { reverseProviderQuotationStatus } from "./utils/getProviderQuotationProductStatus";
import CheckResponse from "../Flight/FlightSelected/Functions/CheckResponse";
import { setModule, setStep } from "../CartMaterial/redux/cartConstructionReducer";
import { AppState } from "../../Reducers/Reducers";

type Props = WithRouterProps

export default function ProviderQuotation(props: Props): JSX.Element {
    const tripId = props.params.tripId;
    const tripVersion = props.params.tripVersion;
    const tripToken = props.params.tripToken;
    const providerId = props.params.providerId ?
        parseInt(props.params.providerId) :
        null;
    const version = tripVersion ? parseInt(tripVersion) : -1;
    const stackNumber = parseInt(props.params.stackNumber ?? '-1');
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const travelers = useSelector((state: AppState) => state.trip.travelers);
    const trip = useSelector((state: AppState) => state.trip.all_data);
    const tripData = useSelector((state: AppState) => state.trip.data_trip);
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const contentLoading = useSelector((state: AppState) => state.cartConstruction.loading);
    const manualProviders = useSelector((state: AppState) => state.trip.manual_providers);
    const itineraryContent = useSelector((state: AppState) => {
        return version ?
            state.itinerarySlice.content[version] :
            undefined;
    });
    const stepperStep = useSelector((state: AppState) => state.cartConstruction.step);
    const manual_product_form = useSelector((state: AppState) => state.cart.manual_product_form);
    const fetchingTexts = useSelector((state: AppState) => state.cartConstruction.fetchingTexts);
    const recreatingTextInputs = useSelector((state: AppState) => state.cartConstruction.recreatingTextInputs);
    const savingTexts = useSelector((state: AppState) => state.cartConstruction.savingTexts);
    const autoloadingTexts = useSelector((state: AppState) => state.cartConstruction.autoloadingTexts);
    const [statuses, setStatuses] = useState<{
        [key: number]: {
            id: number,
            isCustom: boolean,
            type: ReturnType<typeof useProviderQuotationProducts>[number]['type'],
            status: ProviderQuotationProductStatus | null,
            providerComment: string | null
        }
    }>({});
    const [loading, setLoading] = useState(false);
    const [productsCurrency, setProductsCurrency] = useState<number | null>(null);
    const [counterOfferProduct, setCounterOfferProduct] = useState<number | null>(null);
    const [openCreateProductModal, setOpenCreateProductModal] = useState(false);
    const [openNewPropositionModal, setOpenNewPropositionModal] = useState(false);
    const [tab, setTab] = useState(QuotationTab.PRODUCTS);
    const showError = useShowError();
    const updateProductCurrency = useProductCurrencyChange({
        onSuccess(product, data) {
            if (tripData) {
                switch (product.type) {
                    case 'flights': {
                        dispatch({
                            type: 'FLIGHT_EDIT_CART_BY_ID',
                            payload: CheckResponse([data], tripData.end_date)[0]!
                        });
                        break;
                    }
                    case 'cars': {
                        dispatch({
                            type: 'CAR_EDIT_CART_BY_ID',
                            payload: data
                        });
                        break;
                    }
                    case 'accommodations': {
                        dispatch({
                            type: 'ACCOMMODATION_EDIT_CART_BY_ID',
                            payload: data
                        });
                        break;
                    }
                    case 'transfers': {
                        dispatch({
                            type: 'TRANSFER_EDIT_CART_BY_ID',
                            payload: data
                        });
                        break;
                    }
                    case 'pois': {
                        dispatch({
                            type: 'POI_EDIT_CART_BY_ID',
                            payload: data
                        });
                        break;
                    }
                    default: {
                        dispatch({
                            type: 'CART_EDIT_MANUAL_ITEM',
                            payload: data
                        });
                        break;
                    }
                }
            }
        },
        onError(error) {
            console.error(error);
            showError(error);
        }
    });
    const packagedProducts = usePackagedProducts();
    const products = useMemo(() => {
        const key = Object.keys(packagedProducts).find((key) => {
            return key.startsWith(stackNumber + '-');
        }) ?? '';
        return packagedProducts[key] ?? {
            accommodations: [],
            assistances: [],
            cars: [],
            flights: [],
            manualProducts: [],
            pois: [],
            transfers: []
        };
    }, [packagedProducts]);
    const rawProducts = useMemo(() => {
        return [
            ...products.accommodations.map((item): typeof item.accommodation & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                return {
                    type: item.type === 'normal' ?
                        'accommodations' :
                        'manual',
                    ...item.accommodation
                }
            }),
            ...products.assistances.map((item): typeof item.assistance & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                return {
                    type: item.type === 'normal' ?
                        'assistances' :
                        'manual',
                    ...item.assistance
                }
            }),
            ...products.cars.map((item): typeof item.car & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                return {
                    type: item.type === 'normal' ?
                        'cars' :
                        'manual',
                    ...item.car
                }
            }),
            ...products.flights.map((item): typeof item.flight & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                return {
                    type: item.type === 'normal' ?
                        'flights' :
                        'manual',
                    ...item.flight
                }
            }),
            ...products.manualProducts.map((item): typeof item & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                return {
                    type: 'manual',
                    ...item
                }
            }),
            ...products.pois.map((item): typeof item.poi & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                return {
                    type: item.type === 'normal' ?
                        'pois' :
                        'manual',
                    ...item.poi
                }
            }),
            ...products.transfers.map((item): typeof item.transfer & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                return {
                    type: item.type === 'normal' ?
                        'transfers' :
                        'manual',
                    ...item.transfer
                }
            })
        ];
    }, [products]);
    const provider = providers.find((item) => {
        return item.provider.id === providerId;
    }) ?? manualProviders.find((item) => {
        return item.provider.id === providerId;
    });
    const context = useMemo(() => {
        return {
            tripId: tripId ? parseInt(tripId) : null,
            version: tripVersion ? parseInt(tripVersion) : null,
            providerId: providerId ? providerId : null,
            tripToken: tripToken ?? null
        };
    }, [
        tripId,
        tripVersion,
        providerId,
        tripToken
    ]);
    const updatePrice = useProductPriceChange({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(product, data) {
            if (tripData) {
                switch (product.type) {
                    case 'flights': {
                        dispatch({
                            type: 'FLIGHT_EDIT_CART_BY_ID',
                            payload: CheckResponse([data], tripData.end_date)[0]!
                        });
                        break;
                    }
                    case 'cars': {
                        dispatch({
                            type: 'CAR_EDIT_CART_BY_ID',
                            payload: data
                        });
                        break;
                    }
                    case 'accommodations': {
                        dispatch({
                            type: 'ACCOMMODATION_EDIT_CART_BY_ID',
                            payload: data
                        });
                        break;
                    }
                    case 'transfers': {
                        dispatch({
                            type: 'TRANSFER_EDIT_CART_BY_ID',
                            payload: data
                        });
                        break;
                    }
                    case 'pois': {
                        dispatch({
                            type: 'POI_EDIT_CART_BY_ID',
                            payload: data
                        });
                        break;
                    }
                    default: {
                        dispatch({
                            type: 'CART_EDIT_MANUAL_ITEM',
                            payload: data
                        });
                        break;
                    }
                }
            }
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    const onChangeStatus = (
        key: keyof typeof statuses,
        status: ProviderQuotationProductStatus | null,
        providerComment: string | null
    ) => {
        setStatuses((state) => ({
            ...state,
            [key]: {
                ...state[key]!,
                status,
                providerComment
            }
        }));
    };
    const onChangePrice = useCallback((productId: number, price: number) => {
        const product = rawProducts.find((item) => {
            return item.id === productId;
        });
        const masterPrice = product?.prices.find((item) => {
            return item.master_price;
        });
        if (
            product &&
            context.tripId &&
            context.version &&
            masterPrice?.id
        ) {
            updatePrice(
                {
                    tripId: context.tripId,
                    version: context.version,
                    tripToken: context.tripToken
                },
                {
                    id: productId,
                    type: product.type,
                    isCustom: product.is_custom,
                    price: !isNaN(price) ? price : 0,
                    masterPriceId: masterPrice.id,
                    customRate: masterPrice.custom_rate,
                    customRateType: (masterPrice.custom_rate_type as 'PER' | 'FIX' | null) ?? 'PER',
                    customValue: masterPrice.custom_value ? parseFloat(masterPrice.custom_value) : 0
                }
            );
        }
    }, [
        context,
        rawProducts,
        updatePrice
    ]);
    const providerContext = useMemo((): Parameters<typeof ProviderContext['Provider']>[0]['value'] => {
        return {
            module: 'quotation',
            statuses,
            tripId: tripId ? parseInt(tripId) : 0,
            version: tripVersion ? parseInt(tripVersion) : 0,
            providerId: providerId ?? 0,
            tripToken: tripToken ?? '',
            onChangePrice,
            onChangeStatus,
            onOpenManualProductForm: () => setOpenNewPropositionModal(true),
            onChangeCounterOfferProduct: (id) => setCounterOfferProduct(id)
        };
    }, [
        statuses,
        onChangePrice
    ]);
    const getPrice = useGetPrice();
    const loadingTexts = fetchingTexts || recreatingTextInputs || savingTexts || autoloadingTexts;

    const onChangeProductsCurrency = async (currency: number | null) => {
        setProductsCurrency(currency);
        if (currency && context.tripId && context.version && currency) {
            setLoading(true);
            for (const product of rawProducts) {
                const masterPrice = product.prices.find((item) => {
                    return item.master_price;
                });
                const price = getPrice(product.prices);
                if (masterPrice?.id) {
                    await updateProductCurrency(
                        {
                            tripId: context.tripId,
                            version: context.version,
                            tripToken: context.tripToken ?? ''
                        },
                        {
                            id: product.id,
                            type: product.type,
                            isCustom: product.is_custom,
                            price: price.purchaseCost,
                            currency,
                            masterPriceId: masterPrice.id,
                            customRate: masterPrice.custom_rate,
                            customRateType: (masterPrice.custom_rate_type as 'PER' | 'FIX') ?? 'PER',
                            customValue: masterPrice.custom_value ?
                                parseFloat(masterPrice.custom_value) :
                                0
                        }
                    );
                }
            }
            setLoading(false);
        }
    };

    const onOpenCreateProductForm = () => {
        setOpenCreateProductModal(true);
        dispatch({ type: 'CART_CREATE_NEW_MANUAL_PRODUCT' });
    };

    useEffect(() => {
        setStatuses(
            mapValues(
                keyBy(
                    rawProducts,
                    (item) => item.id
                ),
                (item) => ({
                    id: item.id,
                    isCustom: item.is_custom,
                    type: item.type,
                    status: {
                        agent: item.agent_accepted ?
                            'accepted' :
                            (
                                item.agent_refused ?
                                    'refused' :
                                    null
                            ),
                        provider: item.provider_accepted ?
                            'accepted' :
                            (
                                item.provider_refused ?
                                    'refused' :
                                    null
                            ),
                    } as ProviderQuotationProductStatus,
                    providerComment: item.provider_comment
                })
            )
        );
    }, [products]);

    useEffect(() => {
        if (tab === QuotationTab.TEXTS) {
            dispatch(setModule('texts'));
        }
    }, [tab]);

    useEffect(() => {
        if (!manual_product_form) {
            setOpenCreateProductModal(false);
            setOpenNewPropositionModal(false);
        }
    }, [manual_product_form]);

    useEffect(() => {
        if (tab === QuotationTab.TEXTS) {
            dispatch({ type: 'CART_SET_STEP', payload: { step: 2 } });
        } else {
            dispatch({ type: 'CART_SET_STEP', payload: { step: 1 } });
        }
    }, [tab]);

    useItineraryContentFetch(version ?? -1);

    return (
        <DndProvider backend={HTML5Backend}>
            <ProviderContext.Provider value={providerContext}>
                <Page>
                    <ProviderQuotationNavbar {...props} />
                    {
                        trip?.provider_allowed_to_edit_byday &&
                        <Tabs
                            value={tab}
                            onChange={(_, value) => setTab(value)}
                            centered
                        >
                            <Tab
                                label={t('cart-material.provider-quotation-tab-products')}
                            />
                            <Tab
                                icon={
                                    loadingTexts ?
                                        <CircularProgress size="1em" /> :
                                        undefined
                                }
                                iconPosition="start"
                                label={t('cart-material.provider-quotation-tab-texts')}
                                disabled={loadingTexts}
                            />
                        </Tabs>
                    }
                    {
                        tab === QuotationTab.PRODUCTS &&
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: 2.5
                            }}
                        >
                            <Typography
                                fontWeight={100}
                                textAlign="center"
                                sx={{ margin: 'auto' }}
                            >
                                <Box
                                    sx={{
                                        fontSize: '1.25em',
                                        whiteSpace: {
                                            lg: 'nowrap'
                                        }
                                    }}
                                >
                                    {
                                        trip?.user_to &&
                                        <Trans
                                            i18nKey="cart-material.provider-quotation-title-with-agent"
                                            values={{
                                                to: trip.target_client?.name,
                                                agent: [
                                                    trip.user_to.first_name,
                                                    trip.user_to.last_name
                                                ].filter((item) => item).join(' ')
                                            }}
                                            components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                                        />
                                    }
                                    {
                                        !trip?.user_to &&
                                        <Trans
                                            i18nKey="cart-material.provider-quotation-title"
                                            values={{
                                                name: trip?.target_client?.name
                                            }}
                                            components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                                        />
                                    }
                                </Box>
                                <Box
                                    sx={(theme) => ({
                                        'li:first-child::before': {
                                            content: `"${t('cart-material.note-dot')}"`,
                                            position: 'absolute',
                                            transform: `translateX(calc(-100% - ${theme.spacing(3)}))`,
                                            fontWeight: 'bold'
                                        }
                                    })}
                                    component="ul"
                                >
                                    <Box
                                        sx={{
                                            textAlign: 'left',
                                            whiteSpace: {
                                                lg: 'nowrap'
                                            }
                                        }}
                                        component="li"
                                    >
                                        <Trans
                                            i18nKey="cart-material.provider-quotation-hint-with-icons"
                                            components={{
                                                confirm: <Check color="success" sx={{ verticalAlign: 'middle' }} />,
                                                refuse: <Close color="error" sx={{ verticalAlign: 'middle' }} />,
                                                proposition: <Edit color="info" sx={{ verticalAlign: 'middle' }} />
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            textAlign: 'left',
                                        }}
                                        component="li"
                                    >
                                        <Trans
                                            i18nKey="cart-material.provider-price-edit-hint"
                                            components={{
                                                edit: (
                                                    <Edit
                                                        fontSize="small"
                                                        sx={(theme) => ({
                                                            verticalAlign: 'middle',
                                                            color: theme.palette.grey[600]
                                                        })}
                                                    />
                                                )
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Typography>
                            <Grid container spacing={5} sx={{ marginTop: 2.5 }}>
                                <Grid item xs={8}>
                                    <Stack
                                        spacing={2.5}
                                        sx={{
                                            padding: 3,
                                            paddingLeft: 4,
                                            paddingRight: 4,
                                        }}
                                    >
                                        <ProviderQuotationCurrencyPicker
                                            value={productsCurrency}
                                            onChange={onChangeProductsCurrency}
                                        />
                                        <ProviderProducts products={products} />
                                        <Button
                                            startIcon={<Add />}
                                            variant="outlined"
                                            onClick={onOpenCreateProductForm}
                                        >
                                            {t('shared.add')}
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Paper
                                        elevation={1}
                                        sx={{
                                            paddingTop: 2.5,
                                            paddingBottom: 3.5,
                                            paddingLeft: 3.5,
                                            paddingRight: 3.5,
                                            borderRadius: '4px',
                                            marginBottom: 2.5
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            component="div"
                                            sx={{ marginBottom: 1 }}
                                        >
                                            {t('cart-material.provider-quotation-travelers')}
                                        </Typography>
                                        {
                                            travelers?.map((traveler, index) => {
                                                const birthDay = window.moment.utc(traveler.birth_date, 'YYYY-MM-DD');
                                                const name = [
                                                    traveler.first_name,
                                                    traveler.last_name
                                                ].filter((item): item is NonNullable<typeof item> => {
                                                    return !!item;
                                                }).join(' ');
                                                return (
                                                    <Typography key={index}>
                                                        {
                                                            t(
                                                                'cart-material.provider-quotation-traveler',
                                                                {
                                                                    title: traveler.title,
                                                                    name,
                                                                    birthDay: birthDay.format('L'),
                                                                    age: window.moment.utc().diff(birthDay, 'years')
                                                                }
                                                            )
                                                        }
                                                    </Typography>
                                                );
                                            })
                                        }
                                    </Paper>
                                    <Box
                                        sx={{
                                            position: 'sticky',
                                            top: '36px'
                                        }}
                                    >
                                        <ProviderQuotationSideContent
                                            statuses={statuses}
                                            onChangeTab={setTab}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    {
                        tab === QuotationTab.TEXTS &&
                        <Box sx={{ marginY: 2, paddingX: 8 }}>
                            <Box sx={{ marginBottom: 3 }}>
                                <CartConstructionItineraryContentStepper
                                    orientation="horizontal"
                                    step={stepperStep}
                                    steps={
                                        (
                                            itineraryContent?.state === 'success' ?
                                                itineraryContent.data.content.map((item) => {
                                                    return {
                                                        mode: itineraryContent.data.mode,
                                                        content: item
                                                    };
                                                }) :
                                                []
                                        ) as any[]
                                    }
                                    setStep={(step) => dispatch(setStep(step))}
                                    alternativeLabel
                                    showStatus
                                />
                            </Box>
                            <DndProvider backend={HTML5Backend}>
                                <CartTextsConstructionWrapper alwaysShow>
                                    <CartConstructionContents />
                                </CartTextsConstructionWrapper>
                            </DndProvider>
                        </Box>
                    }
                    {
                        tab === QuotationTab.TEXTS &&
                        <Button
                            variant="contained"
                            startIcon={
                                <Save />
                            }
                            onClick={() => setTab(QuotationTab.PRODUCTS)}
                            sx={(theme) => ({
                                position: 'fixed',
                                right: theme.spacing(3),
                                bottom: theme.spacing(3),
                                textTransform: 'none',
                                zIndex: theme.zIndex.appBar + 1
                            })}
                            disabled={loadingTexts}
                        >
                            {t('cart-material.provider-validate-texts')}
                        </Button>
                    }
                </Page>
                {
                    provider && openNewPropositionModal &&
                    <CartManualProductForm
                        dataTransformer={(data) => ({
                            ...data,
                            stack_number: stackNumber,
                            provider_created: true,
                            localization: [
                                {
                                    locale,
                                    name: (data as any).name,
                                    description: (data as any).description
                                }
                            ],
                            counter_offer_of: counterOfferProduct,
                            ...reverseProviderQuotationStatus({
                                agent: null,
                                provider: 'accepted'
                            })
                        })}
                        provider={provider}
                        isForProposition
                    />
                }
                {
                    provider && openCreateProductModal &&
                    <CartManualProductForm
                        dataTransformer={(data) => ({
                            ...data,
                            stack_number: stackNumber,
                            provider_created: true,
                            localization: [
                                {
                                    locale,
                                    name: (data as any).name,
                                    description: (data as any).description
                                }
                            ]
                        })}
                        provider={provider}
                        isForProviderCreation
                    />
                }
                {
                    trip?.provider_allowed_to_edit_byday &&
                    <CartConstructionContentsFormSync />
                }
                <CartAccommodationCancellationDetail />
                <ProviderTrip />
                <LoadingBackDrop open={loading || contentLoading} />
            </ProviderContext.Provider>
        </DndProvider>
    );
}

const Page = styled('div')(() => ({
    minHeight: '100vh'
}));
