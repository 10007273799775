import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { ContactSupportOutlined } from "@mui/icons-material";
import LightTooltip from "../Menu/MaterialTripList/utils/tooltip/lightTooltip";
import { getProviderQuotationStatus } from "../ProviderQuotation/utils/getProviderQuotationProductStatus";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    item: {
        agent_accepted: boolean,
        agent_refused: boolean,
        agent_comment: string | null,
        provider_comment: string | null,
        provider_booking_comment: string | null,
        provider_created: boolean,
        provider_accepted: boolean,
        provider_refused: boolean,
        counter_offer_of: number | null
    }
}

export function CartConstructionProductsTableItemProviderQuotationStatus(props: Props): React.ReactNode {
    const { t } = useTranslation();
    const view = useSelector((state: AppState) => state.cartConstruction.productsView);
    const status = getProviderQuotationStatus(props.item);

    const onSeeCounterOffer = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        const element = document.getElementById(`product-${props.item.counter_offer_of}`);
        element?.scrollIntoView({
            block: 'start',
            behavior: 'smooth'
        });
    };

    const content = [
        status.provider === 'accepted' &&
        <>
            {
                view === 'cards' &&
                <Stack spacing={1}>
                    <Chip
                        variant="outlined"
                        size="small"
                        label={t('cart-material.provider-quotation-product-confirmed')}
                        color="success"
                    />
                    {
                        (props.item.provider_comment?.trim().length ?? 0) > 0 &&
                        <Box sx={{ padding: 2, paddingTop: 0 }}>
                            <Typography
                                fontWeight="bold"
                                sx={{
                                    marginBottom: 1,
                                    textDecoration: 'underline'
                                }}
                            >
                                {t('cart-material.provider-quotation-comment-provider-tooltip-title')}
                            </Typography>
                            <Typography>
                                {props.item.provider_comment}
                            </Typography>
                        </Box>
                    }
                </Stack>
            }
            {
                view === 'table' &&
                <Stack
                    display="inline-flex"
                    direction="row"
                    alignItems="center"
                    spacing={1}
                >
                    <Chip
                        variant="outlined"
                        size="small"
                        label={t('cart-material.provider-quotation-product-confirmed')}
                        color="success"
                    />
                    {
                        (props.item.provider_comment?.trim().length ?? 0) > 0 &&
                        <LightTooltip
                            title={
                                <Box sx={{ padding: 2 }}>
                                    <Typography
                                        fontWeight="bold"
                                        sx={{
                                            marginBottom: 1,
                                            textDecoration: 'underline'
                                        }}
                                    >
                                        {t('cart-material.provider-quotation-comment-provider-tooltip-title')}
                                    </Typography>
                                    <Typography>
                                        {props.item.provider_comment}
                                    </Typography>
                                </Box>
                            }
                            arrow
                        >
                            <ContactSupportOutlined
                                fontSize="inherit"
                                sx={{ cursor: 'help' }}
                            />
                        </LightTooltip>
                    }
                </Stack>
            }
        </>,
        (props.item.provider_booking_comment?.length ?? 0) > 0 &&
        <Stack
            display="inline-flex"
            direction="row"
            alignItems="center"
            spacing={1}
        >
            <Chip
                variant="outlined"
                size="small"
                label={t('cart-material.provider-booking-comment')}
                color="success"
            />
            <LightTooltip
                title={
                    <Box sx={{ padding: 2 }}>
                        <Typography>
                            {props.item.provider_booking_comment}
                        </Typography>
                    </Box>
                }
                arrow
            >
                <ContactSupportOutlined
                    fontSize="inherit"
                    sx={{ cursor: 'help' }}
                />
            </LightTooltip>
        </Stack>,
        status.provider === 'refused' &&
        <>
            {
                view === 'cards' &&
                <Stack spacing={1}>
                    <Chip
                        variant="outlined"
                        size="small"
                        label={t('cart-material.provider-quotation-product-refused')}
                        color="error"
                    />
                    {
                        (props.item.provider_comment?.trim().length ?? 0) > 0 &&
                        <Box sx={{ padding: 2, paddingTop: 0 }}>
                            <Typography
                                fontWeight="bold"
                                sx={{
                                    marginBottom: 1,
                                    textDecoration: 'underline'
                                }}
                            >
                                {t('cart-material.provider-quotation-comment-provider-tooltip-title')}
                            </Typography>
                            <Typography>
                                {props.item.provider_comment}
                            </Typography>
                        </Box>
                    }
                </Stack>
            }
            {
                view === 'table' &&
                <Stack
                    display="inline-flex"
                    direction="row"
                    alignItems="center"
                    spacing={1}
                >
                    <Chip
                        variant="outlined"
                        size="small"
                        label={t('cart-material.provider-quotation-product-refused')}
                        color="error"
                    />
                    {
                        (props.item.provider_comment?.trim().length ?? 0) > 0 &&
                        <LightTooltip
                            title={
                                <Box sx={{ padding: 2 }}>
                                    <Typography
                                        fontWeight="bold"
                                        sx={{
                                            marginBottom: 1,
                                            textDecoration: 'underline'
                                        }}
                                    >
                                        {t('cart-material.provider-quotation-comment-provider-tooltip-title')}
                                    </Typography>
                                    <Typography>
                                        {props.item.provider_comment}
                                    </Typography>
                                </Box>
                            }
                            arrow
                        >
                            <ContactSupportOutlined
                                fontSize="inherit"
                                sx={{ cursor: 'help' }}
                            />
                        </LightTooltip>
                    }
                </Stack>
            }
        </>,
        status.agent === 'accepted' &&
        <>
            {
                view === 'cards' &&
                <Stack spacing={1}>
                    <Chip
                        variant="outlined"
                        size="small"
                        label={t('cart-material.provider-quotation-product-confirmed-by-agent')}
                        color="success"
                    />
                    {
                        (props.item.agent_comment?.trim().length ?? 0) > 0 &&
                        <Box sx={{ padding: 2, paddingTop: 0 }}>
                            <Typography
                                fontWeight="bold"
                                sx={{
                                    marginBottom: 1,
                                    textDecoration: 'underline'
                                }}
                            >
                                {t('cart-material.provider-quotation-comment-agent-tooltip-title')}
                            </Typography>
                            <Typography>
                                {props.item.agent_comment}
                            </Typography>
                        </Box>
                    }
                </Stack>
            }
            {
                view === 'table' &&
                <Stack
                    display="inline-flex"
                    direction="row"
                    alignItems="center"
                    spacing={1}
                >
                    <Chip
                        variant="outlined"
                        size="small"
                        label={t('cart-material.provider-quotation-product-confirmed-by-agent')}
                        color="success"
                    />
                    {
                        (props.item.agent_comment?.trim().length ?? 0) > 0 &&
                        <LightTooltip
                            title={
                                <Box sx={{ padding: 2 }}>
                                    <Typography
                                        fontWeight="bold"
                                        sx={{
                                            marginBottom: 1,
                                            textDecoration: 'underline'
                                        }}
                                    >
                                        {t('cart-material.provider-quotation-comment-agent-tooltip-title')}
                                    </Typography>
                                    <Typography>
                                        {props.item.agent_comment}
                                    </Typography>
                                </Box>
                            }
                            arrow
                        >
                            <ContactSupportOutlined
                                fontSize="inherit"
                                sx={{ cursor: 'help' }}
                            />
                        </LightTooltip>
                    }
                </Stack>
            }
        </>,
        status.agent === 'refused' &&
        <>
            {
                view === 'cards' &&
                <Stack spacing={1}>
                    <Chip
                        variant="outlined"
                        size="small"
                        label={t('cart-material.provider-quotation-product-refused-by-agent')}
                        color="error"
                    />
                    {
                        (props.item.agent_comment?.trim().length ?? 0) > 0 &&
                        <Box sx={{ padding: 2, paddingTop: 0 }}>
                            <Typography
                                fontWeight="bold"
                                sx={{
                                    marginBottom: 1,
                                    textDecoration: 'underline'
                                }}
                            >
                                {t('cart-material.provider-quotation-comment-agent-tooltip-title')}
                            </Typography>
                            <Typography>
                                {props.item.agent_comment}
                            </Typography>
                        </Box>
                    }
                </Stack>
            }
            {
                view === 'table' &&
                <Stack
                    display="inline-flex"
                    direction="row"
                    alignItems="center"
                    spacing={1}
                >
                    <Chip
                        variant="outlined"
                        size="small"
                        label={t('cart-material.provider-quotation-product-refused-by-agent')}
                        color="error"
                    />
                    {
                        (props.item.agent_comment?.trim().length ?? 0) > 0 &&
                        <LightTooltip
                            title={
                                <Box sx={{ padding: 2 }}>
                                    <Typography
                                        fontWeight="bold"
                                        sx={{
                                            marginBottom: 1,
                                            textDecoration: 'underline'
                                        }}
                                    >
                                        {t('cart-material.provider-quotation-comment-agent-tooltip-title')}
                                    </Typography>
                                    <Typography>
                                        {props.item.agent_comment}
                                    </Typography>
                                </Box>
                            }
                            arrow
                        >
                            <ContactSupportOutlined
                                fontSize="inherit"
                                sx={{ cursor: 'help' }}
                            />
                        </LightTooltip>
                    }
                </Stack>
            }
        </>
    ].filter((item): item is Exclude<typeof item, false> => !!item);

    return (
        <Box sx={{ marginLeft: 1 }} component="span">
            {content}
            {
                props.item.counter_offer_of &&
                <Typography
                    fontSize="inherit"
                    component="span"
                >
                    {' '}
                    (
                    {t('cart-material.provider-quotation-proposition-marker')}
                    <Box
                        href="#"
                        onClick={onSeeCounterOffer}
                        component="a"
                        sx={{ marginLeft: 1 }}
                    >
                        #{props.item.counter_offer_of}
                    </Box>
                    )
                </Typography>
            }
        </Box>
    );
}
