import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Menu, MenuItem, Stack, Tooltip, Typography, styled } from "@mui/material";
import { Edit, FileDownloadOutlined, HouseSiding, Sort, WbSunnyTwoTone } from "@mui/icons-material";
import { intersection, isArray, isFunction, isString } from "lodash";
import { EditorState, Modifier } from "draft-js";
import { Map } from 'immutable';
import { CartConstructionContentsFormItemFloatingAddButton } from "./CartConstructionContentsFormItemFloatingAddButton";
import { CartConstructionContentsFormLongDescriptionInput } from "./CartConstructionContentsFormLongDescriptionInput";
import { CartConstructionContentsFormProductsInput } from "./CartConstructionContentsFormProductsInput";
import { CartConstructionContentsFormPictureInput } from "./CartConstructionContentsFormPictureInput";
import { CartConstructionContentsFormReorderProductsModal } from "./CartConstructionContentsFormReorderProductsModal";
import { CartConstructionContentsFormShortDescriptionInput } from "./CartConstructionContentsFormShortDescriptionInput";
import { MailTemplateVisualEditor } from "../Menu/MaterialTripList/MailVisualEditor/mailTemplateVisualEditor";
import PictureModalCreateForm from "../Menu/MaterialTripList/picture/pictureModalCreateForm";
import { CartConstructionContentsContext } from "./utils/cartConstructionContentsContext";
import { Guide, findDestinationGuideDescription } from "../Menu/MaterialTripList/utils/findDestinationGuideDescription";
import { getQuickDestination } from "../Itinerary/utils/getQuickDestination";
import { htmlHasEmptyContent } from "../Menu/MaterialTripList/utils/htmlHasEmptyContent";
import { findCircuitGIRContent } from "../Menu/MaterialTripList/utils/findCircuitGIRContent";
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import { restoreRichEditorStateFromHtml } from "../Menu/MaterialTripList/utils/editor/restoreRichEditorStateFromHtml";
import { getLocalizedValue } from "../Itinerary/utils/getLocalizedValue";
import { ProviderContext } from "./utils/providerContext";
import { TextBlock } from "../Menu/MaterialTripList/MailVisualEditor/blocks/mailTemplateVisualEditorTextBlock";
import { RichEditorStyleMap } from "../Menu/MaterialTripList/utils/editor/editor";
import { customColors } from "../Menu/MaterialTripList/utils/editor/customColors";
import { appendVisualEditorBlock, setVisualEditorBlockOptions } from "../Menu/MaterialTripList/MailVisualEditor/redux/actions";
import { addAndSelectVisualEditorBlock } from "../Menu/MaterialTripList/MailVisualEditor/redux/thunks";
import { RegisteredBlocks } from "../Menu/MaterialTripList/MailVisualEditor/utils/registered-blocks";
import { ItineraryByDay } from "../Itinerary/objects/itineraryByDay";
import { Destination } from "../Itinerary/objects/destination";
import { ItineraryContentItem } from "./objects/itineraryContentItem";
import { Picture } from "../Menu/MaterialTripList/picture/objects/picture";
import { CartConstructionContentsFormItemProps } from "./objects/cartConstructionContentsFormItemProps";
import { ImageBlockOptions } from "../Menu/MaterialTripList/MailVisualEditor/blocks/mailTemplateVisualEditorImageBlock";
import { AppState } from "../../Reducers/Reducers";

type Props = CartConstructionContentsFormItemProps & {
    dayOffset: number,
    prevStepIsCircuit?: ItineraryByDay
}

export const CartConstructionContentsFormItemNotDragging = React.forwardRef<{ contentId: number, importGir: () => void }, Props>(
    function CartConstructionContentsFormItemNotDragging(props, ref): JSX.Element {
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const quotationCode = useSelector((state: AppState) => state.header.quotation_code);
        const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
        const context = useContext(CartConstructionContentsContext);
        const showPictures = useSelector((state: AppState) => state.cartConstruction.showPictures);
        const longDescriptionHasBlocks = useSelector((state: AppState) => {
            return (
                state.mailTemplate.visualEditor.present.instances[
                    props.mode === 'by-day' ?
                        `cart-construction-by-day-long-description-${props.content.id}` :
                        `cart-construction-by-step-long-description-${props.content.id}`
                ]?.visualEditorBlocks[props.locale]?.order.length ?? 0
            ) > 0;
        });
        const shortDescriptionHasBlocks = useSelector((state: AppState) => {
            return (
                state.mailTemplate.visualEditor.present.instances[
                    props.mode === 'by-day' ?
                        `cart-construction-by-day-short-description-${props.content.id}` :
                        `cart-construction-by-step-short-description-${props.content.id}`
                ]?.visualEditorBlocks[props.locale]?.order.length ?? 0
            ) > 0;
        });
        const longDescriptionIsEmpty = useSelector((state: AppState) => {
            const ids = state.mailTemplate.visualEditor.present.instances[
                props.mode === 'by-day' ?
                    `cart-construction-by-day-long-description-${props.content.id}` :
                    `cart-construction-by-step-long-description-${props.content.id}`
            ]?.visualEditorBlocks[props.locale]?.order ?? [];
            const blocks = ids.map((id) => {
                return state.mailTemplate.visualEditor.present.instances[
                    props.mode === 'by-day' ?
                        `cart-construction-by-day-long-description-${props.content.id}` :
                        `cart-construction-by-step-long-description-${props.content.id}`
                ]?.visualEditorBlocks[props.locale]?.blocks[id];
            }).filter((item): item is NonNullable<typeof item> => !!item);
            const textBlocks = blocks.filter((item) => {
                return item.getType() === 'text';
            });
            return blocks.findIndex((item) => {
                return item.getType() === 'image';
            }) < 0 &&
                (
                    textBlocks.length === 0 ||
                    textBlocks.every((item) => {
                        const block = item as TextBlock;
                        return block.getOptions().editorState.getCurrentContent().getPlainText().trim().length === 0;
                    })
                );
        });
        const [openProductsReorderModal, setOpenProductsReorderModal] = useState(false);
        const [openPicturesModal, setOpenPicturesModal] = useState(false);
        const [gir, setGir] = useState<ItineraryByDay[]>([]);
        const [guide, setGuide] = useState<Guide[]>([]);
        const [selectedImageBlock, setSelectedImageBlock] = useState<{
            instanceId: string;
            blockId: number;
            blockOptions: unknown;
        } | null>(null);
        const steps = useMemo(() => {
            return itinerary.filter((step) => {
                return step.step_type === 'STEP';
            }).sort(sortItinerary);
        }, [itinerary]);
        const [from, to] = useMemo(() => {
            if (props.mode === 'by-day') {
                const firstDay = window.moment.utc(steps[0]?.start_date);
                return [
                    firstDay.clone().startOf('day').add(
                        (props.content.day[0] ?? 1) - 1,
                        'days'
                    ).toISOString(),
                    firstDay.clone().startOf('day').add(
                        (props.content.day[props.content.day.length - 1] ?? 1) - 1,
                        'days'
                    ).toISOString()
                ];
            }
            const step = steps[props.index ?? -1];
            return [
                step?.start_date ?? window.moment.utc().toISOString(),
                step?.end_date ?? window.moment.utc().toISOString()
            ];
        }, [
            steps,
            props.mode,
            props.index,
            props.content
        ]);
        const customActions = useMemo((): Parameters<typeof MailTemplateVisualEditor>[number]['customActions'] => {
            const createWrapper = (type: string) => {
                const ActionsWrapper = (wrapperProps: { instanceId: string, blockId: number }) => {
                    const dispatch = useDispatch();
                    return (
                        <Box sx={{ color: '#fff' }}>
                            {
                                type === 'text' &&
                                <ResetMenu
                                    {...props}
                                    gir={gir}
                                    guide={guide}
                                    locale={props.locale}
                                    valueType={['long-description', 'short-description']}
                                    sources={['gir', 'guide']}
                                    onChoose={(value) => {
                                        const styles = {
                                            ...RichEditorStyleMap,
                                            ...(quotationCode ? customColors[quotationCode] : null)
                                        };
                                        const block = new TextBlock();
                                        const editorState = restoreRichEditorStateFromHtml(
                                            styles,
                                            value
                                        );

                                        let result = editorState;
                                        const currentContent = result.getCurrentContent();
                                        let selection = result.getSelection();
                                        selection = selection.merge({
                                            anchorKey: currentContent.getFirstBlock().getKey(),
                                            anchorOffset: 0,

                                            focusOffset: currentContent.getLastBlock().getText().length,
                                            focusKey: currentContent.getLastBlock().getKey()
                                        });
                                        const newState = Modifier.mergeBlockData(
                                            result.getCurrentContent(),
                                            selection,
                                            Map({ alignment: 'justify' })
                                        );
                                        result = EditorState.push(result, newState, 'change-block-data');

                                        const dispatchData = setVisualEditorBlockOptions(
                                            wrapperProps.instanceId,
                                            props.locale,
                                            wrapperProps.blockId,
                                            {
                                                ...block.getOptions(),
                                                editorState: result
                                            }
                                        );
                                        dispatch(dispatchData);
                                    }}
                                />
                            }
                        </Box>
                    );
                };
                return ActionsWrapper;
            };

            return {
                text: createWrapper('text'),
                image: createWrapper('image'),
                spacer: createWrapper('spacer'),
                divider: createWrapper('divider')
            };
        }, [props, quotationCode, gir, guide]);
        const titleInputRef = useRef<HTMLInputElement>(null);
        const onContentClick = useMemo((): Parameters<typeof MailTemplateVisualEditor>[number]['onContentClick'] => {
            return {
                image: (context) => {
                    const options = context.blockOptions as ImageBlockOptions;
                    if (context.isBlockSelected || !options.url) {
                        setSelectedImageBlock(context);
                        setOpenPicturesModal(true);
                    }
                }
            };
        }, [dispatch]);
        const providerContext = useContext(ProviderContext);

        const onNewBlock = (
            instanceId: string,
            type: string | symbol
        ) => {
            const selectedBlock = RegisteredBlocks.find((block) => block.type === type);
            if (selectedBlock) {
                dispatch(
                    addAndSelectVisualEditorBlock({
                        instanceId,
                        locale: props.locale,
                        blockType: type
                    })
                );
            }
        };

        const onChangeSelectedBlockPicture = (pictures: Picture[]) => {
            const url = pictures[0]?.url;
            if (selectedImageBlock && url) {
                const newOptions = {
                    ...selectedImageBlock.blockOptions as ImageBlockOptions,
                    url
                };
                const dispatchData = setVisualEditorBlockOptions(
                    selectedImageBlock.instanceId,
                    props.locale,
                    selectedImageBlock.blockId,
                    newOptions
                );
                dispatch(dispatchData);
            }
        };

        const onImportGir = () => {
            const item = gir[0];

            if (longDescriptionIsEmpty && item) {
                let days: number[] = [];

                if (props.mode === 'by-day') {
                    days = props.content.day.map((day) => {
                        return day - props.dayOffset;
                    });
                }

                if (
                    intersection(item.day, days).length > 0 ||
                    props.mode === 'by-step'
                ) {
                    const localization = item.localization.find((item) => {
                        return item.locale === props.locale;
                    });

                    let longDescription = '';

                    if (localization?.long_description && !htmlHasEmptyContent(localization.long_description)) {
                        longDescription = localization.long_description;
                    } else {
                        longDescription = item.long_description ?? '';
                    }

                    let shortDescription = '';

                    if (localization?.short_description && !htmlHasEmptyContent(localization.short_description)) {
                        shortDescription = localization.short_description;
                    } else {
                        shortDescription = item.short_description ?? '';
                    }

                    let value = '';

                    if (!htmlHasEmptyContent(longDescription)) {
                        value = longDescription;
                    } else {
                        value = shortDescription;
                    }

                    if (!htmlHasEmptyContent(value)) {
                        const styles = {
                            ...RichEditorStyleMap,
                            ...(quotationCode ? customColors[quotationCode] : null)
                        };
                        const editorState = restoreRichEditorStateFromHtml(
                            styles,
                            value
                        );

                        let result = editorState;
                        const currentContent = result.getCurrentContent();
                        let selection = result.getSelection();
                        selection = selection.merge({
                            anchorKey: currentContent.getFirstBlock().getKey(),
                            anchorOffset: 0,

                            focusOffset: currentContent.getLastBlock().getText().length,
                            focusKey: currentContent.getLastBlock().getKey()
                        });
                        const newState = Modifier.mergeBlockData(
                            result.getCurrentContent(),
                            selection,
                            Map({ alignment: 'justify' })
                        );
                        result = EditorState.push(result, newState, 'change-block-data');

                        const block = new TextBlock();
                        block.setOptions({
                            ...block.getOptions(),
                            editorState: result
                        });

                        const dispatchData = appendVisualEditorBlock(
                            props.mode === 'by-day' ?
                                `cart-construction-by-day-long-description-${props.content.id}` :
                                `cart-construction-by-step-long-description-${props.content.id}`,
                            props.locale,
                            block
                        );
                        dispatch(dispatchData);
                    }
                }
            }

            const photoIds = (
                props.content.pictures?.map((picture) => {
                    return picture.id;
                }) ?? []
            ).sort((a, b) => {
                return a - b;
            });
            const itemPhotoIds = (
                item?.pictures?.map((picture) => {
                    return picture.id;
                }) ?? []
            ).sort((a, b) => {
                return a - b;
            });

            if (
                itemPhotoIds.length > 0 &&
                (
                    itemPhotoIds.length !== photoIds.length ||
                    !itemPhotoIds.every((a, index) => {
                        const b = photoIds[index]!;
                        return a === b;
                    })
                )
            ) {
                props.onChangeItemPictures(
                    props.content.id,
                    item?.pictures ?? []
                );
            }
        };

        let destination = '';

        if (props.mode === 'by-day') {
            destination = props.content.destinations?.map((destination) => {
                let result = getLocalizedValue(
                    props.locale,
                    destination.data ?? {
                        name: '',
                        localization: []
                    },
                    'name'
                ) ?? '';
                result = result.length > 0 ?
                    result :
                    (
                        destination.data?.international_name ?? ''
                    );
                return result.split(',')[0] ?? '';
            }).join(' - ') ?? '';
        } else {
            destination = getLocalizedValue(
                props.locale,
                props.content.destination?.data ?? {
                    name: '',
                    localization: []
                },
                'name'
            ) ?? '';
            destination = destination.length > 0 ?
                destination :
                (
                    props.content.destination?.data?.international_name ?? ''
                );
            destination = destination.split(',')[0] ?? '';
        }

        if (ref && !isFunction(ref)) {
            ref.current = {
                importGir: onImportGir,
                contentId: props.content.id
            };
        } else if (ref && isFunction(ref)) {
            ref({
                importGir: onImportGir,
                contentId: props.content.id
            });
        }

        useEffect(() => {
            let isCancelled = false;
            (async () => {
                if (props.mode === 'by-day') {
                    const guides = await findDestinationGuideDescription(
                        props.content.destinations?.map((item) => item.id) ?? [],
                        context.mode === 'roadbook' ? true : null,
                        context.mode === 'text' ? true : null
                    );
                    if (!isCancelled) {
                        setGuide(guides);
                    }
                } else {
                    const guides = await findDestinationGuideDescription(
                        props.content.destination?.id ?
                            [props.content.destination.id] :
                            [],
                        context.mode === 'roadbook' ? true : null,
                        context.mode === 'text' ? true : null
                    );
                    if (!isCancelled) {
                        setGuide(guides);
                    }
                }

                if (
                    props.mode === 'by-day' &&
                    props.content.circuit &&
                    props.content.circuit_trip_version
                ) {
                    const gir = await findCircuitGIRContent(props.content.circuit);
                    const days = props.content.day.map((day) => {
                        return day - props.dayOffset;
                    });
                    setGir(
                        gir.filter((item) => {
                            return item.trip_version === props.content.circuit_trip_version &&
                                intersection(days, item.day).length > 0;
                        })
                    );
                } else if (
                    props.mode === 'by-day' &&
                    props.prevStepIsCircuit?.circuit &&
                    props.prevStepIsCircuit?.circuit_trip_version
                ) {
                    const gir = await findCircuitGIRContent(props.prevStepIsCircuit.circuit);
                    const days = props.content.day.map((day) => {
                        return day - props.dayOffset;
                    });
                    setGir(
                        gir.filter((item) => {
                            return item.trip_version === props.prevStepIsCircuit!.circuit_trip_version &&
                                intersection(days, item.day).length > 0;
                        })
                    );
                }
            })();
            return () => {
                isCancelled = true;
            };
        }, [context.mode, props.mode, props.content]);

        return (
            <>
                <Box
                    sx={{
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        marginBottom: 3,
                        padding: 1.5
                    }}
                >
                    <Box>
                        <Stack
                            direction="row"
                            alignItems="flex-start"
                            spacing={0.5}
                            sx={{
                                color: '#757575',
                                position: 'sticky',
                                top: !providerContext.module ?
                                    150 + (props.stickyOffset ?? 0) :
                                    0,
                                backgroundColor: '#fff',
                                zIndex: 1
                            }}
                        >
                            <Tooltip title={t('cart-material.cart-construction-change-products-order')}>
                                <IconButton
                                    size="small"
                                    color="inherit"
                                    onClick={() => setOpenProductsReorderModal(true)}
                                >
                                    <Sort />
                                </IconButton>
                            </Tooltip>
                            {
                                props.mode === 'by-step' ?
                                    <HouseSiding
                                        sx={{
                                            fontSize: '2.5em',
                                            position: 'relative',
                                            top: '4px'
                                        }}
                                    /> :
                                    <WbSunnyTwoTone
                                        sx={{
                                            fontSize: '2.5em',
                                            position: 'relative',
                                            top: '4px'
                                        }}
                                    />
                            }
                            <Box sx={{ flex: 1 }}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={0.5}
                                    sx={{ color: '#757575' }}
                                >
                                    {
                                        !providerContext.module &&
                                        <ResetMenu
                                            {...props}
                                            gir={gir}
                                            guide={guide}
                                            locale={props.locale}
                                            valueType="title"
                                            sources={['guide', 'gir']}
                                            onChoose={(value) => props.onChangeItemTitle(props.content.id, value)}
                                        />
                                    }
                                    <Typography
                                        sx={{
                                            fontWeight: 700,
                                            display: 'flex',
                                            flex: 1,
                                            alignItems: 'center'
                                        }}
                                        component="div"
                                    >
                                        {
                                            props.mode === 'by-day' ?
                                                t(
                                                    'cart-material.cart-construction-content-title-by-day',
                                                    {
                                                        day: [...props.content.day].sort((a, b) => a - b).join(', ')
                                                    }
                                                ) :
                                                t(
                                                    'cart-material.cart-construction-content-title-by-step',
                                                    {
                                                        no: (props.index ?? -1) + 1
                                                    }
                                                )
                                        }
                                        <Box
                                            sx={{
                                                flex: 1,
                                                display: 'flex',
                                                borderBottom: '1px solid #757575'
                                            }}
                                        >
                                            <TitleInput
                                                ref={titleInputRef}
                                                type="text"
                                                value={props.titles[props.locale] ?? ''}
                                                onChange={(event) => props.onChangeItemTitle(props.content.id, event.target.value)}
                                            />
                                            <IconButton
                                                sx={{ fontSize: '1rem' }}
                                                onClick={() => titleInputRef.current?.focus()}
                                            >
                                                <Edit fontSize="inherit" />
                                            </IconButton>
                                        </Box>
                                    </Typography>
                                </Stack>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        verticalAlign: 'top',
                                        marginLeft: !providerContext.module ?
                                            '39px' :
                                            '112px',
                                        position: 'relative',
                                        top: 4
                                    }}
                                >
                                    {
                                        t(
                                            'cart-material.cart-construction-content-title-destination',
                                            {
                                                name: destination,
                                                date: window.moment.utc(from).format('LL') !== window.moment.utc(to).format('LL') ?
                                                    t(
                                                        'cart-material.cart-construction-products-table-date',
                                                        {
                                                            from: window.moment.utc(from).format('LL'),
                                                            to: window.moment.utc(to).format('LL')
                                                        }
                                                    ) :
                                                    window.moment.utc(from).format('LL')
                                            }
                                        )
                                    }
                                </Typography>
                            </Box>
                        </Stack>
                        <Box
                            sx={{
                                "position": 'relative',
                                "width": 'calc(100% + 44px)',
                                "marginLeft": '-44px',
                                "paddingTop": 2,
                                "paddingLeft": '44px',
                                "paddingBottom": 2,
                                '& .floating-actions-button': {
                                    display: !providerContext.module ? 'none' : undefined,
                                    visibility: !providerContext.module ? 'visible' : undefined
                                },
                                '&:hover .floating-actions-button': {
                                    display: 'block'
                                }
                            }}
                        >
                            {
                                context.mode === 'roadbook' &&
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    sx={{ marginBottom: 1 }}
                                    spacing={1.5}
                                >
                                    <InputTitle>
                                        {t('cart-material.cart-construction-short-text')}
                                    </InputTitle>
                                </Stack>
                            }
                            <Box
                                sx={{
                                    visibility: longDescriptionHasBlocks || providerContext.module ?
                                        'visible' :
                                        'hidden',
                                    height: longDescriptionHasBlocks || providerContext.module ?
                                        'auto' :
                                        10,
                                }}
                            >
                                <CartConstructionContentsFormItemFloatingAddButton
                                    onAdd={(type) => onNewBlock(
                                        props.mode === 'by-day' ?
                                            `cart-construction-by-day-long-description-${props.content.id}` :
                                            `cart-construction-by-step-long-description-${props.content.id}`,
                                        type
                                    )}
                                />
                                {
                                    longDescriptionHasBlocks &&
                                    <CartConstructionContentsFormLongDescriptionInput
                                        id={props.content.id}
                                        mode={props.mode}
                                        locale={props.locale}
                                        edit={false}
                                        customActions={customActions}
                                        onContentClick={onContentClick}
                                    />
                                }
                            </Box>
                        </Box>
                        {
                            context.mode === 'roadbook' &&
                            <Box
                                sx={{
                                    "position": 'relative',
                                    "width": 'calc(100% + 44px)',
                                    "marginLeft": '-44px',
                                    "paddingTop": 2,
                                    "paddingLeft": '44px',
                                    "paddingBottom": 2,
                                    '& .floating-actions-button': {
                                        display: 'none',
                                        visibility: 'visible'
                                    },
                                    '&:hover .floating-actions-button': {
                                        display: 'block'
                                    }
                                }}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    sx={{ marginBottom: 1 }}
                                    spacing={1.5}
                                >
                                    <InputTitle>
                                        {t('cart-material.cart-construction-long-text')}
                                    </InputTitle>
                                </Stack>
                                <Box
                                    sx={{
                                        visibility: shortDescriptionHasBlocks ?
                                            'visible' :
                                            'hidden',
                                        height: shortDescriptionHasBlocks ?
                                            'auto' :
                                            10
                                    }}
                                >
                                    <CartConstructionContentsFormItemFloatingAddButton
                                        onAdd={(type) => onNewBlock(
                                            props.mode === 'by-day' ?
                                                `cart-construction-by-day-short-description-${props.content.id}` :
                                                `cart-construction-by-step-short-description-${props.content.id}`,
                                            type
                                        )}
                                    />
                                    <CartConstructionContentsFormShortDescriptionInput
                                        id={props.content.id}
                                        mode={props.mode}
                                        locale={props.locale}
                                        edit={false}
                                        customActions={customActions}
                                        onContentClick={onContentClick}
                                    />
                                </Box>
                            </Box>
                        }
                        {
                            context.mode === 'text' &&
                            showPictures &&
                            <Box>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    sx={{ color: '#757575', marginBottom: 1 }}
                                    spacing={1.5}
                                >
                                    <InputTitle>
                                        {t('shared.circuit-step-image')}
                                    </InputTitle>
                                    {
                                        !providerContext.module &&
                                        <ResetMenu
                                            {...props}
                                            gir={gir}
                                            guide={guide}
                                            locale={props.locale}
                                            valueType="pictures"
                                            sources={['gir']}
                                            destinations={
                                                props.mode === 'by-day' ?
                                                    props.content.destinations ?? [] :
                                                    [props.content.destination].filter((item): item is NonNullable<typeof item> => {
                                                        return !!item;
                                                    })
                                            }
                                            onChoose={(value) => props.onChangeItemPictures(props.content.id, value)}
                                        />
                                    }
                                </Stack>
                                <CartConstructionContentsFormPictureInput
                                    pictures={props.pictures}
                                    onChange={(picture) => props.onChangeItemPictures(props.content.id, picture)}
                                />
                            </Box>
                        }
                    </Box>
                    <Box sx={{ marginTop: 2 }}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ marginBottom: 1 }}
                            spacing={1.5}
                        >
                            <InputTitle>
                                {t('shared.circuit-products')}
                            </InputTitle>
                        </Stack>
                        <CartConstructionContentsFormProductsInput
                            index={props.index ?? -1}
                            mode={props.mode as any}
                            content={props.content as any}
                            locale={props.locale}
                            edit={false}
                            customActions={customActions}
                            onContentClick={onContentClick}
                            startDate={from}
                            endDate={to}
                        />
                    </Box>
                </Box>
                {
                    openProductsReorderModal &&
                    <CartConstructionContentsFormReorderProductsModal
                        index={props.index ?? -1}
                        content={props}
                        onClose={() => setOpenProductsReorderModal(false)}
                    />
                }
                {
                    openPicturesModal &&
                    <div
                        onMouseDown={(event) => {
                            event.stopPropagation();
                            event.nativeEvent.stopImmediatePropagation();
                        }}
                    >
                        <PictureModalCreateForm
                            onClose={() => setOpenPicturesModal(false)}
                            onChangePictures={onChangeSelectedBlockPicture}
                            singleFile
                        />
                    </div>
                }
            </>
        );
    }
);

type ResetMenuSource = 'guide' | 'gir'

type ResetMenuValueType = 'title' |
    'short-description' |
    'long-description' |
    'pictures'

type ResetMenuProps<T extends ResetMenuValueType> = {
    gir: ItineraryByDay[],
    guide: Guide[],
    locale: CartConstructionContentsFormItemProps['locale'],
    index?: CartConstructionContentsFormItemProps['index'],
    valueType: T | T[],
    sources: ResetMenuSource[],
    destinations?: Destination[]
    onChoose: (value: T extends 'pictures' ? Picture[] : string) => void
} & ItineraryContentItem

function ResetMenu<T extends ResetMenuValueType>(props: ResetMenuProps<T>): JSX.Element {
    const { t, i18n } = useTranslation();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLButtonElement>(null);
    const providerContext = useContext(ProviderContext);

    const onChooseGuide = (rubric: Guide['rubrics'][number]) => {
        const localization = rubric.localization.find((item) => {
            return item.locale === props.locale;
        });
        const text = props.valueType === 'title' ?
            localization?.title ?? '' :
            localization?.description ?? '';
        props.onChoose(text as Parameters<typeof props.onChoose>[0]);
        setOpen(false);
    };

    const onChooseGir = (id: number) => {
        const content = props.gir.find((item) => {
            return item.id === id;
        });
        const localization = content?.localization.find((item) => {
            return item.locale === props.locale;
        });

        if (content) {
            const types = isArray(props.valueType) ? props.valueType : [props.valueType];
            for (const type of types) {
                let result: Parameters<typeof props.onChoose>[0] | null = null;
                switch (type) {
                    case 'long-description': {
                        result = (
                            localization?.long_description ??
                            content.long_description ??
                            ''
                        ) as Parameters<typeof props.onChoose>[0];
                        break;
                    }
                    case 'short-description': {
                        result = (
                            localization?.short_description ??
                            content.short_description ??
                            ''
                        ) as Parameters<typeof props.onChoose>[0];
                        break;
                    }
                    case 'title': {
                        result = (
                            localization?.title ??
                            content?.title ??
                            ''
                        ) as Parameters<typeof props.onChoose>[0];
                        break;
                    }
                    case 'pictures': {
                        result = content.pictures as Parameters<typeof props.onChoose>[0];
                        break;
                    }
                }
                if (isString(result) && htmlHasEmptyContent(result)) {
                    continue;
                } else if (result) {
                    props.onChoose(result);
                    break;
                }
            }
        }

        setOpen(false);
    };

    const onChooseDestination = async (destination: Destination) => {
        const data = await getQuickDestination(destination.id);
        props.onChoose(
            (data?.pictures ?? []) as Parameters<typeof props.onChoose>[0]
        );
        setOpen(false);
    };

    return (
        <>
            {
                !providerContext.module &&
                <Tooltip title={t('shared.import')} disableInteractive>
                    <IconButton
                        ref={ref}
                        size="small"
                        color="inherit"
                        onClick={() => setOpen((state) => !state)}
                    >
                        <FileDownloadOutlined />
                    </IconButton>
                </Tooltip>
            }
            <Menu
                open={open}
                anchorEl={ref.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                onClose={() => setOpen(false)}
                sx={{ minWidth: 250, maxWidth: '100%' }}
            >
                {
                    props.valueType !== 'pictures' &&
                    props.guide.length === 0 &&
                    <MenuItem disabled>
                        {
                            t('cart-material.cart-construction-guide-not-found')
                        }
                    </MenuItem>
                }
                {
                    props.valueType !== 'pictures' &&
                    props.guide.length > 0 &&
                    props.guide.map((guide) => {
                        return guide.rubrics.map((rubric) => {
                            const localization = rubric.localization.find((item) => {
                                return item.locale === props.locale;
                            });
                            return (
                                <MenuItem
                                    key={rubric.id}
                                    onClick={() => onChooseGuide(rubric)}
                                    disabled={!props.sources.includes('guide')}
                                >
                                    <Typography noWrap>
                                        {
                                            t(
                                                'cart-material.cart-construction-guide',
                                                {
                                                    destination: localization?.title ?? ''
                                                }
                                            )
                                        }
                                    </Typography>
                                </MenuItem>
                            );
                        });
                    })
                }
                {
                    props.gir.map((item) => {
                        const localization = item.localization.find((item) => {
                            return item.locale === props.locale;
                        });
                        return (
                            <MenuItem
                                key={item.id}
                                onClick={() => onChooseGir(item.id)}
                                disabled={
                                    !props.sources.includes('gir') ||
                                    (
                                        props.valueType === 'pictures' &&
                                        !item.pictures
                                    )
                                }
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                <Typography noWrap>
                                    {
                                        t(
                                            'cart-material.cart-construction-gir-day',
                                            {
                                                day: item.day.join(', '),
                                                destination: localization?.title ?? item.title
                                            }
                                        )
                                    }
                                </Typography>
                            </MenuItem>
                        );
                    })
                }
                {
                    props.valueType === 'pictures' &&
                    props.destinations?.map((destination) => (
                        <MenuItem
                            key={destination.id}
                            onClick={() => onChooseDestination(destination)}
                        >
                            {
                                t(
                                    'shared.circuit-step-destination',
                                    {
                                        name: (
                                            destination.data?.localization.find((item) => {
                                                return item.locale === locale;
                                            })?.name ??
                                            destination.data?.international_name
                                        )?.split(',')[0]
                                    }
                                )
                            }
                        </MenuItem>
                    ))
                }
            </Menu>
        </>
    );
}

const TitleInput = styled('input')(() => ({
    background: 'none',
    border: 'none',
    color: '#757575',
    marginLeft: 1,
    flex: 1
}));

const InputTitle = styled(Typography)(() => ({
    color: '#757575',
    fontWeight: 700
}));
