import axios, { AxiosResponse } from "axios";
import { useProviderQuotationProducts } from "./providerQuotationProducts";
import { reverseProviderQuotationStatus } from "./getProviderQuotationProductStatus";
import {
    makeAccommodationEditRequest,
    makeCarEditRequest,
    makeCustomProductEditRequest,
    makeFlightEditRequest,
    makeManualProductEditRequest,
    makeTransferEditRequest
} from "../../CartMaterial/utils/editProductRequests";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { TripVersion } from "../../Menu/MaterialTripList/objects/tripVersion";
import { ProviderQuotationProductStatus } from "../objects/providerQuotationProductStatus";

type Callback = (
    statuses: {
        [key: number]: {
            id: number,
            isCustom: boolean,
            type: ReturnType<typeof useProviderQuotationProducts>[number]['type'],
            status: ProviderQuotationProductStatus | null,
            providerComment: string | null
        }
    },
    prices: {
        terrestrial: {
            amount: number,
            currency: number
        } | null,
        flight?: {
            amount: number,
            currency: number
        } | null,
        total: {
            amount: number,
            currency: number
        } | null,
    },
    options: {
        tripId: number,
        stackNumber: number,
        version: number,
        providerId: number,
        tripToken: string
    }
) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: () => void,
    onError: (error: Error) => void,
    onFinally: () => void
}>

export function useProviderQuotationConfirm(options: Options): Callback {
    return async (statuses, prices, requestOptions) => {
        try {
            if (options.onTrigger) {
                options.onTrigger();
            }
            for (const item of Object.values(statuses)) {
                if (!item.status) {
                    continue;
                }
                if (
                    (
                        item.type === 'transfers' ||
                        item.type === 'pois'
                    ) &&
                    item.isCustom
                ) {
                    await makeCustomProductEditRequest({
                        ...requestOptions,
                        ...item,
                        data: {
                            ...reverseProviderQuotationStatus(item.status),
                            is_stack_price: true,
                            provider_comment: (item.providerComment?.trim().length ?? 0) === 0 ?
                                null :
                                item.providerComment
                        }
                    });
                } else {
                    switch (item.type) {
                        case 'flights': {
                            await makeFlightEditRequest({
                                ...requestOptions,
                                ...item,
                                data: {
                                    ...reverseProviderQuotationStatus(item.status),
                                    is_stack_price: true,
                                    provider_comment: (item.providerComment?.trim().length ?? 0) === 0 ?
                                        null :
                                        item.providerComment
                                }
                            });
                            break;
                        }
                        case 'cars': {
                            await makeCarEditRequest({
                                ...requestOptions,
                                ...item,
                                data: {
                                    ...reverseProviderQuotationStatus(item.status),
                                    is_stack_price: true,
                                    provider_comment: (item.providerComment?.trim().length ?? 0) === 0 ?
                                        null :
                                        item.providerComment
                                }
                            });
                            break;
                        }
                        case 'accommodations': {
                            await makeAccommodationEditRequest({
                                ...requestOptions,
                                ...item,
                                data: {
                                    ...reverseProviderQuotationStatus(item.status),
                                    is_stack_price: true,
                                    provider_comment: (item.providerComment?.trim().length ?? 0) === 0 ?
                                        null :
                                        item.providerComment
                                }
                            });
                            break;
                        }
                        case 'transfers': {
                            await makeTransferEditRequest({
                                ...requestOptions,
                                ...item,
                                data: {
                                    ...reverseProviderQuotationStatus(item.status),
                                    is_stack_price: true,
                                    provider_comment: (item.providerComment?.trim().length ?? 0) === 0 ?
                                        null :
                                        item.providerComment
                                }
                            });
                            break;
                        }
                        case 'pois': {
                            await makeCustomProductEditRequest({
                                ...requestOptions,
                                ...item,
                                data: {
                                    ...reverseProviderQuotationStatus(item.status),
                                    is_stack_price: true,
                                    provider_comment: (item.providerComment?.trim().length ?? 0) === 0 ?
                                        null :
                                        item.providerComment
                                }
                            });
                            break;
                        }
                        case 'pois': {
                            await makeCustomProductEditRequest({
                                ...requestOptions,
                                ...item,
                                data: {
                                    ...reverseProviderQuotationStatus(item.status),
                                    provider_comment: (item.providerComment?.trim().length ?? 0) === 0 ?
                                        null :
                                        item.providerComment
                                }
                            });
                            break;
                        }
                        default: {
                            await makeManualProductEditRequest({
                                ...requestOptions,
                                ...item,
                                data: {
                                    ...reverseProviderQuotationStatus(item.status),
                                    is_stack_price: true,
                                    provider_comment: (item.providerComment?.trim().length ?? 0) === 0 ?
                                        null :
                                        item.providerComment
                                }
                            });
                            break;
                        }
                    }
                }
            }
            await makeUpdatePriceRequest({
                ...requestOptions,
                prices
            });
            await makeConfirmationMailRequest(requestOptions);
            if (options.onSuccess) {
                options.onSuccess();
            }
        } catch (error: any) {
            if (options.onError) {
                options.onError(error);
            }
        } finally {
            if (options.onFinally) {
                options.onFinally();
            }
        }
    };
}

type MailRequestOptions = {
    tripId: number,
    version: number,
    tripToken: string,
    providerId: number,
    stackNumber: number
}

async function makeConfirmationMailRequest(options: MailRequestOptions): Promise<void> {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        await axios.get(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/send_provider_quotation_response_template/`,
            {
                headers,
                params: {
                    provider_id: options.providerId,
                    stack_number: options.stackNumber,
                    token: options.tripToken
                }
            }
        );
    }
}

type UpdatePriceRequestOptions = {
    tripId: number,
    version: number,
    tripToken: string,
    stackNumber: number,
    providerId: number | null,
    prices: {
        terrestrial: {
            amount: number,
            currency: number
        } | null,
        flight?: {
            amount: number,
            currency: number
        } | null,
        total: {
            amount: number,
            currency: number
        } | null,
    },
}

async function makeUpdatePriceRequest(options: UpdatePriceRequestOptions): Promise<AxiosResponse<TripVersion> | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check && options.providerId) {
        let data: Record<string, unknown> = {};

        if (options.prices.terrestrial || options.prices.flight) {
            data = {
                terrestrial_purchase_price: options.prices.terrestrial?.amount.toString(),
                terrestrial_purchase_currency: options.prices.terrestrial?.currency,
                terrestrial_stack_number: options.stackNumber,
                terrestrial_provider_id: options.providerId,
                flight_purchase_price: options.prices.flight?.amount.toString(),
                flight_purchase_currency: options.prices.flight?.currency,
                flight_stack_number: options.prices.flight ? options.stackNumber : undefined,
                flight_provider_id: options.prices.flight ? options.providerId : undefined,
                stack_number: options.stackNumber,
                provider_id: options.providerId,
                stack_price_delete: true
            }
        } else {
            data = {
                terrestrial_stack_number: options.stackNumber,
                terrestrial_provider_id: options.providerId,
                terrestrial_price_delete: true,
                flight_stack_number: options.stackNumber,
                flight_provider_id: options.providerId,
                flight_price_delete: true,
                stack_purchase_price: options.prices.total?.amount.toString(),
                stack_purchase_currency: options.prices.total?.currency,
                stack_number: options.stackNumber,
                provider_id: options.providerId
            }
        }
        return axios.patch<TripVersion>(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/`,
            data,
            {
                headers,
                params: {
                    token: options.tripToken
                }
            }
        );
    }

    return null;
}
