import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { makeStyles, useTheme } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import Divider from '@material-ui/core/Divider';

import PoiCardHeader from "./PoiCardComponents/PoiCardHeader";
import PoiCardPictures from "./PoiCardComponents/PoiCardPictures";
import Picture from "./PoiCardComponents/Picture";
import MosaicPicture from "./PoiCardComponents/MosaicPicture";
import PoiCardPrices from "./PoiCardComponents/PoiCardPrices";
import PoiCardPricesBottom from "./PoiCardComponents/PoiCardPricesBottom";
import PoiCardPreCart from "./PoiCardComponents/PoiCardPreCart";
import AboutPoi from "./PoiCardComponents/AboutPoi";
import PoiMap from "./PoiCardComponents/PoiMap";
import ActivityCardStandaloneModal from "./PoiCardComponents/ActivityCardStandaloneModal";

import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";

import { SetCurrentGroup, SetDates, SetActiveDestination, AddToCart } from "../../Actions/Poi";

import axios from "axios";
import moment from "moment";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { CartConstructionReplaceProductContext } from "../CartMaterial/utils/cartConstructionReplaceProductContext";

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 1128
    },
    filterTitle: {
        float: "left",
        marginTop: 10,
        marginRight: 8
    },
    stickyPaper: {
        position: "sticky",
        top: 80,
        borderRadius: 12
    },
    alignCenter: {
        textAlign: "center"
    },
    staticPriceContainerSkeleton: {
        position: 'fixed',
        bottom: 0
    },
    noPaddingTop: {
        paddingTop: '0px !important'
    },
    noMarginBottom: {
        marginBottom: 0
    },
    smallFont: {
        fontSize: '0.7rem'
    }
}));

const PoiCard = ({ router }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const active = useSelector(state => state.base.active);
    const page_list = useSelector(store => store.poi.page_list);
    const start_date = useSelector(store => store.poi.start_date);
    const end_date = useSelector(store => store.poi.end_date);
    const destination = useSelector(store => store.poi.destination);
    const reducer_current_groups = useSelector(store => store.poi.current_groups);
    const travelers = useSelector(state => state.trip.travelers);
    const itinerary_list = useSelector(state => state.itinerary.itinerary_list);
    const language = useSelector(state => state.header.tmp_language);
    const trip_id = useSelector(state => state.trip.trip_id);
    const traveler_groups = useSelector(state => state.trip.traveler_groups);

    const [activity, setActivity] = useState(null);
    const [manualVariant, setManualVariant] = useState("");

    const [defaultPictures, setDefaultPictures] = useState([
        "/Img/poi_default.jpg",
        "/Img/poi_default.jpg",
        "/Img/poi_default.jpg",
        "/Img/poi_default.jpg",
        "/Img/poi_default.jpg"
    ]);
    const [modalPictures, setModalPictures] = useState([]);
    const [currentPicture, setCurrentPicture] = useState(0);
    const [mosaicModalOpen, setMosaicModalOpen] = useState(false);
    const [picturesModalOpen, setPicturesModalOpen] = useState(false);
    const [currentTravelers, setCurrentTravelers] = useState(0);
    const [productSelected, setProductSelected] = useState([]);
    const [assignedGroups, setAssignedGroups] = useState([]);
    const [addingToCart, setAddingToCart] = useState(false);
    const [standalone_modal_open, setStandaloneModalOpen] = useState(false);
    const [openChildActivity, setOpenChildActivity] = useState(false);
    const [current_groups, setCurrentGroups] = useState(null);
    const [variantChosen, setVariantChosen] = useState("");
    const replaceProductContext = useContext(CartConstructionReplaceProductContext);

    const activity_id = decodeURIComponent(router.params.id);

    const preCartRef = useRef(null);

    const toggleMosaicModal = (pictures) => {
        setMosaicModalOpen(!mosaicModalOpen);
        setModalPictures(pictures);
    };
    const togglePictureModal = (pictures, index_picture = 0) => {
        setPicturesModalOpen(!picturesModalOpen);
        setModalPictures(pictures);
        setCurrentPicture(index_picture);
    };
    const toggleStandaloneModal = () => {
        setStandaloneModalOpen(!standalone_modal_open);
        dispatch({ type: 'CART_LEAVE_CART' });
    };
    const standaloneSuccess = () => {
        setStandaloneModalOpen(!standalone_modal_open);
        router.push(`/${window.url_name}/apps/poi`);
    };
    const handleChildActivity = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpenChildActivity(!openChildActivity);
        }
    };
    const checkChild = () => {
        let has_adult = true;
        current_groups.map((group) => {
            let has_group_adult = false;
            group.travelers_list.map((traveler) => {
                if (moment().diff(traveler.birth_date, "y") > 18) {
                    has_group_adult = true;
                }
            });
            if (!has_group_adult) {
                has_adult = false;
            }
        });
        if (!has_adult) {
            setOpenChildActivity(true);
            //enqueueSnackbar(t("poi.group_without_adult"), {variant: "warning", autoHideDuration: 4000, disableWindowBlurListener: true });
        } else {
            addToCart();
        }
    };
    const addToCart = () => {
        setAddingToCart(true);
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            productSelected.map((product) => {
                let request = Object.assign({}, product);
                delete request.group_index;
                delete request.detail_price;
                delete request.total_price;
                delete request.variant_name;
                axios({
                    method: "post",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/custom-products/?token=${GetCookie("trip_token")}`,
                    data: request
                }).then(function (response) {
                    enqueueSnackbar(t("poi.add_to_cart_success"), { variant: "success", autoHideDuration: 4000, disableWindowBlurListener: true });
                    dispatch(AddToCart(response.data));
                    setAddingToCart(false);
                    if (replaceProductContext.enableReplace && replaceProductContext.onDelete) {
                        replaceProductContext.onDelete();
                    }
                    if (!replaceProductContext.enableReplace) {
                        router.push(`/${window.url_name}/apps/poi`);
                    } else if (replaceProductContext.onClose) {
                        replaceProductContext.onClose();
                    }
                }).catch(function (error) {
                    console.error(error);
                    enqueueSnackbar(t("poi.add_to_cart_failed"), { variant: "error", autoHideDuration: 4000, disableWindowBlurListener: true });
                    setAddingToCart(false);
                });
            });
        }
    };

    useEffect(() => {
        let { pass_check, headers } = CheckBeforeRequest();
        let found = false;
        let new_current_groups = [];
        let current_itinerary = null;
        console.log('current_groups:', current_groups);
        if (current_groups === null) {
            //traveler_groups.map((traveler_group) => {
            //if (traveler_group.default) {
            //new_current_groups.push({...traveler_group});
            //}
            //});
            let travelers_id = [];
            travelers.map((traveler) => {
                travelers_id.push(traveler.id);
            });
            let request = {
                default: false,
                travelers: travelers_id
            };

            axios({
                method: "POST",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/?token=${GetCookie("trip_token")}`,
                data: request
            }).then(function (response) {
                new_current_groups.push(response.data);
                let temp_current_travelers = 0;
                new_current_groups.map((group) => {
                    temp_current_travelers += group.travelers.length;
                });
                setCurrentTravelers(temp_current_travelers);
            });
            //dispatch(SetCurrentGroup(new_current_groups));
            setCurrentGroups(new_current_groups);
        }
        if (destination === null) {
            itinerary_list.map((itinerary_step) => {
                if (itinerary_step.step_type === "STEP" && itinerary_step.id === active) {
                    current_itinerary = itinerary_step;
                    dispatch(SetDates(moment.utc(itinerary_step.start_date).format('YYYY-MM-DD'), moment.utc(itinerary_step.end_date).format('YYYY-MM-DD')));
                    dispatch(SetActiveDestination(itinerary_step));
                }
            });
        }
        if (page_list.length !== 0) {
            page_list.map((loaded_activity) => {
                let tmp_activity = { ...loaded_activity };
                if (loaded_activity.id.toString() === activity_id && !tmp_activity.is_custom) {
                    found = true;
                    setActivity(tmp_activity);
                }
            });
        }
        const active_bounds = JSON.parse(destination !== null ? destination.destination.data.bounds.replace(/'/g, '"') : current_itinerary.destination.data.bounds.replace(/'/g, '"'));
        dispatch({
            type: "POI_SET_RELOAD_DATA",
            payload: {
                active: destination !== null ? destination.id : current_itinerary.id,
                destination: destination !== null ? destination : current_itinerary,
                current_groups: current_groups !== null ? current_groups : new_current_groups,
                start_date: (start_date !== null ? start_date : moment.utc(current_itinerary.start_date).format("YYYY-MM-DD")),
                end_date: (end_date !== null ? end_date : moment.utc(current_itinerary.end_date).format("YYYY-MM-DD")),
                bounds: {
                    north: active_bounds.north,
                    east: active_bounds.east,
                    south: active_bounds.south,
                    west: active_bounds.west
                }
            }
        });
        if (!found) {
            // load activity on refresh
            if (pass_check){
                axios({
                    method: "get",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/custom-products/${activity_id}/`
                }).then(function(response){
                    let tmp_activity = {...response.data}
                    if (tmp_activity.is_custom && (tmp_activity.poi_type === undefined || !tmp_activity.poi_type)) {
                        let minimmal_request = [];
                        let groups_ids = [];
                        if (current_groups !== null && current_groups.length !== 0) {
                            current_groups.map((group) => {
                                groups_ids.push(group.id);
                            });
                        } else {
                            new_current_groups.map((group) => {
                                groups_ids.push(group.id);
                            });
                        }
                        let providers_available = [];
                        if (tmp_activity.provider !== null) {
                            providers_available.push(tmp_activity.provider);
                        }
                        if (tmp_activity.providers !== undefined && tmp_activity.providers !== null && tmp_activity.providers.length > 0) {
                            providers_available = [...providers_available, ...tmp_activity.providers];
                        }
                        providers_available = providers_available.filter((value, index, self) =>
                            value.provider.is_activated && index === self.findIndex((t) => (
                                t.id === value.id
                            ))
                        );
                        if (tmp_activity.contract !== undefined && tmp_activity.contracts !== null) {
                            providers_available = providers_available.filter((provider, index) => {
                                let contract = tmp_activity.contracts.find((contract) => {
                                    return contract.provider.id === provider.id;
                                });
                                return contract !== undefined && contract.activated;
                            });
                        }
                        providers_available.map(provider => {
                            minimmal_request.push(
                                axios({
                                    method: "POST",
                                    headers: headers,
                                    url: `${API_HREF}client/${window.id_owner}/custom-products/minimal_price/?custom_provider=true&arrival_date=${moment(current_itinerary !== null ? current_itinerary.start_date : destination.start_date).format('YYYY-MM-DD')}&departure_date=${moment(current_itinerary !== null ? current_itinerary.end_date : destination.end_date).format('YYYY-MM-DD')}&start_destination=${current_itinerary !== null ? current_itinerary.destination.id : destination.destination.id}&provider_id=${provider.provider.id}&comps=${groups_ids}&trip=${trip_id}&language=${language}`,
                                    data: JSON.stringify({
                                        product_identifiers: [tmp_activity.id]
                                    })
                                })
                            );
                        });
                        axios.all([...minimmal_request]).then(axios.spread((...responses) => {
                            responses.map((response, response_index) => {
                                if (response.data.length > 0 && response.data[0].prices !== undefined && response.data[0].prices !== null && response.data[0].prices.length !== 0) {
                                    if (tmp_activity.provider !== null) {
                                        if (response.config.url.includes(`provider_id=${tmp_activity.provider.provider.id}`)) {
                                            tmp_activity.provider.price = response.data[0].prices[0];
                                            tmp_activity.provider.catalog_price = response.data[0].catalog_price;
                                        }
                                    }
                                    if (tmp_activity.providers !== undefined && tmp_activity.providers !== null && tmp_activity.providers.length !== 0) {
                                        let find_provider = tmp_activity.providers.findIndex(el => response.config.url.includes(`provider_id=${el.provider.id}`));
                                        if (find_provider !== -1) {
                                            tmp_activity.providers[find_provider].price = response.data[0].prices[0];
                                            tmp_activity.providers[find_provider].catalog_price = response.data[0].catalog_price;
                                        }
                                    }
                                }
                            });
                            if (tmp_activity.variants !== undefined && tmp_activity.variants.length > 0) {
                                let tmp_variants = tmp_activity.variants.filter(variant => variant.is_active);
                                tmp_variants = tmp_variants.sort((a, b) => (a.id > b.id) ? 1 : -1);
                                tmp_activity.variants = tmp_variants;
                                setManualVariant(tmp_variants[0]);
                            }
                            setActivity(tmp_activity);
                        }));
                    } else {
                        if (tmp_activity.variants !== undefined && tmp_activity.variants.length > 0) {
                            let tmp_variants = tmp_activity.variants.filter(variant => variant.is_active);
                            tmp_variants = tmp_variants.sort((a, b) => (a.id > b.id) ? 1 : -1);
                            tmp_activity.variants = tmp_variants;
                            setManualVariant(tmp_variants[0]);
                        }
                        setActivity(tmp_activity);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            }
        }
    }, []);

    useEffect(() => {
        if (current_groups !== null && current_groups.length !== 0) {
            let temp_current_travelers = 0;
            current_groups.map((group) => {
                temp_current_travelers += group.travelers.length;
            });
            setCurrentTravelers(temp_current_travelers);
        }
    }, [current_groups]);
    return (
        <Container className={classes.container}>
            {
                !replaceProductContext.enableReplace &&
                <>
                    <Grid container spacing={ 3 }>
                        <Grid item xs={ 12 }/>
                        <Grid item xs={ 12 }>
                            {
                                activity !== null ? (
                                    <PoiCardHeader router={router} activity={activity} />
                                ) : (
                                    <Fragment>
                                        <Skeleton/>
                                        <Skeleton/>
                                    </Fragment>
                                )
                            }
                        </Grid>
                        {
                            activity !== null ? (
                                <PoiCardPictures activity={(manualVariant !== "" && !activity.poi_type) ? manualVariant : activity} default_pictures={defaultPictures} togglePictureModal={togglePictureModal} toggleMosaicModal={toggleMosaicModal} />
                            ) : (
                                <Fragment>
                                    <Grid item xs={12}>
                                        <Skeleton variant={ "rect" } width={ "100%" } height={ 300 }/>
                                    </Grid>
                                </Fragment>
                            )
                        }
                    </Grid>
                    {
                        activity !== null && (
                            <Fragment>
                                <MosaicPicture open={ mosaicModalOpen } onClose={ toggleMosaicModal } pictures={ modalPictures } openModal={ togglePictureModal }/>
                                <Picture open={ picturesModalOpen } onClose={ togglePictureModal } pictures={ modalPictures } current_picture={ currentPicture } setCurrentPicture={ setCurrentPicture }/>
                            </Fragment>
                        )
                    }
                    { activity === null && <br /> }
                </>
            }
            <Grid item container direction={ "row-reverse" } spacing={ 3 }>
                <Grid item xs={ 12 } md={ 4 }>
                    {
                        !mobile && (
                            <Card className={ classes.stickyPaper } raised>
                                {
                                    activity !== null && destination !== null && start_date !== null && end_date !== null && current_groups !== null ? (
                                        <PoiCardPrices start_date={start_date} end_date={end_date} activity={activity} current_travelers={currentTravelers} productSelected={productSelected} setProductSelected={setProductSelected} assignedGroups={assignedGroups} setAssignedGroups={setAssignedGroups} addToCart={checkChild} addingToCart={addingToCart} openStandalone={ toggleStandaloneModal } variantChosen={variantChosen} setVariantChosen={setVariantChosen} manualVariant={manualVariant} setManualVariant={setManualVariant} current_groups={current_groups} setCurrentGroups={setCurrentGroups} ref={preCartRef} />
                                    ) : (
                                        <CardContent>
                                            <Skeleton variant={ "rect" } width={ "100%" } height={ 300 }/>
                                        </CardContent>
                                    )
                                }
                            </Card>
                        )
                    }
                </Grid>
                <Grid item xs={ 12 } md={ 8 }>
                    <PoiCardPreCart productSelected={productSelected} setProductSelected={setProductSelected} assignedGroups={assignedGroups} setAssignedGroups={setAssignedGroups} current_groups={current_groups} manualVariant={manualVariant}/>
                    {
                        activity !== null ? (
                            <AboutPoi activity={activity} manualVariant={manualVariant} variantChosen={variantChosen}/>
                        ) : (
                            <Fragment>
                                <Skeleton variant={ "rect" } width={ "100%" } height={ 500 } />
                            </Fragment>
                        )
                    }
                </Grid>
            </Grid>
            {
                activity !== null && activity.place !== null && (
                    <Grid>
                        <br/>
                        <Divider/>
                        <br/>
                        <PoiMap lat={parseFloat(activity.place.latitude)} lng={parseFloat(activity.place.longitude)} />
                    </Grid>
                )
            }
            {
                activity !== null && mobile && (
                    <PoiCardPricesBottom start_date={start_date} end_date={end_date} activity={activity} current_travelers={currentTravelers} productSelected={productSelected} setProductSelected={setProductSelected} assignedGroups={assignedGroups} setAssignedGroups={setAssignedGroups} addToCart={checkChild} />
                )
            }
            <ActivityCardStandaloneModal
                open={ standalone_modal_open }
                onClose={ toggleStandaloneModal }
                start_date={ start_date }
                end_date={ end_date }
                activity={activity}
                pictures={
                    (() => {
                        if (manualVariant !== "" && !activity?.poi_type) {
                            return (manualVariant?.pictures?.length ?? 0) > 0 ?
                                manualVariant.pictures :
                                [manualVariant?.cover_picture].filter((item) => item);
                        }
                        return (activity?.pictures?.length ?? 0) > 0 ?
                            activity.pictures :
                            [activity?.cover_picture].filter((item) => item);
                    })()
                }
                current_groups={ current_groups }
                destination={ destination }
                standaloneSuccess={ standaloneSuccess }
            />
            <Dialog open={openChildActivity} onClose={handleChildActivity} disableEscapeKeyDown>
                <DialogContent>
                    {t("poi.solo_child_warning")}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleChildActivity}>{t('global.cancel')}</Button>
                    <Button onClick={addToCart}>{t('global.validate')}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default React.memo(PoiCard);
