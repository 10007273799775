import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import GetTrip from "../Base/Functions/GetTrip";

export const ProviderTrip = withRouter(
    function ProviderTrip(props): null {
        const providerId = props.params.providerId;
        const tripId = props.params.tripId;
        const tripVersion = props.params.tripVersion;
        const tripToken = props.params.tripToken;
        const dispatch = useDispatch();

        useEffect(() => {
            if (providerId && tripId && tripVersion && tripToken) {
                GetTrip(
                    null,
                    dispatch,
                    undefined,
                    undefined,
                    {
                        tripId,
                        tripVersion,
                        tripToken
                    }
                );
            }
        }, [
            providerId,
            tripId,
            tripToken,
            tripVersion
        ]);

        return null;
    }
);