import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AccessTime from "@material-ui/icons/AccessTime";
import Restaurant from "@material-ui/icons/Restaurant";
import RoomService from "@material-ui/icons/RoomService";
import FreeBreakfastOutlined from "@material-ui/icons/FreeBreakfastOutlined";
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Assignment from '@material-ui/icons/Assignment';
import grey from "@material-ui/core/colors/grey";
import MoneyOff from '@material-ui/icons/MoneyOff';
import ListAlt from '@mui/icons-material/ListAlt';
import { SupportAgentOutlined } from "@mui/icons-material";
import { SecurityOutlined } from "@material-ui/icons";
import { Button, Checkbox } from '@mui/material';
import LocalBar from '@mui/icons-material/LocalBar';
import { CiCircleCheck, CiMoneyCheck1, CiNoWaitingSign, CiMemoPad } from "react-icons/ci";
import { IoBedOutline } from 'react-icons/io5';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
    inline: {
        display: "inline-block"
    },
    flex: {
        display: "flex"
    },
    variant: {
        backgroundColor: 'aliceblue',
        margin: "8px 16px",
        borderRadius: 8
    },
    variantActive: {
        backgroundColor: grey[100],
        borderLeft: "4px solid #76B6C2"
    },
    paddingBottom: {
        // paddingBottom: theme.spacing(2),
    },
    paddingLeft: {
        paddingLeft: theme.spacing(4)
    },
    negativeMarginLeft: {
        marginLeft: -42
    },
    floatLeft: {
        float: "left"
    },
    bold: {
        fontWeight: "bold"
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    icon: {
        fontSize: 18,
        marginTop: 2
    },
    onRequest: {
        color: '#FF9800'
    },
    marketingFee: {
        fontSize: 12,
        fontStyle: 'italic'
    },
    cancellationInfo: {
        cursor: 'pointer',
        position: 'absolute',
        marginLeft: 8,
        fontSize: 18,
        marginTop: 2
    },
    additionalInfo: {
        cursor: 'pointer',
    },
    red: {
        color: 'red'
    },
    validateButton: {
        marginTop: '8px !important',
        backgroundColor: `${main_color} !important`,
        borderRadius: "20px !important",
        padding: "7px 24px !important",
        color: "white !important",
        "&&.Mui-disabled": {
            backgroundColor: `inherit !important`,
            color: "rgba(0, 0, 0, 0.26) !important"
        }
    }
}));

const AccommodationVariant = ({variant, provider_loaded, selected, onChange, onValidate, room_code}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const language = useSelector(state => state.header.tmp_language);
    const currency = useSelector(state => state.trip.currency);
    const currency_list = useSelector(state => state.base.currency_list);
    const additional_products = useSelector(state => state.accommodation.additional_products);
    const user = useSelector(state => state.user.user);
    const [totalAdditionalProducts, setTotalAdditionalProducts] = useState(0);
    const [selectedAdditionalProducts, setSelectedAdditionalProducts] = useState([]);
    const [expediaRule, setExpediaRule] = useState(false);
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const onAddVariant = () => {
        onValidate({ ...variant, additional_products: selectedAdditionalProducts}, room_code);
    };
    const onCancellationInfo = () => () => {
        let price_currency = currency;
        if (user.client_full.type !== 2 && variant.price_stay && variant.price_stay.length > 0) {
            price_currency = currency_list.find((currency) => {
                return currency.id === variant.price_stay[0].purchase_currency;
            });
        }
        dispatch({
            type: 'MATERIAL_SET_CANCELLATION',
            payload: {
                charges: variant.charges,
                charges_currency: price_currency,
            }
        });
    };
    const onCancellationText = cancellation_text => () => {
        dispatch({type: 'MATERIAL_SET_CANCELLATION_TEXT', payload: cancellation_text});
    };
    const openAdditionalInfoModal = () => {
        dispatch({type: 'MATERIAL_SET_ADDITIONAL_INFO', payload: variant.additional_information.split("Ping")[0]});
    };
    const onTaxesInfo = () => {
        dispatch({type: 'MATERIAL_TOGGLE_TAXES_INFO'});
    };
    const toggleAdditionalProducts = (new_additional_product) => {
        const current_additional_products = [...selectedAdditionalProducts];
        for (let i = 0; i < current_additional_products.length; i++) {
            if (current_additional_products[i].identifier === new_additional_product.identifier) {
                setTotalAdditionalProducts((prevState) => prevState - parseFloat(new_additional_product.total_price[0].selling_price));
                current_additional_products.splice(i, 1);
                setSelectedAdditionalProducts(current_additional_products);
                return;
            }
        }
        setTotalAdditionalProducts((prevState) => prevState + parseFloat(new_additional_product.total_price[0].selling_price));
        setSelectedAdditionalProducts([...selectedAdditionalProducts, new_additional_product]);
    };
    const checkIfCompatible = (product) => {
        let isCompatible = true;
        selectedAdditionalProducts.map(current_product => {
            if (current_product.identifier !== product.identifier) {
                if (current_product.breakfast && product.breakfast) {
                    isCompatible = false;
                } else if (current_product.dinner && product.dinner) {
                    isCompatible = false;
                } else if (current_product.lunch && product.lunch) {
                    isCompatible = false;
                }
            }
        });
        return !isCompatible;
    };
    let price_currency = currency;
    if (variant.showing_currency !== null && price_currency.id !== variant.showing_currency) {
        price_currency = currency_list.find((currency) => {
            return currency.id === variant.showing_currency;
        });
    }
    let resort_fees_currency = price_currency;
    let converted_resort_fees = {
        cost: 0,
        currency: currency
    };
    if (variant.resort_fees !== null && variant.resort_fees > 0 && !variant.resort_fees_included) {
        if (resort_fees_currency.id !== variant.resort_fees_currency_id) {
            resort_fees_currency = currency_list.find((currency) => {
                return currency.id === variant.resort_fees_currency_id;
            });
            let dollar_resort_fees = variant.resort_fees;
            if (resort_fees_currency.iso_code !== "USD") {
                dollar_resort_fees = variant.resort_fees / parseFloat(resort_fees_currency.usd_factor);
            }
            converted_resort_fees.cost = (dollar_resort_fees * parseFloat(currency.usd_factor)).toFixed(0);
        }
    }
    let fees_currency = price_currency;
    if (variant.fees_curreency !== undefined) {
        fees_currency = currency_list.find((currency) => {
            return currency.id === variant.fees_curreency;
        });
    }
    return (
        <Fragment>
            {/*<Divider style={{width: "100%"}}/>*/}
            <Grid item container className={ classes.variant }>
            {/*<Grid item container className={ (selected !== null && variant.identifier === selected.identifier ? classes.variantActive : "") }>*/}
                <Grid item xs>
                    {/*<Grid item xs className={ classes.paddingLeft }>*/}
                    {/*<FormControlLabel className={ classes.negativeMarginLeft } checked={ (selected !== null && variant.identifier === selected.identifier) } onChange={() => { onChange(variant); }} control={<Radio color={ "primary" }/>} label={ <Typography className={ classes.bold }>{ variant.description }</Typography> }/>*/}
                    {
                        variant.additional_information !== null && variant.additional_information !== '' &&
                        <div className={ classes.paddingBottom } onClick={ openAdditionalInfoModal }>
                            <CiMemoPad className={ `${classes.additionalInfo} ${classes.floatLeft} ${classes.marginRight} ${classes.icon}` }/>
                            <Typography className={ classes.inline } style={{fontSize: 14}}>{ t("accommodation.additional_information") }</Typography>
                            <InfoOutlined className={ classes.cancellationInfo }/>
                        </div>
                    }
                    { (user.client_full.type !== 2 || quotation_code === 'verdie') && variant.subprovider_name !== undefined && variant.subprovider_name !== null && variant.subprovider_name !== '' && <Typography className={ classes.inline }><span style={{fontWeight: 'bold'}}>{t('accommodation.sub_provider')}</span> : {variant.subprovider_name}</Typography> }
                    {
                        variant.bed_type !== null && variant.bed_type.length > 0 && (
                            <div className={ classes.paddingBottom }>
                                <IoBedOutline className={ `${classes.floatLeft} ${classes.marginRight}` } style={{marginTop: "4px"}}/>
                                <Typography className={ classes.inline } style={{fontSize: 14}}>
                                    {
                                        variant.bed_type.map((bed_type, index_bed_type) => (
                                            <span key={ `variant-${variant.identifier}-bed-type-${index_bed_type}` }>
                                                { (bed_type === 'simple_bed' || bed_type === 'queen_size' || bed_type === 'king_size' || bed_type === 'twin_bed' || bed_type === 'double_bed' || bed_type === 'sofa_bed' || bed_type === 'baby_bed' || bed_type === 'rollaway_bed' || bed_type === 'bunk_bed' || bed_type === 'futon') ? t(`accommodation.${bed_type}`) : bed_type }
                                                { index_bed_type > 0 && ', ' }
                                            </span>
                                        ))
                                    }
                                </Typography>
                            </div>
                        )
                    }
                    {
                        (quotation_code === 'visiteurs' && user.client_full.type === 2) || quotation_code === "tropicalementvotre" ? (
                            <Fragment>
                                <div className={ classes.paddingBottom }>
                                    <CiCircleCheck className={ `${classes.floatLeft} ${classes.marginRight} ${classes.icon}` } style={variant.is_cancellable ? {color: 'green'} : {}}/>
                                    {
                                        variant.is_cancellable ? (
                                            <Typography className={ classes.inline } style={{fontSize: 14, color: 'green'}}>{ t("accommodation.cancellable-visiteurs") }</Typography>
                                        ) : (
                                            <Typography className={ classes.inline } style={{fontSize: 14}}>{ t('accommodation.not_cancellable') }</Typography>
                                        )
                                    }
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                {
                                    variant.is_cancellable || !!variant.cancel_condition_text ? (
                                        <div className={ classes.paddingBottom }>
                                            <CiCircleCheck className={ `${classes.floatLeft} ${classes.marginRight} ${classes.icon}` } style={{color: 'green'}}/>
                                            <Typography className={ classes.inline } style={{fontSize: 14, color: 'green'}}>{ t("accommodation.cancellable") }</Typography>
                                            <InfoOutlined className={ classes.cancellationInfo } onClick={ !!variant.cancel_condition_text ? onCancellationText(variant.cancel_condition_text) : onCancellationInfo } style={{color: 'green'}}/>
                                        </div>
                                    ) : (
                                        <div className={ classes.paddingBottom }>
                                            <CiNoWaitingSign className={ `${classes.floatLeft} ${classes.marginRight} ${classes.icon}` }/>
                                            <Typography className={ classes.inline } style={{fontSize: 14}}>{ t("accommodation.not_cancellable") }</Typography>
                                        </div>
                                    )
                                }
                            </Fragment>
                        )
                    }
                    {
                        variant.breakfast_included &&
                        <div className={ classes.paddingBottom }>
                            <FreeBreakfastOutlined className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                            <Typography className={ classes.inline } style={{fontSize: 14}}>{ t("accommodation.breakfast_included") }</Typography>
                        </div>
                    }
                    {
                        variant.lunch_included &&
                        <div className={ classes.paddingBottom }>
                            <Restaurant className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                            <Typography className={ classes.inline } style={{fontSize: 14}}>{ t("accommodation.lunch_included") }</Typography>
                        </div>
                    }
                    {
                        variant.dinner_included &&
                        <div className={ classes.paddingBottom }>
                            <RoomService className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                            <Typography className={ classes.inline } style={{fontSize: 14}}>{ t("accommodation.dinner_included") }</Typography>
                        </div>
                    }
                    {
                        variant.all_inclusive &&
                        <div className={ classes.paddingBottom }>
                            <RoomService className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                            <Typography className={ classes.inline } style={{fontSize: 14}}>{ t("accommodation_filters.all_inclusive_meals") }</Typography>
                        </div>
                    }
                    {
                        variant.drink_included &&
                        <div className={ classes.paddingBottom }>
                            <LocalBar className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                            <Typography className={ classes.inline } style={{fontSize: 14}}>{ t("accommodation.drink_included") }</Typography>
                        </div>
                    }
                    {
                        variant.fees_n_taxes !== null &&
                        <div className={ classes.paddingBottom }>
                            <CiMoneyCheck1 className={ `${classes.floatLeft} ${classes.marginRight} ${classes.icon}` }/>
                            <Typography className={ classes.inline } style={{fontSize: 14}}>
                                Taxes and Fees : { variant.fees_n_taxes } { fees_currency.symbol }
                            </Typography>
                            <InfoOutlined className={ classes.cancellationInfo } onClick={ onTaxesInfo }/>
                        </div>
                    }
                    {
                        variant.sales_tax !== null &&
                        <div className={ classes.paddingBottom }>
                            <CiMoneyCheck1 className={ `${classes.floatLeft} ${classes.marginRight} ${classes.icon}` }/>
                            <Typography className={ classes.flex } style={{fontSize: 14}}>
                                Sales Tax : { variant.sales_tax } { fees_currency.symbol }
                            </Typography>
                        </div>
                    }
                    {
                        variant.resort_fees !== null && variant.resort_fees > 0 && !variant.resort_fees_included &&
                        <div className={ classes.paddingBottom }>
                            <CiMoneyCheck1 className={ `${classes.floatLeft} ${classes.marginRight} ${classes.icon}` }/>
                            <Typography className={ classes.flex } style={{fontSize: 14}}>
                                { variant.resort_fees_mandatory ? t("cart.resort_fees_text_1") : t("cart.resort_fees_text_1_v2") } { variant.resort_fees } { resort_fees_currency.symbol } { converted_resort_fees.cost !== 0 && `(${ t('cart.resort_fees_text_2') } ${ converted_resort_fees.cost } ${converted_resort_fees.currency.symbol})` } { variant.resort_fees_mandatory ? t('cart.resort_fees_text_3') : t('cart.resort_fees_text_4') }
                            </Typography>
                        </div>
                    }
                    {
                        variant.resort_fees !== null && variant.resort_fees_included &&
                        <div className={ classes.paddingBottom }>
                            <CiMoneyCheck1 className={ `${classes.floatLeft} ${classes.marginRight} ${classes.icon}` }/>
                            <Typography className={ classes.flex } style={{fontSize: 14}}>
                                { variant.resort_fees > 0 ? `${t("cart.resort_fees_text_included_1")} ${ variant.resort_fees } ${ resort_fees_currency.symbol } ${t("cart.resort_fees_text_included_2")}` : t("cart.resort_fees_text_included_1_v1") }
                            </Typography>
                        </div>
                    }
                    {
                        !!variant.resort_fees_text &&
                        <div className={ classes.paddingBottom }>
                            <CiMoneyCheck1 className={ `${classes.floatLeft} ${classes.marginRight} ${classes.icon}` }/>
                            <Typography className={ classes.flex } style={{fontSize: 14}}>
                                { variant.resort_fees_text }
                            </Typography>
                        </div>
                    }
                    {
                        user.client_full.type !== 2 && variant.static_contract &&
                        <div className={ classes.paddingBottom }>
                            <ListAlt className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                            <Typography className={ classes.flex } style={{fontSize: 14}}>{ t('accommodation.direct-contract') }</Typography>
                        </div>
                    }
                    {
                        variant.transfer_included &&
                        <div className={ classes.paddingBottom }>
                            <TransferWithinAStation className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                            <Typography className={ classes.inline } style={{fontSize: 14}}>{ t("accommodation.transfer_included") }</Typography>
                        </div>
                    }
                    {
                        variant.assistance_included &&
                        <div className={ classes.paddingBottom }>
                            <SupportAgentOutlined className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                            <Typography className={ classes.inline }>{ t("accommodation.assistance_included") }</Typography>
                        </div>
                    }
                    {
                        variant.insurance_included &&
                        <div className={ classes.paddingBottom }>
                            <SecurityOutlined className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                            <Typography className={ classes.inline }>{ t("accommodation.insurance_included") }</Typography>
                        </div>
                    }
                    {
                        variant.remaining_stock !== null &&
                        <div className={ classes.paddingBottom }>
                            <Typography className={ `${classes.inline} ${classes.red}` } style={{fontSize: 14}}>{ variant.remaining_stock } { t("accommodation.remaining_stock") }</Typography>
                        </div>
                    }
                    {
                        variant.elements.map((element, index_element) => {
                            if (element.description !== null) {
                                return <div key={ index_element } className={ classes.paddingBottom }>{ element.type_elem === "D" && <MoneyOff className={ `${classes.floatLeft} ${classes.marginRight}` }/> }<Typography style={{fontSize: 14, whiteSpace: "pre-line", display: "inline"}}>{ element.type_elem === "D" ? `${element.name !== null ? element.name + " : " : ''}` : '' }{ element.description }</Typography></div>;
                            }
                        })
                    }
                    {
                        additional_products && additional_products.length > 0 &&
                        <Fragment>
                            <Typography style={{marginTop: 4, fontWeight: "bold"}}>{ t("accommodation.additional-products") } :</Typography>
                            {
                                additional_products.map(product => {
                                    const isDisabled = checkIfCompatible(product);
                                    return (
                                        <Grid container>
                                            <Grid item>
                                                <FormControlLabel
                                                    style={{marginBottom: 0, position: "relative"}}
                                                    checked={ !!selectedAdditionalProducts.find(current_product => current_product.identifier === product.identifier) }
                                                    onChange={() => {
                                                        if (!isDisabled) {
                                                            toggleAdditionalProducts(product);
                                                        }
                                                    }}
                                                    control={<Checkbox color={ "primary" } size={ "small" } style={{padding: "0px 5px 0px 9px", position: "absolute", top: 0}}/>}
                                                    disabled={ isDisabled }
                                                    label={
                                                        <Typography style={{marginBottom: 0, fontSize: 14, marginLeft: 35}}>
                                                            { product.name }
                                                            {
                                                                product.total_price && product.total_price.length > 0 &&
                                                                ` + ${new Intl.NumberFormat(language, { style: "currency", currency: currency.iso_code }).format(parseFloat(product.total_price[0].selling_price))} `
                                                            }
                                                            ({ t('accommodation.additional-product-info') })
                                                        </Typography>
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    );
                                })
                            }
                        </Fragment>
                    }
                </Grid>
                <Grid item>
                    <Typography style={{fontWeight: 'bold'}}>{ new Intl.NumberFormat(language, { style: "currency", currency: price_currency.iso_code }).format(parseFloat(variant.showing_price) + totalAdditionalProducts) }</Typography>
                    {
                        provider_loaded === null && variant.provider && (variant.provider.logo ? <img src={ variant.provider.logo.url } width={ 60 }/> : <Typography>{ variant.provider.name }</Typography>)
                    }
                    { ((provider_loaded && provider_loaded.code === 'expedia_rapid') || (variant.provider && variant.provider.code === 'expedia_rapid')) && <Fragment>{ variant.is_packaged ? <Typography className={ classes.onRequest }>{ t('accommodation.is_packaged') }</Typography> : <Typography className={ classes.onRequest }>{ t('accommodation.is_standalone') }</Typography> }</Fragment> }
                    {
                        variant.marketing_fee !== null && (
                            <Fragment>
                                <Typography className={ classes.marketingFee }>{ t('accommodation.price_with_taxes') }</Typography>
                                <Typography className={ classes.marketingFee }>{ new Intl.NumberFormat(language, { style: "currency", currency: price_currency.iso_code }).format((parseFloat(variant.showing_price) + parseFloat(variant.marketing_fee))) }</Typography>
                                <Typography className={ classes.marketingFee }>{ t('accommodation.marketing_fee') }</Typography>
                                <Typography className={ classes.marketingFee }>{ new Intl.NumberFormat(language, { style: "currency", currency: price_currency.iso_code }).format(parseFloat(variant.marketing_fee)) }</Typography>
                            </Fragment>
                        )
                    }
                    { variant.on_request && <Typography className={ classes.onRequest }>{ t('accommodation.on_request') }</Typography> }
                </Grid>
                <Grid item container justify={ (((provider_loaded && provider_loaded.code === 'expedia_rapid') || (variant.provider && variant.provider.code === 'expedia_rapid')) && variant.is_packaged) ? 'space-between' : 'flex-end' } alignItems={ 'center' } xs={ 12 }>
                    {
                        ((provider_loaded && provider_loaded.code === 'expedia_rapid') || (variant.provider && variant.provider.code === 'expedia_rapid')) && variant.is_packaged &&
                        <Grid item>
                            <FormControlLabel
                                style={{marginBottom: 0, position: "relative"}}
                                checked={ expediaRule }
                                onChange={() => {
                                    setExpediaRule((prevState) => !prevState);
                                }}
                                control={<Checkbox color={ "primary" } size={ "small" } style={{padding: "0px 5px 0px 9px", position: "absolute", top: 0}}/>}
                                label={
                                    <Typography style={{marginBottom: 0, fontSize: 14, marginLeft: 35}}>
                                        { t('accommodation.expedia-rule') }
                                    </Typography>
                                }
                            />
                        </Grid>
                    }
                    <Grid item>
                        <Button className={ classes.validateButton } onClick={ onAddVariant }
                                disabled={
                                    ((provider_loaded && provider_loaded.code === 'expedia_rapid') || (variant.provider && variant.provider.code === 'expedia_rapid')) && variant.is_packaged && !expediaRule
                                }
                        >{ t('accommodation.validate_room') }</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
};

export default React.memo(AccommodationVariant);
