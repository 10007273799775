import React, { useContext, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Chip,
    Collapse,
    IconButton,
    Stack,
    TableCell,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import {
    ArrowRightAlt,
    BuildCircle,
    CloudCircle,
    CloudOff,
    DriveEta,
    ErrorOutline,
    ImportExport,
    MoreVert,
    ReportProblem
} from "@mui/icons-material";
import { green } from "@mui/material/colors";
import { makeStyles } from "@material-ui/core";
import LightTooltip from "../Menu/MaterialTripList/utils/tooltip/lightTooltip";
import { CartConstructionProductsTableItemStatus } from "./CartConstructionProductsTableItemStatus";
import { CartConstructionProductsTableItem } from "./CartConstructionProductsTableItem";
import { CartConstructionProductsTableItemMenu } from "./CartConstructionProductsTableItemMenu";
import { CartConstructionProductsTableItemMargin } from "./CartConstructionProductsTableItemMargin";
import {
    CartConstructionProductsTableItemProviderQuotationStatus
} from "./CartConstructionProductsTableItemProviderQuotationStatus";
import {
    CartPackageItemChangeProviderQuotationStatusButtons
} from "./CartPackageItemChangeProviderQuotationStatusButtons";
import { CartProductCardProviderLogo } from "./CartProductCardProviderLogo";
import { findProductItineraryError } from "./utils/findProductItineraryError";
import { useGetPrice } from "./utils/getPrice";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { useProductDays } from "./utils/productDays";
import { useManualProductAlerts } from "./utils/manualProductAlerts";
import { useTripDays } from "./utils/tripDays";
import { useCartProductUpdate } from "./network/cartProductUpdate";
import { useShowError } from "../Utils/showError";
import GetCookie from "../Common/Functions/GetCookie";
import GetPricesId from "../Cart/Functions/Margin/GetPricesId";
import { Currency } from "../../Reducers/objects/currency";
import { AppState } from "../../Reducers/Reducers";
import { isProductPackaged } from "./utils/isProductPackaged";
import { ProviderContext } from "./utils/providerContext";

type Props = {
    index: number,
    item: ReturnType<typeof useCartProducts>['cars'][number]
}

export function CartConstructionProductsTableCarItem(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const currency = useSelector((state: AppState) => state.trip.currency);
    const currency_list = useSelector((state: AppState) => state.base.currency_list);
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const user = useSelector((state: AppState) => state.user.user);
    const see_new_price = useSelector((state: AppState) => state.cart.see_new_price);
    const [openMenu, setOpenMenu] = useState(false);
    const [hasSubproviderError, setHasSubproviderError] = useState(false);
    const provider = useMemo(() => {
        return manual_providers.find((item) => {
            return item.provider.id === props.item.car.provider;
        }) ?? providers.find((item) => {
            return item.provider.id === props.item.car.provider;
        });
    }, [providers, manual_providers, props.item]);
    const subprovider = useMemo(() => {
        return manual_providers.find((item) => {
            return props.item.type === 'normal' &&
                item.provider.id === props.item.car.subprovider_id;
        }) ?? providers.find((item) => {
            return props.item.type === 'normal' &&
                item.provider.id === props.item.car.subprovider_id;
        });
    }, [providers, manual_providers, props.item]);
    const priceCurrencyConverter = (first_price: any, first_currency: Currency | undefined, second_currency: Currency | undefined) => {
        if (first_currency?.iso_code === second_currency?.iso_code) {
            return first_price;
        }
        return (
            first_currency?.iso_code !== 'USD' ?
                first_price / (first_currency?.usd_factor ?? 1) :
                first_price) * (second_currency?.usd_factor ?? 0
            );
    };
    const oneWay = useMemo(() => {
        if (props.item.type === 'normal') {
            const item = props.item.car.onsite_fare.find((onsite_fare) => {
                return onsite_fare.description === "One way fares" && onsite_fare.price !== 0;
            });
            if (item) {
                const currency_one_way = currency_list.find((el) => {
                    return el.id === item.currency;
                });
                let one_way = {
                    cost: item.price.toString() ?? '0',
                    cost_currency: currency_one_way,
                    converted_cost: currency_one_way?.iso_code !== currency.iso_code ? priceCurrencyConverter(item.price, currency_one_way, currency) : item.price,
                    converted_currency: currency
                };
                one_way.cost = one_way.cost.toLocaleString();
                one_way.converted_cost = one_way.converted_cost.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                return one_way;
            }
        }
        return null;
    }, []);
    const { found, iti_error } = useMemo(() => {
        if (props.item.type === 'normal') {
            return findProductItineraryError(
                'car',
                {
                    product: props.item.car,
                    itinerary,
                    stackInfos: trip?.stack_info ?? null
                }
            );
        }
        return { found: { start: true, end: true }, iti_error: null };
    }, [
        itinerary,
        props.item,
        trip
    ]);
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const showError = useShowError();
    const update = useCartProductUpdate({
        onError(error) {
            console.error(error);
            showError(error);
        }
    });
    const tripDays = useTripDays();
    const getDays = useProductDays();
    const getPrice = useGetPrice();
    const manualProductAlerts = useManualProductAlerts(
        props.item.type === 'manual' ?
            props.item.car :
            null
    );
    const startDate = window.moment.utc(props.item.car.start_date);
    const endDate = window.moment.utc(props.item.car.end_date);
    const price = getPrice(props.item.car.prices);
    const days = getDays(props.item.car.start_date, props.item.car.end_date);
    const providerContext = useContext(ProviderContext);
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const handleSeeNewPrice = () => {
        dispatch({
            type: 'CART_SEE_NEW_PRICE_MODAL',
            payload: {
                see_new_price: !see_new_price,
                data: see_new_price ? null : {
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${props.item.car.id}/`,
                    id: props.item.car.id,
                    old_price: props.item.car.prices[GetPricesId(props.item.car.prices, user?.client, user, true)],
                    new_price: props.item.type === 'normal' ? props.item.car.provider_wanted_price : null,
                    product: props.item.car,
                    custom_dispatch: 'CAR_EDIT_CART_BY_ID'
                }
            }
        });
    };

    const onDeleteTravelerModificationAlert = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        const version = parseInt(GetCookie('trip_id_version') ?? '-1');
        update(
            version,
            {
                id: props.item.car.id,
                type: props.item.type === 'normal' ?
                    'car' :
                    'manual',
                isCustom: props.item.car.is_custom
            },
            { traveler_modification: null }
        );
    };

    const alerts = [
        oneWay &&
        <Alert className={classes.resortFees} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'warning'}>
            {
                [
                    `${t('cars.one_way')} : ${t('cart-material.resort-fees-1')}`,
                    oneWay.cost,
                    oneWay.cost_currency ? oneWay.cost_currency?.symbol : '',
                    oneWay.cost !== oneWay.converted_cost ?
                        `(${t('cart-material.resort-fees-2')} ${oneWay.converted_cost}${oneWay.converted_currency ? oneWay.converted_currency.symbol : ''})` :
                        '',
                    t('cart-material.one-way-fees-3')
                ].join(' ')
            }
        </Alert>,
        props.item.car.booking_status &&
        (props.item.car.booking_status.status_booking as any) === 'UNAVAILABLE' &&
        <Alert className={classes.errorAlert} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'error'}>
            {t('cart-material.booking-unavailable')} : {props.item.car.booking_status.item_reference}
        </Alert>,
        props.item.car.booking_status &&
        (props.item.car.booking_status.status_booking === 'ERROR' || (props.item.car.booking_status.status_booking as any) === 'UNAVAILABLE') &&
        props.item.car.booking_errors &&
        props.item.car.booking_errors.length > 0 &&
        <Alert className={classes.errorAlert} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'error'}>
            <AlertTitle>{t('cart-material.error')} :</AlertTitle>
            {props.item.car.booking_errors.map(error => (
                <Typography key={`booking-error-${error.created_date}`}>
                    {window.moment.utc(error.created_date).format('DD/MM/YYYY')} : {error.detail}
                </Typography>
            ))}
        </Alert>,
        iti_error?.start &&
        <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
            {t('cart-material.itinerary-error-1')}{' '}
            {
                iti_error.start.destination?.data?.localization.find((item) => {
                    return item.locale === locale;
                })?.name ??
                iti_error.start.destination?.data?.name ??
                iti_error.start.destination?.data?.international_name
            } {t('global.from')}{' '}
            {window.moment.utc(iti_error.start.start_date).format('DD/MM/YYYY HH:mm')}{' '}
            {t('global.to')}{' '}
            {window.moment.utc(iti_error.start.end_date).format('DD/MM/YYYY HH:mm')}.{' '}
            {t('cart-material.itinerary-error-2')}.
        </Alert>,
        iti_error?.end &&
        <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
            {t('cart-material.itinerary-error-1')}{' '}
            {
                iti_error.end.destination?.data?.localization.find((item) => {
                    return item.locale === locale;
                })?.name ??
                iti_error.end.destination?.data?.name ??
                iti_error.end.destination?.data?.international_name
            } {t('global.from')}{' '}
            {window.moment.utc(iti_error.end.start_date).format('DD/MM/YYYY HH:mm')}{' '}
            {t('global.to')}{' '}
            {window.moment.utc(iti_error.end.end_date).format('DD/MM/YYYY HH:mm')}.{' '}
            {t('cart-material.itinerary-error-2')}.
        </Alert>,
        !found.start && !found.end &&
        <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
            {t('cart-material.no-destination-found')}
        </Alert>,
        !found.start && found.end &&
        <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
            {t('cart-material.no-start-destination-found')}
        </Alert>,
        found.start && !found.end &&
        <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
            {t('cart-material.no-end-destination-found')}
        </Alert>,
        user?.client_full?.type !== 2 && props.item.type === 'normal' && props.item.car.provider_wanted_price &&
        <Alert variant={'filled'} severity={'error'} action={
            <Button color={"inherit"} size={'small'} onClick={handleSeeNewPrice}>{t('global.see')}</Button>
        }>
            <AlertTitle>{t('cart-material.provider-wanted-price')}</AlertTitle>
        </Alert>
    ].filter((item): item is Exclude<typeof item, false> => !!item).concat(manualProductAlerts);

    return (
        <>
            <CartConstructionProductsTableItem
                {
                ...(
                    props.item.type === 'normal' ?
                        { type: 'car', index: props.index, item: props.item.car } :
                        { type: 'manual', item: props.item.car }
                )
                }
            >
                <LightTooltip
                    title={alerts}
                    onClick={(event) => event.stopPropagation()}
                    placement="top"
                    arrow
                >
                    <TableCell sx={{ verticalAlign: 'middle', paddingRight: 0 }}>
                        {
                            alerts.length > 0 &&
                            <ReportProblem color="warning" />
                        }
                    </TableCell>
                </LightTooltip>
                <TableCell color="inherit">
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        flexWrap="nowrap"
                    >
                        <LightTooltip title={t('cart-material.car')} placement="top" arrow>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                flexWrap="nowrap"
                            >
                                <DriveEta />
                                <Typography
                                    variant="body2"
                                    fontWeight="bold"
                                    sx={{
                                        "display": '-webkit-box',
                                        '-webkit-line-clamp': '1',
                                        '-webkit-box-orient': 'vertical',
                                        "overflow": 'hidden',
                                        "textOverflow": "ellipsis"
                                    }}
                                >
                                    {t('cart-material.car')}
                                </Typography>
                            </Stack>
                        </LightTooltip>
                        {
                            !isProductPackaged({
                                product: props.item.car,
                                stackInfos: trip?.stack_info ?? null,
                                connected: true
                            }) &&
                            (
                                user?.client_full?.type !== 2 ||
                                props.item.car.creator?.client?.type === user.client_full.type
                            ) &&
                            <>
                                {
                                    props.item.type === 'normal' &&
                                    !props.item.car.is_custom &&
                                    <Chip
                                        label={t('cart-material.cart-construction-flux')}
                                        size="small"
                                        sx={{
                                            backgroundColor: '#2ACAEA',
                                            color: '#fff'
                                        }}
                                        icon={<CloudCircle color="inherit" />}
                                    />
                                }
                                {
                                    props.item.type === 'normal' &&
                                    props.item.car.is_custom &&
                                    <Chip
                                        size="small"
                                        label={t('cart-material.cart-construction-offline')}
                                        sx={{
                                            backgroundColor: '#6A329F',
                                            color: '#fff'
                                        }}
                                        icon={<CloudOff color="inherit" />}
                                    />
                                }
                                {
                                    props.item.type === 'manual' &&
                                    <Chip
                                        label={t('accommodation.manual')}
                                        size="small"
                                        sx={{
                                            backgroundColor: '#6A329F',
                                            color: '#fff'
                                        }}
                                        icon={<BuildCircle color="inherit" />}
                                    />
                                }
                            </>
                        }
                    </Stack>
                    <Typography
                        variant="caption"
                        component="div"
                        sx={{
                            position: 'absolute',
                            marginTop: 1,
                            opacity: 0.7
                        }}
                    >
                        <LightTooltip
                            title={
                                (!days[0] || !tripDays.includes(days[0])) ?
                                    t(
                                        'cart-material.cart-construction-incorrect-product-days',
                                        { day: tripDays.join(', ') }
                                    ) :
                                    ''
                            }
                            arrow
                        >
                            <Box
                                sx={
                                    (!days[0] || !tripDays.includes(days[0])) ?
                                        {
                                            backgroundColor: '#ff0000',
                                            color: '#fff'
                                        } :
                                        undefined
                                }
                                component="span"
                            >
                                {
                                    days[0] !== days[days.length - 1] ?
                                        t(
                                            'cart-material.cart-construction-day-from-to',
                                            { from: days[0], to: days[days.length - 1] }
                                        ) :
                                        t(
                                            'cart-material.cart-construction-day',
                                            { day: days[0] }
                                        )
                                }
                            </Box>
                        </LightTooltip>
                        {' '}-{' '}
                        {
                            (startDate.isValid() || endDate.isValid()) &&
                            t(
                                'cart-material.cart-construction-products-table-date',
                                {
                                    from: startDate.isValid() ? startDate.format('L HH:mm') : null,
                                    to: endDate.isValid() ? endDate.format('L HH:mm') : null
                                }
                            )
                        }
                        {
                            !providerContext.module &&
                            <Stack
                                display="inline-flex"
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <CartConstructionProductsTableItemProviderQuotationStatus
                                    item={props.item.car}
                                />
                                <CartPackageItemChangeProviderQuotationStatusButtons
                                    item={{
                                        ...props.item.car,
                                        type: props.item.type === 'manual' ?
                                            'manual' :
                                            'cars',
                                        isCustom: props.item.car.is_custom
                                    }}
                                />
                            </Stack>
                        }
                        {
                            props.item.car.traveler_modification &&
                            <Chip
                                color="warning"
                                label={
                                    props.item.car.traveler_modification === 'SET_IN_TRIP' ?
                                        t('cart-material.add-to-cart-traveler') :
                                        t('cart-material.add-to-option-traveler')
                                }
                                size="small"
                                icon={<ErrorOutline color="inherit" />}
                                onDelete={onDeleteTravelerModificationAlert}
                            />
                        }
                    </Typography>
                </TableCell>
                <LightTooltip
                    title={
                        props.item.car.station_name_pickup !== props.item.car.station_name_return ?
                            <Stack direction="row">
                                <Typography
                                    variant="body2"
                                    sx={{ whiteSpace: 'nowrap' }}
                                >
                                    {props.item.car.station_name_pickup}
                                </Typography>
                                <ArrowRightAlt fontSize="small" sx={{ marginLeft: 1, marginRight: 1 }} />
                                <Typography
                                    variant="body2"
                                    sx={{ whiteSpace: 'nowrap' }}
                                >
                                    {props.item.car.station_name_return}
                                </Typography>
                            </Stack> :
                            <Stack direction="row">
                                <Typography
                                    variant="body2"
                                    sx={{ whiteSpace: 'nowrap' }}
                                >
                                    {props.item.car.station_name_pickup}
                                </Typography>
                                <ImportExport
                                    fontSize="small"
                                    sx={{
                                        marginLeft: 1,
                                        marginRight: 1,
                                        transform: 'rotate(90deg)'
                                    }}
                                />
                            </Stack>
                    }
                    componentsProps={{
                        tooltip: {
                            sx: {
                                maxWidth: 'none'
                            }
                        }
                    }}
                    placement="top"
                    arrow
                >
                    <TableCell>
                        <div className="ellipsis">
                            {
                                props.item.car.station_name_pickup !== props.item.car.station_name_return &&
                                <Stack direction="row">
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            "display": '-webkit-box',
                                            '-webkit-line-clamp': '1',
                                            '-webkit-box-orient': 'vertical',
                                            "overflow": 'hidden',
                                            "textOverflow": "ellipsis"
                                        }}
                                    >
                                        {props.item.car.station_name_pickup}
                                    </Typography>
                                    <ArrowRightAlt fontSize="small" sx={{ marginLeft: 1, marginRight: 1 }} />
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            "display": '-webkit-box',
                                            '-webkit-line-clamp': '1',
                                            '-webkit-box-orient': 'vertical',
                                            "overflow": 'hidden',
                                            "textOverflow": "ellipsis"
                                        }}
                                    >
                                        {props.item.car.station_name_return}
                                    </Typography>
                                </Stack>
                            }
                            {
                                props.item.car.station_name_pickup === props.item.car.station_name_return &&
                                <Stack direction="row">
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            "display": '-webkit-box',
                                            '-webkit-line-clamp': '1',
                                            '-webkit-box-orient': 'vertical',
                                            "overflow": 'hidden',
                                            "textOverflow": "ellipsis"
                                        }}
                                    >
                                        {props.item.car.station_name_pickup}
                                    </Typography>
                                    <ImportExport
                                        fontSize="small"
                                        sx={{
                                            marginLeft: 1,
                                            marginRight: 1,
                                            transform: 'rotate(90deg)'
                                        }}
                                    />
                                </Stack>
                            }
                        </div>
                    </TableCell>
                </LightTooltip>
                <LightTooltip
                    title={
                        props.item.type === 'normal' ?
                            [
                                props.item.car.localization.find((item) => {
                                    return item.locale === locale;
                                })?.name ?? props.item.car.name,
                                quotation_code === 'marcovasco' &&
                                `(${props.item.car.vehicle_code})`,
                                props.item.car.variant &&
                                ` (${props.item.car.variant.localization?.find((item) => item.locale === locale)?.title ?? props.item.car.variant.name})`
                            ].filter((item) => item).join(' ') :
                            props.item.car.localization?.find((item) => {
                                return item.locale === locale;
                            })?.name ??
                            props.item.car.name
                    }
                    placement="top"
                    arrow
                >
                    <TableCell>
                        <div className="ellipsis">
                            {
                                props.item.type === 'normal' ?
                                    [
                                        props.item.car.localization.find((item) => {
                                            return item.locale === locale;
                                        })?.name ?? props.item.car.name,
                                        quotation_code === 'marcovasco' &&
                                        `(${props.item.car.vehicle_code})`,
                                        props.item.car.variant &&
                                        ` (${props.item.car.variant.localization?.find((item) => item.locale === locale)?.title ?? props.item.car.variant.name})`
                                    ].filter((item) => item).join(' ') :
                                    props.item.car.localization?.find((item) => {
                                        return item.locale === locale;
                                    })?.name ??
                                    props.item.car.name
                            }
                        </div>
                    </TableCell>
                </LightTooltip>
                <TableCell>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="center"
                    >
                        {
                            !isProductPackaged({
                                product: props.item.car,
                                stackInfos: trip?.stack_info ?? null
                            }) &&
                            (
                                props.item.type === 'manual' ||
                                props.item.car.provider !== props.item.car.subprovider_id
                            ) &&
                            <CartProductCardProviderLogo
                                logoUrl={
                                    provider?.provider.logo?.url ??
                                    provider?.provider.logo?.thumbnail_big ??
                                    provider?.provider.logo?.thumbnail_medium ??
                                    provider?.provider.logo?.thumbnail_little ??
                                    null
                                }
                                name={provider?.provider.name ?? null}
                                width={48}
                                height={32}
                                enableClientLogoSwitchMode
                            />
                        }
                        {
                            subprovider &&
                            !isProductPackaged({
                                product: props.item.car,
                                stackInfos: trip?.stack_info ?? null
                            }) &&
                            <>
                                {
                                    !hasSubproviderError &&
                                    <Box
                                        src={
                                            subprovider.provider.logo?.url ??
                                            subprovider.provider.logo?.thumbnail_big ??
                                            subprovider.provider.logo?.thumbnail_medium ??
                                            subprovider.provider.logo?.thumbnail_little ??
                                            ''
                                        }
                                        component="img"
                                        sx={{
                                            width: 48,
                                            height: 32,
                                            objectFit: 'contain'
                                        }}
                                        onError={() => {
                                            setHasSubproviderError(true);
                                        }}
                                    />
                                }
                                {
                                    hasSubproviderError &&
                                    <Tooltip title={subprovider.provider.name}>
                                        <Typography
                                            sx={{
                                                height: 32,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>
                                            {subprovider.provider.name}
                                        </Typography>
                                    </Tooltip>
                                }
                            </>
                        }
                        {
                            props.item.type === 'normal' &&
                            !subprovider &&
                            !isProductPackaged({
                                product: props.item.car,
                                stackInfos: trip?.stack_info ?? null
                            }) &&
                            <Tooltip title={props.item.car.subprovider_name}>
                                <Typography
                                    sx={{
                                        height: 32,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                    {props.item.car.subprovider_name}
                                </Typography>
                            </Tooltip>
                        }
                    </Stack>
                </TableCell>
                <LightTooltip
                    title={
                        props.item.car.booking_status?.item_reference ?
                            t(
                                'cart-material.cart-construction-reference',
                                { ref: props.item.car.booking_status.item_reference }
                            ) :
                            ''
                    }
                    placement="top"
                    arrow
                >
                    <TableCell>
                        <div className="ellipsis">
                            {
                                props.item.car.booking_status?.item_reference &&
                                t(
                                    'cart-material.cart-construction-reference',
                                    { ref: props.item.car.booking_status.item_reference }
                                )
                            }
                        </div>
                    </TableCell>
                </LightTooltip>
                <TableCell sx={{ maxWidth: 'none !important', textAlign: 'center' }}>
                    {
                        !isProductPackaged({
                            product: props.item.car,
                            stackInfos: trip?.stack_info ?? null,
                            connected: true
                        }) &&
                        <>
                            {
                                props.item.type === 'normal' ?
                                    <CartConstructionProductsTableItemStatus
                                        type="car"
                                        item={props.item.car}
                                        bookingProcessState={props.item.car.booking_process_state}
                                        bookingStatus={props.item.car.booking_status}
                                        agencyNeedToBook={props.item.car.agency_need_to_book}
                                    /> :
                                    <CartConstructionProductsTableItemStatus
                                        type="manual"
                                        item={props.item.car}
                                        bookingProcessState={props.item.car.booking_process_state}
                                        bookingStatus={props.item.car.booking_status}
                                        agencyNeedToBook={props.item.car.agency_need_to_book}
                                    />
                            }
                        </>
                    }
                </TableCell>
                <TableCell>
                    <div className="ellipsis">
                        {
                            !isProductPackaged({
                                product: props.item.car,
                                stackInfos: trip?.stack_info ?? null
                            }) &&
                            new Intl.NumberFormat(
                                language,
                                {
                                    style: 'currency',
                                    currency: price.currency?.iso_code ?? 'EUR'
                                }
                            ).format(price.cost)
                        }
                    </div>
                </TableCell>
                <TableCell>
                    <IconButton
                        ref={menuButtonRef}
                        size="small"
                        onClick={(event) => {
                            event.stopPropagation();
                            setOpenMenu(true);
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                    {
                        props.item.type === 'normal' &&
                        <CartConstructionProductsTableItemMenu
                            type="car"
                            index={props.index}
                            open={openMenu}
                            anchorEl={menuButtonRef.current}
                            value={props.item.car}
                            onClose={() => setOpenMenu(false)}
                        />
                    }
                    {
                        props.item.type === 'manual' &&
                        <CartConstructionProductsTableItemMenu
                            type="manual"
                            open={openMenu}
                            anchorEl={menuButtonRef.current}
                            value={props.item.car}
                            onClose={() => setOpenMenu(false)}
                        />
                    }
                </TableCell>
            </CartConstructionProductsTableItem>
            <TableRow className="cart-construction-table-item-margin-row">
                <MarginWrapper item={props.item} />
            </TableRow>
        </>
    );
}

type MarginWrapperProps = {
    item: ReturnType<typeof useCartProducts>['cars'][number]
}

function MarginWrapper(props: MarginWrapperProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <TableCell
            sx={
                seeAllProductsMargins ?
                    undefined :
                    { paddingTop: 0, paddingBottom: 0 }
            }
            colSpan={20}
        >
            <Collapse
                in={
                    seeAllProductsMargins &&
                    !isProductPackaged({
                        product: props.item.car,
                        stackInfos: trip?.stack_info ?? null
                    })
                }
                unmountOnExit
            >
                {
                    props.item.type === 'normal' &&
                    <CartConstructionProductsTableItemMargin
                        type="car"
                        item={props.item.car}
                    />
                }
                {
                    props.item.type === 'manual' &&
                    <CartConstructionProductsTableItemMargin
                        type="manual"
                        item={props.item.car}
                    />
                }
            </Collapse>
        </TableCell>
    );
}


const useStyles = makeStyles(() => ({
    gridImage: {
        width: 250,
        position: 'relative'
    },
    gridContent: {
        padding: '0 15px'
    },
    gridPrice: {
        width: 100
    },
    image: {
        borderRadius: 16
    },
    subProviderLogo: {
        position: 'absolute',
        left: 0
    },
    selector: {
        margin: '16px 0 8px 0'
    },
    variant: {
        margin: '16px 0 8px 0'
    },
    updateQuotationStatus: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    addNote: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    manualCancellationRules: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    containerMargin: {
        marginBottom: 4
    },
    carName: {
        fontSize: 20,
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    hotelInPropertyCollapsed: {
        float: 'left',
        cursor: 'pointer',
        marginRight: 4
    },
    hotelInPropertyIconCollapsed: {
        verticalAlign: 'text-top'
    },
    pin: {
        float: 'left',
        marginRight: 4
    },
    calendar: {
        float: 'left',
        marginRight: 4
    },
    service: {
        float: 'left',
        marginRight: 4
    },
    contractPack: {
        color: '#E6592F',
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    contractLoading: {
        color: '#E6592F',
        marginLeft: 16
    },
    bookingContainer: {
        paddingLeft: 15
    },
    manualBookingHand: {
        float: 'left',
        marginRight: 8
    },
    price: {
        textAlign: 'right',
        fontSize: 32,
        fontWeight: 'bold'
    },
    unavailable: {
        color: 'red'
    },
    red: {
        textAlign: 'right',
        color: 'red'
    },
    green: {
        textAlign: 'right',
        color: 'green'
    },
    priceDetail: {
        textAlign: 'right',
        textDecoration: 'underline'
    },
    traverlerModification: {
        borderRadius: 16,
        padding: '1px 6px'
    },
    booking: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '4px 0',
        textTransform: 'uppercase'
    },
    bookingCollapsed: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '8px 0 0 0',
        textTransform: 'uppercase'
    },
    bookingDetail: {
        textDecoration: 'underline',
        fontSize: 12,
        textAlign: 'right',
        marginBottom: 4,
        cursor: 'pointer'
    },
    needToBook: {
        color: '#4caf50',
        textAlign: 'right'
    },
    booked: {
        border: '2px solid #4caf50',
        color: '#4caf50'
    },
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800'
    },
    cached: {
        float: 'left',
        marginRight: 8,
        cursor: 'pointer'
    },
    cancelled: {
        border: '2px solid black',
        color: 'black'
    },
    error: {
        border: '2px solid #f44336',
        color: '#f44336'
    },
    deleteContainer: {
        textAlign: 'right'
    },
    delete: {
        cursor: 'pointer'
    },
    prices: {
        textAlign: 'right'
    },
    errorAlert: {
        marginTop: 8
    },
    alertIcon: {
        alignItems: 'center'
    },
    infoCustomInformation: {
        border: '1px solid #FF9800',
        color: 'white',
        background: '#FF9800',
        marginRight: 5
    },
    editCustomInformation: {
        border: '1px solid'
    },
    deleteCustomInformation: {
        cursor: 'pointer',
        marginLeft: 8
    },
    marginEdit: {
        textDecoration: 'underline',
        color: '#E6592F',
        cursor: 'pointer'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    lowerCase: {
        textTransform: "capitalize"
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    hideProduct: {
        backgroundColor: '#eeee',
        border: "solid 2px #E6592F",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    inQuotation: {
        border: "solid 2px #43D67D",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    isOption: {
        border: "solid 2px #eeee",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    progress: {
        color: green[500],
        position: 'absolute',
        top: "16%",
        left: "43%",
        marginLeft: -12
    },
    checkboxContainer: {
        position: 'relative',
        marginTop: '-5px'
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    cancelConditionAlert: {
        "whiteSpace": 'pre-wrap',
        "&& .MuiAlert-action": {
            minWidth: 140
        }
    },
    resortFees: {
        margin: '15px 0 0 15px'
    },
    editTime: {
        "cursor": 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}));
