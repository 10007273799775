import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Divider,
    Grid,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import { green } from "@mui/material/colors";

import { Flight } from "../../Itinerary/network/flight";
import { CartProductIconText } from "../../CartMaterial/CartProductIconText";
import { PeopleOutlineOutlined } from "@mui/icons-material";
import { Traveler } from "../../../Reducers/objects/Traveler";
import { CartProductCardProviderLogo } from "../../CartMaterial/CartProductCardProviderLogo";
import DetailedPriceButton from "./DetailedPriceButton.js";
import ImportantInfo from "./ImportantInfo.js";
import FlightOutboundItem from "./FlightOutboundItem";
import { AppState } from "../../../Reducers/Reducers";

export type FlightProductCardProps = {
    direction?: 'horizontal' | 'vertical',
} & ({
    type: 'flight',
    flight: Flight,
    searched_group: any,
    provider: any,
    selecting: boolean,
    setSelecting:any
    flight_index: number,
    selected_flight: boolean
})

const FlightProductCard = React.memo(function (props: FlightProductCardProps): JSX.Element {
    const { t } = useTranslation();
    const { flight, searched_group, provider, selecting, setSelecting, flight_index, selected_flight } = props;
    const openProductsDetails = useSelector((state: AppState) => state.cartConstruction.openProductsDetails);
    const user = useSelector((state: AppState) => state.user.user);

    const trip_currency = useSelector(store => store.trip.currency);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const theme = useTheme();
    const main_color = JSON.parse(localStorage.getItem('config') ?? '{}').main_color;
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
        setShowDetails(openProductsDetails);
    }, [openProductsDetails]);

    // useEffect(() => {
    //     if (props.alwaysOpen) {
    //         setShowDetails(true);
    //     }
    // }, [props.alwaysOpen]);
    return (
        <Grid style={{ width: '100%', marginBottom: '10px' }}>
            <Card
                elevation={0}
                style={{
                    width: '100%',
                    border: '1px solid rgba(0, 0, 0, 0.2)',
                    position: 'relative'
                }}
            >
                <Stack style={{ width: '100%' }}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: props.direction === 'vertical' ?
                                'column' :
                                'row',
                            borderBottom: showDetails ?
                                '1px solid rgba(0, 0, 0, 0.25)' :
                                undefined,
                            [theme.breakpoints.down('md')]: {
                                flexDirection: 'column'
                            }
                        }}>
                        <CardContent
                            style={{
                                width: props.direction === 'vertical' ?
                                    '100%' :
                                    '75%',
                                paddingBottom: `${theme.spacing(2)} !important`,
                                [theme.breakpoints.down('md')]: {
                                    width: '100%'
                                }
                            }}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={{ md: 8 }}
                                flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                            >
                                <Stack spacing={2} style={{ width: '100%' }}>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <CartProductIconText
                                                text={
                                                    [
                                                        t<string>(
                                                            'roadbook.adults-count',
                                                            {
                                                                count: searched_group?.travelers?.filter((traveler: Traveler) => {
                                                                    const result = window.moment.utc(tripEndDate).diff(
                                                                        window.moment.utc(traveler.birth_date),
                                                                        'years'
                                                                    );
                                                                    return result >= 18;
                                                                }).length ?? 0
                                                            }
                                                        ),
                                                        (() => {
                                                            const childrenCount = searched_group?.travelers?.filter((traveler: Traveler) => {
                                                                const result = window.moment.utc(tripEndDate).diff(
                                                                    window.moment.utc(traveler.birth_date),
                                                                    'years'
                                                                );
                                                                return result < 18;
                                                            }).length ?? 0;
                                                            return childrenCount > 0 ?
                                                                t<string>(
                                                                    'roadbook.children-count',
                                                                    { count: childrenCount }
                                                                ) :
                                                                null;
                                                        })()
                                                    ].filter((item) => item).join(', ')
                                                }
                                                startIcon={PeopleOutlineOutlined}
                                            />
                                            <Divider orientation="vertical" flexItem />
                                            {
                                                (quotation_code !== 'visiteurs' || user?.client_full.type !== 2) && 
                                                    <Typography variant="caption">
                                                        {
                                                            ['public', 'all', 'PUBLISHED'].includes(flight.fare_type) ?
                                                                t<string>('flight_groups.public_price') :
                                                                t<string>('flight_groups.private_price')
                                                        }
                                                    </Typography>
                                            }
                                            <DetailedPriceButton flight={flight}/>
                                            {
                                                flight.estimated_last_ticketing_date && (
                                                    <>
                                                        <Divider orientation="vertical" flexItem />
                                                        <Typography variant="caption">
                                                            {t<string>("cart-material.flight-last-ticketing-date")}{' '}
                                                            {
                                                                window.moment(
                                                                    flight.estimated_last_ticketing_date ??
                                                                    flight.booking_status?.last_ticketing_date ??
                                                                    undefined
                                                                ).format("L")
                                                            }
                                                        </Typography>
                                                    </>
                                                )
                                            }
                                            <Divider orientation="vertical" flexItem />
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                spacing={3}
                                                style={{ marginRight: 2 }}
                                            >
                                                <CartProductCardProviderLogo
                                                    logoUrl={
                                                        provider?.logo?.url ??
                                                        provider?.logo?.thumbnail_big ??
                                                        provider?.logo?.thumbnail_medium ??
                                                        provider?.logo?.thumbnail_little ??
                                                        null
                                                    }
                                                    name={provider?.name ?? null}
                                                    height={24}
                                                />
                                            </Stack>
                                            <Typography fontWeight="bold" textAlign="center" position="relative" component={'span'}>
                                                <Box>
                                                    {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(flight.total_price)}
                                                </Box>
                                                {
                                                    (quotation_code !== 'visiteurs' || user?.client_full.type !== 2) &&
                                                    flight.flight_source &&
                                                        <Box
                                                            style={{
                                                                ...theme.typography.caption,
                                                                position: 'absolute',
                                                                opacity: 0.4,
                                                                left: '50%',
                                                                transform: 'translateX(-50%)'
                                                            }}
                                                        >
                                                            {
                                                                flight.flight_source === 'NDC' ?
                                                                    t<string>('cart-material.cart-construction-ndc') :
                                                                    t<string>('cart-material.cart-construction-standard')
                                                            }
                                                        </Box>
                                                }
                                            </Typography>
                                            {
                                                !selected_flight &&
                                                <Stack>
                                                    <Button variant="contained" disabled={selecting || flight.error_options !== null} size="small" onClick={() => setSelecting(true)} style={{
                                                        "backgroundColor": `${main_color}`,
                                                        "position": 'relative',
                                                        "color": "white",
                                                        "padding": "6px 12px",
                                                        '&:hover': {
                                                            backgroundColor: `${main_color}CA`
                                                        }
                                                    }}> 
                                                        { t<string>("flight_search.select")}
                                                        { selecting && <CircularProgress size={20} style={{
                                                            color: green[500],
                                                            position: "absolute",
                                                            top: "25%",
                                                            left: "40%" }} /> }
                                                    </Button>
                                                </Stack>
                                            }
                                        </Stack>
                                    </Stack>
                                    <Divider style={{ marginTop: 10, marginBottom: 1.5 }} />
                                    <Box
                                        style={{
                                            "width": '100%',
                                            '& > table tr.flight-segment-details:not(:last-child)': {
                                                paddingBottom: 1,
                                                marginBottom: 2,
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.2)'
                                            }
                                        }}>
                                        <Box
                                            style={{
                                                width: '100%',
                                                textAlign: 'left'
                                                // td: {
                                                //     height: '1px !important'
                                                // }
                                            }}
                                            component="table"
                                        >
                                            <Box
                                                component="tbody"
                                            >
                                                {
                                                    flight.outbounds.map((outbound, index) => (
                                                        <FlightOutboundItem
                                                            key={index}
                                                            flight_index={flight_index}
                                                            no={index + 1}
                                                            outbound={outbound}
                                                            flight={flight}
                                                            searched_group={searched_group}
                                                        />
                                                    ))
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </Stack>
                            </Stack>
                            <Divider style={{ marginTop: 2, marginBottom: 1.5 }} />
                            <Stack
                                direction={{ md: 'row' }}
                                alignItems="center"
                                paddingRight={{ md: 5 }}
                                spacing={2}
                                style={{ width: '100%' }}
                            >
                                <Stack style={{ width: '100%' }}>
                                    {
                                        flight !== null && (
                                            <ImportantInfo flight={flight} small={true} />
                                        )
                                    }
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Box>
                </Stack>
            </Card>
        </Grid>
    );
});
export default FlightProductCard;
