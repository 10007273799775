//---- Dependencies ----//
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";

//---- Actions ----//
import { SetBigCart } from "../../../../Actions/Menu";
import axios from "axios";


export default (dispatch, id, id_version, user) => {
    let { pass_check, headers } = CheckBeforeRequest();
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    if (pass_check) {
        let request_product = [];
        request_product.push(
            axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/manual-products/`,
                headers: headers,
            })
        )
        request_product.push(
            axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/accommodation/`,
                headers: headers,
            })
        )
        request_product.push(
            axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/car/`,
                headers: headers,
            })
        )
        request_product.push(
            axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/flight/`,
                headers: headers,
            })
        )
        request_product.push(
            axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/custom-products/`,
                headers: headers,
            })
        )
        request_product.push(
            axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/transfers/`,
                headers: headers,
            })
        )
        request_product.push(
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/assistance/`,
            })
        );
        request_product.push(
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/insurance/`,
            })
        );
        axios.all([...request_product]).then(axios.spread((...responses) => {
            let available_cart = [];
            responses[0].data.map((product) => {
                if (quotation_code === 'visiteurs' && product.auto_product === null) {
                    // if (product.is_available === undefined || product.is_available === true) {
                    if (user.client_full.type === 2 || (user.client_full.type !== 2 && user.client_full.type === product.creator.client.type)) {
                        if (product.booking_status === null || (product.booking_status !== undefined && product.booking_status !== null && product.booking_status.status_booking !== "CANCELLED")) {
                            available_cart.push(product);
                        }
                        if (product.booking_status === undefined) {
                            available_cart.push(product);
                        }
                    }
                } else {
                    available_cart.push(product);
                }
            });
            for (let i = 1; i < responses.length; i++) {
                if (responses[i].config.url.includes("custom-products")) {
                    responses[i].data.map(data => {
                        if (!data.poi_type) {
                            available_cart.push(data);
                        }
                    });
                } else {
                    if (responses[i].data.results !== undefined) {
                        available_cart = available_cart.concat([...responses[i].data.results]);
                    } else {
                        available_cart = available_cart.concat([...responses[i].data]);
                    }
                }
            }
            const distantFuture = new Date(8640000000000000);
            let sort_cart = [...available_cart].sort((a, b) => {
                let dateA = a.start_date !== null ? new Date(a.start_date) : distantFuture
                let dateB = b.start_date !== null ? new Date(b.start_date) : distantFuture
                return dateA.getTime() - dateB.getTime()
            });
            dispatch(SetBigCart(sort_cart, true));
        })).catch((error) => {
            console.log('error get all product finance:', error);
        });
    }
};
