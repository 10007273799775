import React, { useContext, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Chip,
    Collapse,
    IconButton,
    Stack,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import {
    BuildCircle,
    CloudCircle,
    CloudOff,
    ErrorOutline,
    Flight,
    MoreVert
} from "@mui/icons-material";
import LightTooltip from "../Menu/MaterialTripList/utils/tooltip/lightTooltip";
import { CartConstructionProductsTableItemStatus } from "./CartConstructionProductsTableItemStatus";
import { CartConstructionProductsTableItem } from "./CartConstructionProductsTableItem";
import { CartConstructionProductsTableItemMenu } from "./CartConstructionProductsTableItemMenu";
import {
    CartConstructionProductsTableItemProviderQuotationStatus
} from "./CartConstructionProductsTableItemProviderQuotationStatus";
import {
    CartPackageItemChangeProviderQuotationStatusButtons
} from "./CartPackageItemChangeProviderQuotationStatusButtons";
import { CartProductCardProviderLogo } from "./CartProductCardProviderLogo";
import { CartConstructionProductsTableItemMargin } from "./CartConstructionProductsTableItemMargin";
import { useGetPrice } from "./utils/getPrice";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { useProductDays } from "./utils/productDays";
import { useGetFlightSellingPrice } from "./utils/getFlightSellingPrice";
import { useTripDays } from "./utils/tripDays";
import { useShowError } from "../Utils/showError";
import { useCartProductUpdate } from "./network/cartProductUpdate";
import GetCookie from "../Common/Functions/GetCookie";
import { Currency } from "../../Reducers/objects/currency";
import { AppState } from "../../Reducers/Reducers";
import { isProductPackaged } from "./utils/isProductPackaged";
import { ProviderContext } from "./utils/providerContext";

type Props = {
    item: ReturnType<typeof useCartProducts>['flights'][number]
}

export function CartConstructionProductsTableFlightsItem(props: Props): JSX.Element {
    const { t } = useTranslation();
    const user = useSelector((state: AppState) => state.user.user);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
    const [openMenu, setOpenMenu] = useState(false);
    const provider = useMemo(() => {
        return manual_providers.find((item) => {
            return item.provider.id === props.item.flight.provider;
        }) ?? providers.find((item) => {
            return item.provider.id === props.item.flight.provider;
        });
    }, [providers, manual_providers, props.item]);
    const getPrice = useGetPrice();
    const [getFlightSellingPrice] = useGetFlightSellingPrice();
    const totalCost = useMemo(() => {
        if (props.item.type === 'normal') {
            return getFlightSellingPrice(props.item.flight);
        }
        let total_cost: {
            cost: number,
            currency: Currency | undefined
        }[] = [];
        let matched = false;
        let price = getPrice(props.item.flight.prices);
        total_cost.map(currency_cost => {
            if (currency_cost.currency?.id === price.currency?.id) {
                matched = true;
                currency_cost.cost += price.cost + parseFloat(props.item.flight.taxes ?? '0');
            }
        });
        if (!matched) {
            total_cost.push({
                cost: price.cost + parseFloat(props.item.flight.taxes ?? '0'),
                currency: price.currency
            });
        }
        return total_cost;
    }, [props.item.type, props.item.flight, user, getPrice]);
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const showError = useShowError();
    const update = useCartProductUpdate({
        onError(error) {
            console.error(error);
            showError(error);
        }
    });
    const tripDays = useTripDays();
    const getDays = useProductDays();
    const startDate = window.moment.utc(props.item.flight.start_date);
    const endDate = window.moment.utc(props.item.flight.end_date);
    const days = getDays(props.item.flight.start_date, props.item.flight.end_date);
    const providerContext = useContext(ProviderContext);

    const onDeleteTravelerModificationAlert = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        const version = parseInt(GetCookie('trip_id_version') ?? '-1');
        update(
            version,
            {
                id: props.item.flight.id,
                type: props.item.type === 'normal' ?
                    'flight' :
                    'manual',
                isCustom: props.item.flight.is_custom
            },
            { traveler_modification: null }
        );
    };

    return (
        <>
            <CartConstructionProductsTableItem
                {
                    ...(
                        props.item.type === 'normal' ?
                            { type: 'flight', item: props.item.flight } :
                            { type: 'manual-flight', item: props.item.flight }
                    )
                }
            >
                <TableCell />
                <TableCell color="inherit">
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        flexWrap="nowrap"
                    >
                        <LightTooltip title={t('cart-material.car')} placement="top" arrow>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                flexWrap="nowrap"
                            >
                                <Flight />
                                <Typography
                                    variant="body2"
                                    fontWeight="bold"
                                    sx={{ whiteSpace: 'nowrap' }}
                                >
                                    {t('cart-material.flight')}
                                </Typography>
                            </Stack>
                        </LightTooltip>
                        {
                            !isProductPackaged({
                                product: props.item.flight,
                                stackInfos: trip?.stack_info ?? null,
                                connected: true
                            }) &&
                            (
                                user?.client_full?.type !== 2 ||
                                props.item.flight.creator?.client?.type === user.client_full.type
                            ) &&
                            <>
                                {
                                    props.item.type === 'normal' &&
                                    !props.item.flight.is_custom &&
                                    <Chip
                                        label={t('cart-material.cart-construction-flux')}
                                        size="small"
                                        sx={{
                                            backgroundColor: '#2ACAEA',
                                            color: '#fff'
                                        }}
                                        icon={<CloudCircle color="inherit" />}
                                    />
                                }
                                {
                                    props.item.type === 'normal' &&
                                    props.item.flight.is_custom &&
                                    <Chip
                                        size="small"
                                        label={t('cart-material.cart-construction-offline')}
                                        sx={{
                                            backgroundColor: '#6A329F',
                                            color: '#fff'
                                        }}
                                        icon={<CloudOff color="inherit" />}
                                    />
                                }
                                {
                                    props.item.type === 'manual' &&
                                    <Chip
                                        label={t('accommodation.manual')}
                                        size="small"
                                        sx={{
                                            backgroundColor: '#6A329F',
                                            color: '#fff'
                                        }}
                                        icon={<BuildCircle color="inherit" />}
                                    />
                                }
                            </>
                        }
                    </Stack>
                    <Typography
                        variant="caption"
                        component="div"
                        sx={{
                            position: 'absolute',
                            marginTop: 1,
                            opacity: 0.7
                        }}
                    >
                        <LightTooltip
                            title={
                                (!days[0] || !tripDays.includes(days[0])) ?
                                    t(
                                        'cart-material.cart-construction-incorrect-product-days',
                                        { day: tripDays.join(', ') }
                                    ) :
                                    ''
                            }
                            arrow
                        >
                            <Box
                                sx={
                                    (!days[0] || !tripDays.includes(days[0])) ?
                                        {
                                            backgroundColor: '#ff0000',
                                            color: '#fff'
                                        } :
                                        undefined
                                }
                                component="span"
                            >
                                {
                                    days[0] !== days[days.length - 1] ?
                                        t(
                                            'cart-material.cart-construction-day-from-to',
                                            { from: days[0], to: days[days.length - 1] }
                                        ) :
                                        t(
                                            'cart-material.cart-construction-day',
                                            { day: days[0] }
                                        )
                                }
                            </Box>
                        </LightTooltip>
                        {' '}-{' '}
                        {
                            (startDate.isValid() || endDate.isValid()) &&
                            t(
                                'cart-material.cart-construction-products-table-date',
                                {
                                    from: startDate.isValid() ? startDate.format('L HH:mm') : null,
                                    to: endDate.isValid() ? endDate.format('L HH:mm') : null
                                }
                            )
                        }
                        {
                            !providerContext.module &&
                            <Stack
                                display="inline-flex"
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <CartConstructionProductsTableItemProviderQuotationStatus
                                    item={props.item.flight}
                                />
                                <CartPackageItemChangeProviderQuotationStatusButtons
                                    item={{
                                        ...props.item.flight,
                                        type: props.item.type === 'manual' ?
                                            'manual-flights' :
                                            'flights',
                                        isCustom: props.item.flight.is_custom
                                    }}
                                />
                            </Stack>
                        }
                        {
                            props.item.flight.traveler_modification &&
                            <Chip
                                color="warning"
                                label={
                                    props.item.flight.traveler_modification === 'SET_IN_TRIP' ?
                                        t('cart-material.add-to-cart-traveler') :
                                        t('cart-material.add-to-option-traveler')
                                }
                                size="small"
                                icon={<ErrorOutline color="inherit" />}
                                onDelete={onDeleteTravelerModificationAlert}
                            />
                        }
                    </Typography>
                </TableCell>
                <LightTooltip
                    title={
                        <Stack direction="row">
                            <Typography
                                variant="body2"
                                sx={{ whiteSpace: 'nowrap' }}
                            >
                                {
                                    props.item.type === 'normal' ?
                                        props.item.flight.outbounds[0]?.legs[0]?.origin?.iata_city.name ??
                                        props.item.flight.outbounds[0]?.legs[0]?.origin?.iata_city.international_name ?? 
                                        props.item.flight.outbounds[0]?.legs[0]?.origin_station?.iata_city?.name ??
                                        props.item.flight.outbounds[0]?.legs[0]?.origin_station?.iata_city?.international_name :
                                        props.item.flight.flight_segment[0]?.origin_airport?.iata_city.name ??
                                        props.item.flight.flight_segment[0]?.origin_airport?.iata_city.international_name
                                }
                            </Typography>
                            <Typography>
                                ...
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ whiteSpace: 'nowrap' }}
                            >
                                {
                                    props.item.type === 'normal' ?
                                        props.item.flight.outbounds[props.item.flight.outbounds.length - 1]?.legs[
                                            (props.item.flight.outbounds[props.item.flight.outbounds.length - 1]?.legs.length ?? 0) - 1
                                        ]?.destination?.iata_city.name ?? 
                                        props.item.flight.outbounds[props.item.flight.outbounds.length - 1]?.legs[
                                            (props.item.flight.outbounds[props.item.flight.outbounds.length - 1]?.legs.length ?? 0) - 1
                                        ]?.destination?.iata_city.international_name ??
                                        props.item.flight.outbounds[props.item.flight.outbounds.length - 1]?.legs[
                                            (props.item.flight.outbounds[props.item.flight.outbounds.length - 1]?.legs.length ?? 0) - 1
                                        ]?.destination_station?.iata_city?.name ??
                                        props.item.flight.outbounds[props.item.flight.outbounds.length - 1]?.legs[
                                            (props.item.flight.outbounds[props.item.flight.outbounds.length - 1]?.legs.length ?? 0) - 1
                                        ]?.destination_station?.iata_city?.international_name :
                                        props.item.flight.flight_segment[props.item.flight.flight_segment.length - 1]?.destination_airport?.iata_city.name ??
                                        props.item.flight.flight_segment[props.item.flight.flight_segment.length - 1]?.destination_airport?.iata_city.international_name
                                }
                            </Typography>
                        </Stack>
                    }
                    componentsProps={{
                        tooltip: {
                            sx: {
                                maxWidth: 'none'
                            }
                        }
                    }}
                    placement="top"
                    arrow
                >
                    <TableCell>
                        <div className="ellipsis">
                            <Stack direction="row">
                                <Typography
                                    variant="body2"
                                    sx={{
                                        maxWidth: 50,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {
                                        props.item.type === 'normal' ?
                                            props.item.flight.outbounds[0]?.legs[0]?.origin?.iata_city.name ??
                                            props.item.flight.outbounds[0]?.legs[0]?.origin?.iata_city.international_name ??
                                            props.item.flight.outbounds[0]?.legs[0]?.origin_station?.iata_city?.name ??
                                            props.item.flight.outbounds[0]?.legs[0]?.origin_station?.iata_city?.name :
                                            props.item.flight.flight_segment[0]?.origin_airport?.iata_city.name ??
                                            props.item.flight.flight_segment[0]?.origin_airport?.iata_city.international_name
                                    }
                                </Typography>
                                <Typography>
                                    ...
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        maxWidth: 50,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {
                                        props.item.type === 'normal' ?
                                            props.item.flight.outbounds[props.item.flight.outbounds.length - 1]?.legs[
                                                (props.item.flight.outbounds[props.item.flight.outbounds.length - 1]?.legs.length ?? 0) - 1
                                            ]?.destination?.iata_city.name ?? 
                                            props.item.flight.outbounds[props.item.flight.outbounds.length - 1]?.legs[
                                                (props.item.flight.outbounds[props.item.flight.outbounds.length - 1]?.legs.length ?? 0) - 1
                                            ]?.destination?.iata_city.international_name ??
                                            props.item.flight.outbounds[props.item.flight.outbounds.length - 1]?.legs[
                                                (props.item.flight.outbounds[props.item.flight.outbounds.length - 1]?.legs.length ?? 0) - 1
                                            ]?.destination_station?.iata_city?.name ??
                                            props.item.flight.outbounds[props.item.flight.outbounds.length - 1]?.legs[
                                                (props.item.flight.outbounds[props.item.flight.outbounds.length - 1]?.legs.length ?? 0) - 1
                                            ]?.destination_station?.iata_city?.international_name :
                                            props.item.flight.flight_segment[props.item.flight.flight_segment.length - 1]?.destination_airport?.iata_city.name ??
                                            props.item.flight.flight_segment[props.item.flight.flight_segment.length - 1]?.destination_airport?.iata_city.international_name
                                    }
                                </Typography>
                            </Stack>
                        </div>
                    </TableCell>
                </LightTooltip>
                <LightTooltip
                    title={
                        t(
                            'cart-material.cart-construction-flight-segments-count',
                            {
                                count: props.item.type === 'normal' ?
                                    props.item.flight.outbounds.length :
                                    props.item.flight.flight_segment.length
                            }
                        )
                    }
                    placement="top"
                    arrow
                >
                    <TableCell>
                        <div className="ellipsis">
                            {
                                t(
                                    'cart-material.cart-construction-flight-segments-count',
                                    {
                                        count: props.item.type === 'normal' ?
                                            props.item.flight.outbounds.length :
                                            props.item.flight.flight_segment.length
                                    }
                                )
                            }
                        </div>
                    </TableCell>
                </LightTooltip>
                <TableCell>
                    <Box sx={{ textAlign: 'center' }}>
                        {
                            !isProductPackaged({
                                product: props.item.flight,
                                stackInfos: trip?.stack_info ?? null
                            }) &&
                            <CartProductCardProviderLogo
                                logoUrl={
                                    provider?.provider.logo?.url ??
                                    provider?.provider.logo?.thumbnail_big ??
                                    provider?.provider.logo?.thumbnail_medium ??
                                    provider?.provider.logo?.thumbnail_little ??
                                    null
                                }
                                name={provider?.provider.name ?? null}
                                width={48}
                                height={32}
                            />
                        }
                    </Box>
                </TableCell>
                <LightTooltip
                    title={
                        props.item.flight.booking_status?.item_reference ?
                            t(
                                'cart-material.cart-construction-reference',
                                { ref: props.item.flight.booking_status.item_reference }
                            ) :
                            ''
                    }
                    placement="top"
                    arrow
                >
                    <TableCell>
                        <div className="ellipsis">
                            {
                                props.item.flight.booking_status?.item_reference &&
                                t(
                                    'cart-material.cart-construction-reference',
                                    { ref: props.item.flight.booking_status.item_reference }
                                )
                            }
                        </div>
                    </TableCell>
                </LightTooltip>
                <TableCell sx={{ maxWidth: 'none !important', textAlign: 'center' }}>
                    {
                        !isProductPackaged({
                            product: props.item.flight,
                            stackInfos: trip?.stack_info ?? null,
                            connected: true
                        }) &&
                        <>
                            {
                                props.item.type === 'normal' ?
                                    <CartConstructionProductsTableItemStatus
                                        type="flight"
                                        item={props.item.flight}
                                        bookingProcessState={props.item.flight.booking_process_state}
                                        bookingStatus={props.item.flight.booking_status}
                                        agencyNeedToBook={props.item.flight.agency_need_to_book}
                                    /> :
                                    <CartConstructionProductsTableItemStatus
                                        type="manual"
                                        item={props.item.flight}
                                        bookingProcessState={props.item.flight.booking_process_state}
                                        bookingStatus={props.item.flight.booking_status}
                                        agencyNeedToBook={props.item.flight.agency_need_to_book}
                                    />
                            }
                        </>
                    }
                </TableCell>
                <TableCell>
                    <div className="ellipsis">
                        {
                            !isProductPackaged({
                                product: props.item.flight,
                                stackInfos: trip?.stack_info ?? null
                            }) &&
                            totalCost.map((currency_cost) => {
                                return new Intl.NumberFormat(
                                    language,
                                    {
                                        style: 'currency', 
                                        currency: currency_cost.currency?.iso_code ?? 'EUR'
                                    }
                                ).format(currency_cost.cost);
                            }).join(' + ')
                        }
                    </div>
                </TableCell>
                <TableCell>
                    <IconButton
                        ref={menuButtonRef}
                        size="small"
                        onClick={(event) => {
                            event.stopPropagation();
                            setOpenMenu(true);
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                    {
                        props.item.type === 'normal' &&
                        <CartConstructionProductsTableItemMenu
                            type="flight"
                            open={openMenu}
                            anchorEl={menuButtonRef.current}
                            value={props.item.flight}
                            onClose={() => setOpenMenu(false)}
                        />
                    }
                    {
                        props.item.type === 'manual' &&
                        <CartConstructionProductsTableItemMenu
                            type="manual"
                            open={openMenu}
                            anchorEl={menuButtonRef.current}
                            value={props.item.flight}
                            onClose={() => setOpenMenu(false)}
                        />
                    }
                </TableCell>
            </CartConstructionProductsTableItem>
            <TableRow className="cart-construction-table-item-margin-row">
                <MarginWrapper item={props.item} />
            </TableRow>
        </>
    );
}

type MarginWrapperProps = {
    item: ReturnType<typeof useCartProducts>['flights'][number]
}

function MarginWrapper(props: MarginWrapperProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <TableCell
            sx={
                seeAllProductsMargins ?
                    undefined :
                    { paddingTop: 0, paddingBottom: 0 }
            }
            colSpan={20}
        >
            <Collapse
                in={
                    seeAllProductsMargins &&
                    isProductPackaged({
                        product: props.item.flight,
                        stackInfos: trip?.stack_info ?? null
                    })
                }
                unmountOnExit
            >
                {
                    props.item.type === 'normal' &&
                    <CartConstructionProductsTableItemMargin
                        type="flight"
                        item={props.item.flight}
                    />
                }
                {
                    props.item.type === 'manual' &&
                    <CartConstructionProductsTableItemMargin
                        type="manual-flight"
                        item={props.item.flight}
                    />
                }
            </Collapse>
        </TableCell>
    );
}
