//Dependencies
import clsx from 'clsx';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, Fragment, useRef } from 'react';
//Core
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

//Icons
import Close from '@material-ui/icons/Close';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import HelpOutline from "@material-ui/icons/HelpOutline";
import HighlightOff from "@material-ui/icons/HighlightOff";
import ReportProblem from '@material-ui/icons/ReportProblem';

//Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import GetPricesId from '../Cart/Functions/Margin/GetPricesId';
//Components
import CartAssistanceNote from './CartAssistanceNote';
import CartAssistanceManualBooking from './CartAssistanceManualBooking';
import CartAssistanceManualCancellationRules from './CartAssistanceManualCancellationRules';

//Actions
import { AssistanceCart } from '../Itinerary/objects/assistanceCart';
import { ProductPrice } from '../Itinerary/objects/productPrice';
import { Currency } from '../../Reducers/objects/currency';
import { Provider } from '../Common/objects/provider';
import { StatusBooking } from '../Itinerary/objects/statusBooking';
import { AppState } from '../../Reducers/Reducers';
import { isProductPackaged } from './utils/isProductPackaged';

type Props = {
    assistance: AssistanceCart
}

export function CartAssistanceCardDetails({ assistance }: Props): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const user = useSelector((state: AppState) => state.user?.user);
    const currency = useSelector((state: AppState) => state.trip.currency);
    const travelers = useSelector((state: AppState) => state.trip.travelers);
    const total_cost = useSelector((state: AppState) => state.trip.total_cost);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const currency_list = useSelector((state: AppState) => state.base.currency_list);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
    const trip = useSelector((state: AppState) => state.trip.data_trip);

    const [showNote, setShowNote] = useState(false);
    const [showManualCancellationRules, setShowManualCancellationRules] = useState(false);
    const [showManualBooking, setShowManualBooking] = useState(false);
    const [onRequest, setOnRequest] = useState(false);
    const [catalogPrice, setCatalogPrice] = useState(false);
    const [margin, setMargin] = useState<{
        id?: number,
        ids?: {
            room_id: number,
            price_id: number
        }[],
        rate_type: 'PER' | 'FIX',
        purchase: number,
        purchase_converted: number,
        purchase_currency?: Currency,
        factor_used: string,
        value: number,
        static_value?: number,
        markup: boolean,
        percent: number,
        static_percent?: number,
        selling: number,
        selling_currency?: Currency,
        custom_rate?: boolean,
        custom_rate_type?: string
    } | null>(null);
    const [surcom, setSurcom] = useState<{
        value?: number,
        static?: number,
        purchase: number,
        selling: number,
        percent: number,
        currency: Currency | null
    } | null>(null);
    const [totalCost, setTotalCost] = useState<{
        cost: number,
        currency?: Currency
    }[]>([]);
    const [provider, setProvider] = useState<Provider | null>(null);
    const [marginValue, setMarginValue] = useState<number | null>(null);
    const [marginPercent, setMarginPercent] = useState<number | null>(null);

    const manualBookingRef = useRef<HTMLDivElement>(null);

    const showCancellationDetail = () => {
        dispatch({ type: 'CART_TOGGLE_CANCELLATION_DETAIL', payload: { room: assistance, currency: getPrice(assistance.prices).currency } });
    };

    const scrollIntoManuaBookingView = () => {
        manualBookingRef.current?.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
    };

    const onManualBooking = () => {
        setShowManualBooking(!showManualBooking);
    };

    const onUpdateAssistance = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${provider?.provider.code === "gritchen" ? 'insurance' : 'assistance'}/${assistance.id}/update_booking/`,
            data: assistance.product_type === 8 ? {
                contract_reference: assistance.contract_reference,
                trip_price: Math.round((((total_cost ?? 0) - getPrice(assistance.prices).cost) / (travelers?.length ?? 1) * assistance.group_passenger.travelers.length))
            } : undefined
        }).then(response => {
            dispatch({ type: 'CART_ASSISTANCE_EDIT_BY_ID', payload: response.data });
            enqueueSnackbar(t('cart-material.assistance-updated'), { variant: 'success' });
        }).catch(error => {
            console.log(error);
            enqueueSnackbar(t('cart-material.assistance-updated-error'), { variant: 'error' });
        });
    };

    const onAddNote = () => {
        setShowNote(!showNote);
    };

    const onDeleteNote = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${provider?.provider.code === "gritchen" ? 'insurance' : 'assistance'}/${assistance.id}/`,
            data: {
                custom_information: null
            }
        }).then(response => {
            dispatch({ type: 'CART_ASSISTANCE_EDIT_BY_ID', payload: response.data });
            enqueueSnackbar(t('cart-material.note-deleted'), { variant: 'success' });
        }).catch(error => {
            console.log(error);
            enqueueSnackbar(t('cart-material.note-not-deleted'), { variant: 'error' });
        });
    };

    const onManualCancellationRules = () => {
        setShowManualCancellationRules(!showManualCancellationRules);
    };

    const onDeleteManualCancellationRules = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${provider?.provider.code === "gritchen" ? 'insurance' : 'assistance'}/${assistance.id}/`,
            data: {
                no_refund: true,
                cancel_condition_text: null
            }
        }).then(response => {
            dispatch({ type: 'CART_ASSISTANCE_EDIT_BY_ID', payload: response.data });
            setShowManualCancellationRules(!showManualCancellationRules);
        }).catch(error => {
            console.log(error);
            setShowManualCancellationRules(!showManualCancellationRules);
        });
    };

    const priceToDisplay = (price: ProductPrice) => {
        return {
            cost: parseFloat(price.selling_price),
            currency: currency_list.find(currency => currency.id === price.selling_currency)
        };
    };

    const getPrice = (prices: ProductPrice[]) => {
        if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.is_tva) {
                    return priceToDisplay(prices[i]!);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.is_surcom) {
                    return priceToDisplay(prices[i]!);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.master_price) {
                    return priceToDisplay(prices[i]!);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.owner === user?.client) {
                    return priceToDisplay(prices[i]!);
                }
            }
        } else {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.is_tva) {
                    return priceToDisplay(prices[i]!);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.owner === user?.client) {
                    return priceToDisplay(prices[i]!);
                }
            }
        }
        return {
            cost: 0,
            currency: currency
        };
    };

    const getMargin = (
        tmp_margin: NonNullable<typeof margin>,
        tmp_surcom: NonNullable<typeof surcom>,
        prices: ProductPrice[]
    ) => {
        if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.is_surcom) {
                    tmp_surcom.purchase += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                    tmp_surcom.selling += parseFloat(prices[i]!.selling_price);
                    tmp_surcom.currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency) ?? null;
                    tmp_surcom.percent += parseFloat(prices[i]!.percentage_between);
                    break;
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.master_price) {
                    tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                    tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                    tmp_margin.factor_used = prices[i]!.factor_used;
                    tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                    tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                    tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                    tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                    tmp_margin.custom_rate = prices[i]!.custom_rate;
                    tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                    tmp_margin.id = prices[i]!.id;
                    break;
                }
            }
            if (tmp_margin.id === null) {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.owner === user?.client) {
                        tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_margin.factor_used = prices[i]!.factor_used;
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                        tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                        tmp_margin.custom_rate = prices[i]!.custom_rate;
                        tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                        tmp_margin.id = prices[i]!.id;
                        break;
                    }
                }
            }
        } else {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i]!.owner === user?.client) {
                    tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                    tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                    tmp_margin.factor_used = prices[i]!.factor_used;
                    tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                    tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                    tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                    tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                    tmp_margin.id = prices[i]!.id;
                    break;
                }
            }
        }
    };

    const confirmCustomInformationRecalc = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${provider?.provider.code === "gritchen" ? 'insurance' : 'assistance'}/${assistance.id}/`,
            data: {
                moved_with_recalc_custom_information: false
            }
        }).then(response => {
            dispatch({ type: 'CART_ASSISTANCE_EDIT_BY_ID', payload: response.data });
        }).catch(error => {
            console.log(error);
        });
    };

    const confirmCancelConditionRecalc = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${provider?.provider.code === "gritchen" ? 'insurance' : 'assistance'}/${assistance.id}/`,
            data: {
                moved_with_recalc_cancel_condition_text: false
            }
        }).then(response => {
            dispatch({ type: 'CART_ASSISTANCE_EDIT_BY_ID', payload: response.data });
        }).catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        if (marginValue && marginValue !== margin?.value) {
            let tmp_margin = margin ?
                { ...margin } :
                null;
            if (tmp_margin) {
                tmp_margin.value = marginValue;
                tmp_margin.rate_type = 'FIX';
                if (tmp_margin.markup) {
                    tmp_margin.percent = parseFloat(((tmp_margin.value / tmp_margin.purchase_converted) * 100).toFixed(2));
                } else {
                    tmp_margin.percent = parseFloat(((tmp_margin.value / tmp_margin.selling) * 100).toFixed(2));
                }
                setMarginPercent(tmp_margin.percent);
                setMargin(tmp_margin);
            }
        }
    }, [marginValue]);

    useEffect(() => {
        if (marginPercent && marginPercent !== margin?.percent) {
            let tmp_margin = margin ?
                { ...margin } :
                null;
            if (tmp_margin) {
                tmp_margin.percent = marginPercent;
                tmp_margin.rate_type = 'PER';
                if (tmp_margin.markup) {
                    tmp_margin.value = parseFloat((tmp_margin.purchase_converted * (tmp_margin.percent / 100)).toFixed(2));
                } else {
                    tmp_margin.value = parseFloat((tmp_margin.selling * (tmp_margin.percent / 100)).toFixed(2));
                }
                setMarginValue(tmp_margin.value);
                setMargin(tmp_margin);
            }
        }
    }, [marginPercent]);

    useEffect(() => {
        if (showNote) {
            const element = document.getElementById(`assistance-${assistance.id}-note`);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
        }
    });

    useEffect(() => {
        if (showManualCancellationRules) {
            const element = document.getElementById(`assistance-${assistance.id}-manual-cancellation-rules`);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
        }
    });

    useEffect(() => {
        if (showManualBooking) {
            scrollIntoManuaBookingView();
        }
    });

    useEffect(() => {
        let total_cost: {
            cost: number,
            currency?: Currency
        }[] = [];
        let on_request = false;
        let catalog_price = false;
        let tmp_margin: NonNullable<typeof margin> = {
            purchase: 0,
            purchase_converted: 0,
            selling: 0,
            value: 0,
            percent: 0,
            rate_type: 'PER',
            factor_used: '0',
            markup: false
        };
        let tmp_surcom: NonNullable<typeof surcom> = {
            purchase: 0,
            selling: 0,
            value: 0,
            percent: 0,
            currency: null
        };
        if (assistance.on_request) {
            on_request = true;
        }
        if (assistance.catalog_price) {
            catalog_price = true;
        }
        let price = getPrice(assistance.prices);
        getMargin(tmp_margin, tmp_surcom, assistance.prices);
        let matched = false;
        total_cost.map(currency_cost => {
            if (currency_cost.currency?.id === price.currency?.id) {
                matched = true;
                currency_cost.cost += price.cost;
            }
        });
        if (!matched) {
            total_cost.push({
                cost: price.cost,
                currency: price.currency
            });
        }
        let tmp_provider = null;
        for (let i = 0; i < providers.length; i++) {
            if (providers[i]!.provider.id === assistance.provider) {
                tmp_provider = providers[i];
            }
        }
        for (let i = 0; i < manual_providers.length; i++) {
            if (manual_providers[i]!.provider.id === assistance.provider) {
                tmp_provider = manual_providers[i];
            }
        }
        tmp_margin.value = parseFloat((tmp_margin.selling - tmp_margin.purchase_converted).toFixed(2));
        tmp_margin.static_value = tmp_margin.value;
        tmp_margin.markup = Math.abs(tmp_margin.percent - (100 - (tmp_margin.purchase_converted / tmp_margin.selling) * 100)) > Math.abs(tmp_margin.percent - ((tmp_margin.value / tmp_margin.purchase_converted) * 100));
        tmp_margin.static_percent = tmp_margin.percent;
        tmp_margin.static_percent = tmp_margin.percent;
        tmp_surcom.static = tmp_surcom.value;
        tmp_margin.custom_rate = tmp_margin.custom_rate;
        tmp_surcom.value = tmp_surcom.selling - tmp_surcom.purchase;
        setMargin(tmp_margin);
        setSurcom(tmp_surcom);
        setOnRequest(on_request);
        setCatalogPrice(catalog_price);
        setTotalCost(total_cost);
        setProvider(tmp_provider ?? null);
    }, [assistance]);

    return (
        <Grid container item xs direction={"column"}>
            <Grid container item xs spacing={2}>
                <Grid item xs>
                    <Grid container>
                        <Grid className={ classes.gridContent } item xs>
                            {
                                !isProductPackaged({
                                    product: assistance,
                                    stackInfos: trip?.stack_info ?? null
                                }) &&
                                <Fragment>
                                    {
                                        assistance.is_cancellable ? (
                                            <div className={ classes.containerMargin }>
                                                <CheckCircleOutline className={ classes.roomIcon }/>
                                                <Typography className={ classes.inlineBlock }>
                                                    {
                                                        (
                                                            (assistance.charges?.length ?? 0) > 0 ||
                                                            parseFloat(assistance.cancellation_amount ?? '0') > 0
                                                        ) ?
                                                            t('cart-material.cancellable-with-conditions') :
                                                            t('cart-material.cancellable')
                                                    }
                                                </Typography>
                                                {
                                                    (
                                                        (assistance.charges?.length ?? 0) > 0 ||
                                                        parseFloat(assistance.cancellation_amount ?? '0') > 0
                                                    ) &&
                                                    <HelpOutline onClick={ showCancellationDetail } className={ classes.cancellationInfo } />
                                                }
                                            </div>
                                        ) : (
                                            <div className={ classes.containerMargin }>
                                                <HighlightOff className={ classes.roomIcon }/><Typography className={ classes.inlineBlock }>{ t('cart-material.not-cancellable') }</Typography>
                                            </div>
                                        )
                                    }
                                </Fragment>
                            }
                            {
                                assistance.product_type === 8 && !!assistance.contract_description &&
                                <div className={ classes.containerMargin }>
                                    <br/>
                                    <Typography>{ assistance.contract_description }</Typography>
                                </div>
                            }
                        </Grid>
                    </Grid>
                    <Grid className={ classes.bookingContainer } item xs>
                        {
                            !isProductPackaged({
                                product: assistance,
                                stackInfos: trip?.stack_info ?? null
                            }) &&
                            <Fragment>
                                {
                                    !assistance.is_available && (provider === null || !provider.provider.custom) &&
                                    <Typography className={ clsx(classes.price, classes.unavailable) }>{ t('cart-material.product-unavailable') }</Typography>
                                }
                                {
                                    catalogPrice &&
                                    <Typography className={ clsx(classes.price, { [classes.priceUnavailable]: !assistance.is_available && (provider === null || !provider.provider.custom) }) }>
                                        {t('cart-material.catalog-price')}
                                    </Typography>
                                }
                                {
                                    assistance.booking_status?.status_booking !== StatusBooking.CONFIRMED &&
                                    onRequest &&
                                    <Typography>{ t('cart-material.on-request') }</Typography>
                                }
                                { assistance.price_change && (assistance.price_change.price_variation !== 0 || !assistance.price_change.is_available) &&
                                <Grid container justify={ 'flex-end' } alignItems={ 'center' }>
                                    {
                                        assistance.price_change.is_available &&
                                        <Grid item>
                                            { assistance.price_change.price_variation > 0 ? <ArrowUpward className={ classes.red }/> : <ArrowDownward className={ classes.green }/> }
                                        </Grid>
                                    }
                                    <Grid item>
                                        <Typography className={ assistance.price_change.price_variation > 0 || !assistance.price_change.is_available ? classes.red : classes.green }>
                                            {
                                                !assistance.price_change.is_available ?
                                                    t('cart-material.product-unavailable') :
                                                    assistance.price_change.price_variation.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                                }
                            </Fragment>
                        }
                        {
                            assistance.booking_status && assistance.booking_status.status_booking === 'CANCELLED' && assistance.cancellation_charged_amount &&
                            <Typography className={ clsx(classes.booking, classes.error) }>
                                { t('flight_search.cancel_fee') } :{' '}
                                {
                                    new Intl.NumberFormat(
                                        language,
                                        {
                                            style: 'currency',
                                            currency: currency_list.find((currency) => currency.id === assistance.prices[GetPricesId(assistance.prices, user?.client, user, true)]?.purchase_currency)?.iso_code,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }
                                    ).format(assistance.cancellation_charged_amount)}
                            </Typography>
                        }
                    </Grid>
                    {
                        assistance.product_type !== 8 && assistance.booking_status && assistance.booking_status.status_booking === 'CONFIRMED' && assistance.booking_process_state !== 'CANCEL' &&
                        <Grid className={ classes.updateAssistance } item xs={ 12 }>
                            <Alert variant={ 'filled' } severity={ 'info' } action={
                                <Fragment>
                                    <Button className={ classes.editCustomInformation } color={ 'inherit' } size={ 'small' } onClick={ onUpdateAssistance }>
                                        { t('cart-material.assistance-update') }
                                    </Button>
                                </Fragment>
                            }>
                                <AlertTitle>{ t('global.informations') } :</AlertTitle>
                                { t('cart-material.assistance-update-helper') }
                            </Alert>
                        </Grid>
                    }
                    {
                        assistance.product_type === 8 &&
                        (assistance.booking_status === null || assistance.booking_status.status_booking !== "CANCELLED") &&
                        totalCost.length > 0 &&
                        (
                            assistance.trip_price > Math.round((((total_cost ?? 0) - getPrice(assistance.prices).cost) / (travelers?.length ?? 1) * assistance.group_passenger.travelers.length)) + 1 ||
                            assistance.trip_price < Math.round((((total_cost ?? 0) - getPrice(assistance.prices).cost) / (travelers?.length ?? 1) * assistance.group_passenger.travelers.length)) - 1
                        ) &&
                        <Grid className={ classes.updateAssistance } item xs={ 12 }>
                            <Alert variant={ 'filled' } severity={ 'warning' } action={
                                <Fragment>
                                    {
                                        assistance.booking_status === null &&
                                        <Button className={ classes.editCustomInformation } color={ 'inherit' } size={ 'small' } onClick={ onUpdateAssistance }>
                                            { t('cart-material.insurance-update') }
                                        </Button>
                                    }
                                </Fragment>
                            }>
                                <AlertTitle>{ t('global.informations') } :</AlertTitle>
                                { t('cart-material.insurance-update-helper') }
                                <br/>
                                { t('cart-material.insurance-update-helper-selling-price') } : { new Intl.NumberFormat(language).format(assistance.trip_price) }
                            </Alert>
                        </Grid>
                    }
                </Grid>
                {
                    assistance.booking_status &&
                    (assistance.booking_status.status_booking === 'ERROR' || assistance.booking_status.status_booking === 'UNAVAILABLE') && assistance.booking_errors && assistance.booking_errors.length > 0 &&
                    <Grid item xs={ 12 }>
                        <Alert className={ classes.errorAlert } classes={{ icon: classes.alertIcon }} variant={ 'filled' } severity={ 'error' }>
                            <AlertTitle>{ t('cart-material.error') } :</AlertTitle>
                            { assistance.booking_errors.map(error => <Typography key={ `booking-error-${error.created_date}` }>{ window.moment.utc(error.created_date).format('DD/MM/YYYY') } : { error.detail }</Typography>) }
                        </Alert>
                    </Grid>
                }
                {
                    showManualBooking &&
                    <Grid item xs={ 12 } style={{ marginTop: 15 }} ref={manualBookingRef}>
                        <CartAssistanceManualBooking assistance={ assistance } onManualBooking={ onManualBooking }/>
                    </Grid>
                }
                {
                    showNote ? (
                        <Grid id={ `assistance-${assistance.id}-note` } item xs={ 12 }>
                            <CartAssistanceNote assistance={ assistance } onAddNote={ onAddNote } providerCode={ provider?.provider.code }/>
                        </Grid>
                    ) : assistance.custom_information && (
                        <Grid item xs={ 12 }>
                            <Alert variant={ 'filled' } severity={ 'info' } action={
                                <Fragment>
                                    {
                                        assistance.moved_with_recalc_custom_information &&
                                        <Tooltip title={t('cart-material.recalc-product-note')}>
                                            <Button
                                                startIcon={
                                                    <ReportProblem style={{ marginBottom: 2 }}/>
                                                }
                                                variant={'contained'}
                                                className={ classes.infoCustomInformation }
                                                color={ 'inherit' }
                                                size={ 'small' }
                                                onClick={confirmCustomInformationRecalc}
                                            >
                                                { t('poi.ok') }
                                            </Button>
                                        </Tooltip>
                                    }
                                    <Button className={ classes.editCustomInformation } color={ 'inherit' } size={ 'small' } onClick={ onAddNote }>
                                        { t('global.edit') }
                                    </Button>
                                    <Close className={ classes.deleteCustomInformation } onClick={ onDeleteNote }/>
                                </Fragment>
                            }>
                                <AlertTitle>{ t('cart-material.note') } :</AlertTitle>
                                { assistance.custom_information }
                            </Alert>
                        </Grid>
                    )
                }
                {
                    showManualCancellationRules ?
                        <Grid id={ `assistance-${assistance.id}-manual-cancellation-rules` } item xs={ 12 }>
                            <CartAssistanceManualCancellationRules assistance={ assistance } onManualCancellationRules={ onManualCancellationRules } providerCode={ provider?.provider.code }/>
                        </Grid> : assistance.cancel_condition_text &&
                        <Grid item xs={ 12 }>
                            <Alert variant={ 'filled' } severity={ 'info' } action={
                                <Fragment>
                                    {
                                        assistance.moved_with_recalc_cancel_condition_text &&
                                        <Tooltip title={t('cart-material.recalc-product-cancel-condition')}>
                                            <Button
                                                startIcon={<ReportProblem style={{ marginBottom: 2 }}/>}
                                                variant={'contained'}
                                                className={ classes.infoCustomInformation }
                                                color={ 'inherit' }
                                                size={ 'small' }
                                                onClick={confirmCancelConditionRecalc}
                                            >
                                                { t('poi.ok') }
                                            </Button>
                                        </Tooltip>
                                    }
                                    {
                                        //!['verdie'].includes(quotation_code) &&
                                        <Fragment>
                                            <Button className={ classes.editCustomInformation } color={ 'inherit' } size={ 'small' } onClick={ onManualCancellationRules }>
                                                { t('global.edit') }
                                            </Button>
                                            <Close className={ classes.deleteCustomInformation } onClick={ onDeleteManualCancellationRules }/>
                                        </Fragment>
                                    }
                                </Fragment>
                            }>
                                <AlertTitle>
                                    {
                                        !['verdie'].includes(quotation_code) ?
                                            t('cart-material.manual-cancellation-rules') :
                                            t('cart-material.provider-cancellation-rules')
                                    } :{' '}
                                    {
                                        assistance.no_refund ?
                                            t('cart-material.not-cancellable') :
                                            t('cart-material.cancellable-with-conditions')
                                    }
                                </AlertTitle>
                                { assistance.cancel_condition_text === t('cart-material.not-cancellable') ? '' : assistance.cancel_condition_text }
                            </Alert>
                        </Grid>
                }
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles({
    gridImage: {
        width: 250
    },
    gridContent: {
        padding: '0 15px'
    },
    gridPrice: {
        width: 100
    },
    image: {
        borderRadius: 16
    },
    selector: {
        margin: '16px 0 8px 0'
    },
    variant: {
        margin: '16px 0 8px 0'
    },
    updateQuotationStatus: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    addNote: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    manualCancellationRules: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    containerMargin: {
        marginBottom: 4
    },
    roomIcon: {
        float: 'left',
        marginRight: 4
    },
    inlineBlock: {
        display: 'inline-block'
    },
    cancellationInfo: {
        display: 'inline-block',
        verticalAlign: 'bottom',
        marginLeft: 4,
        cursor: 'pointer'
    },
    assistanceName: {
        fontSize: 20,
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    pin: {
        float: 'left',
        marginRight: 4
    },
    calendar: {
        float: 'left',
        marginRight: 4
    },
    composition: {
        float: 'left',
        marginRight: 4
    },
    service: {
        float: 'left',
        marginRight: 4
    },
    contractPack: {
        color: '#E6592F',
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    bookingContainer: {
        paddingLeft: 15
    },
    manualBookingHand: {
        float: 'left',
        marginRight: 8
    },
    price: {
        textAlign: 'right',
        fontSize: 32,
        fontWeight: 'bold'
    },
    red: {
        textAlign: 'right',
        color: 'red'
    },
    green: {
        textAlign: 'right',
        color: 'green'
    },
    needToBook: {
        color: '#4caf50',
        textAlign: 'right'
    },
    booking: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '4px 0',
        textTransform: 'uppercase'
    },
    bookingDetail: {
        textDecoration: 'underline',
        fontSize: 12,
        textAlign: 'right',
        marginBottom: 4,
        cursor: 'pointer'
    },
    booked: {
        border: '2px solid #4caf50',
        color: '#4caf50'
    },
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800'
    },
    cached: {
        float: 'left',
        marginRight: 8,
        cursor: 'pointer'
    },
    cancelled: {
        border: '2px solid black',
        color: 'black'
    },
    error: {
        border: '2px solid #f44336',
        color: '#f44336'
    },
    deleteContainer: {
        textAlign: 'right'
    },
    delete: {
        cursor: 'pointer'
    },
    prices: {
        textAlign: 'right'
    },
    errorAlert: {
        marginTop: 8
    },
    alertIcon: {
        alignItems: 'center'
    },
    infoCustomInformation: {
        border: '1px solid #FF9800',
        color: 'white',
        background: '#FF9800',
        marginRight: 5
    },
    editCustomInformation: {
        border: '1px solid'
    },
    deleteCustomInformation: {
        cursor: 'pointer',
        marginLeft: 8
    },
    marginEdit: {
        textDecoration: 'underline',
        color: '#E6592F',
        cursor: 'pointer'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    assistanceInPropertyCollapsed: {
        float: 'left',
        cursor: 'pointer',
        marginRight: 4
    },
    assistanceInPropertyIconCollapsed: {
        verticalAlign: 'text-top'
    },
    pictureContainer: {
        overflow: "hidden",
        height: 200,
        //width: 300,
        position: "relative",
        cursor: "pointer"
    },
    updateAssistance: {
        marginLeft: 16
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer',
        float: 'right'
    },
    hideProduct: {
        background: "#eeee",
        border: "solid 2px #E6592F",
        padding: 15,
        borderRadius: 10,
        marginBottom: 10
    },
    inQuotation: {
        border: "solid 2px #43D67D",
        padding: 15,
        borderRadius: 10,
        marginBottom: 10
    },
    isOption: {
        border: "solid 2px #eeee",
        padding: 15,
        borderRadius: 10,
        marginBottom: 10
    },
    progress: {
	    color: green[500],
	    position: 'absolute',
        top: "16%",
        left: "43%",
        marginLeft: -12
    },
    checkboxContainer: {
        position: 'relative',
        marginTop: '-5px'
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    unavailable: {
        color: 'red'
    },
    priceUnavailable: {
        fontSize: 20
    },
    traverlerModification: {
        borderRadius: 16,
        padding: '0px 6px'
    }
});
