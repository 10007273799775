//Dependencies
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
//Core
import Container from '@material-ui/core/Container';
import {
    Box,
    Button,
    Stack,
    Switch,
    Typography,
    styled
} from '@mui/material';
//Components
import CartCard from './CartCard';
import CartStepper from './CartStepper';
import CartQuotation from './CartQuotation';
import CartFullTripPrice from './CartFullTripPrice';
import RefreshPrice from '../RefreshPrice/RefreshPrice';
import CartCarContractDetail from './CartCarContractDetail';
import CartFlightPriceDetail from './CartFlightPriceDetail';
import CartManualProductPictures from './CartManualProductPictures';
import CartAccommodationPriceDetail from './CartAccommodationPriceDetail';
import CartAccommodationBookingDetail from './CartAccommodationBookingDetail';
import CartAccommodationCancellationDetail from './CartAccommodationCancellationDetail';
import CartAutomaticProductsModal from './CartAutomaticProductsModal';
import CartHandleVariantModal from './CartHandleVariantModal';
import PoiQuestion from "../Cart/Poi/PoiQuestion";
import CartNewPriceModal from './CartNewPriceModal';
import CartManualProductForm from "./CartManualProductForm";
import { CartScrollers } from "./CartScrollers";
import { CartConstructionHeader } from "./CartConstructionHeader";
import { LoadingBackDrop } from '../Common/LoadingBackdrop';
import { CartConstructionContents } from './CartConstructionContents';
import { CartConstructionProducts } from './CartConstructionProducts';
import { CartConstructionItineraryContentStepper } from './CartConstructionItineraryContentStepper';
import { CartConstructionItineraryContentCopyModal } from './CartConstructionItineraryContentCopyModal';
import { CartConstructionItineraryContentAggregateModal } from './CartConstructionItineraryContentAggregateModal';
import { CartConstructionContentsFormSync } from "./CartConstructionContentsFormSync";
import { CartTextsConstructionWrapper } from "./CartTextsConstructionWrapper"
import { SavingRouteBlock } from '../Common/SavingRouteBlock';
import { useItineraryTripUpdate } from '../Itinerary/network/itineraryTripUpdate';
import { useShowError } from '../Utils/showError';
import GetCookie from '../Common/Functions/GetCookie';
import {
    setFetchingTexts,
    setStep,
    toggleShowItineraryStepper
} from './redux/cartConstructionReducer';
import { setItineraryContent, setItineraryContentInputs } from '../Itinerary/redux/reducer';

const Cart = ({ route }) => {
    const versionString = GetCookie("trip_id_version");
    const version = versionString ? parseInt(versionString) : -1;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const step = useSelector(state => state.cart.step);

    const tripAllData = useSelector((state) => state.trip.all_data);
    const data_trip = useSelector(state => state.trip.data_trip);
    const stepperStep = useSelector((state) => state.cartConstruction.step);
    const itineraryContent = useSelector((state) => {
        return version ?
            state.itinerarySlice.content[version] :
            undefined;
    });
    const showItineraryStepper = useSelector((state) => state.cartConstruction.showItineraryStepper);
    const [loading, setLoading] = useState(false);
    const [openAggregateModal, setOpenAggregateModal] = useState(false);
    const [openCopyModal, setOpenCopyModal] = useState(false);
    const [stickyHeaderOffset, setStickyHeaderOffset] = useState(0);
    const [stepperNode, setStepperNode] = useState(null);
    const [actionButtonsNode, setActionButtonsNode] = useState(null);
    const onCloseAggregateModal = useCallback(() => {
        setOpenAggregateModal(false);
    });
    const showError = useShowError();
    const updateTrip = useItineraryTripUpdate({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(trip) {
            dispatch(setFetchingTexts(true));
            dispatch({
                type: 'TRIP_SET_ALL_DATA',
                payload: {
                    data: trip
                }
            });
            dispatch(
                setItineraryContentInputs({
                    version,
                    data: { state: 'loading' }
                })
            );
            dispatch(
                setItineraryContent({
                    version,
                    data: { state: 'loading' }
                })
            );
        },
        onError(error) {
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    const Stepper = useMemo(() => {
        return data_trip?.trip &&
            !data_trip.trip.itinerary_type &&
            !data_trip.trip.package_type &&
            !data_trip.trip.circuit_type &&
            quotationCode !== 'marcovasco' &&
            <CartStepper active_step={step} />;
    }, [data_trip, step, quotationCode]);
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const onChangeContentsMode = (isByDay) => {
        if (tripAllData?.id) {
            updateTrip(
                tripAllData.id,
                { is_by_day_view: isByDay }
            );
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <CartConstructionHeader
                actions={() => (
                    <Box
                        ref={(element) => {
                            setActionButtonsNode(element ?? null);
                        }}
                        sx={{ marginLeft: 'auto !important' }}
                    />
                )}
                onChangeStickyHeaderOffset={setStickyHeaderOffset}
            />
            <Container maxWidth="lg">
                <StepperWrapper>
                    <Stack
                        direction="row"
                        alignItems="flex-end"
                        justifyContent="space-between"
                        sx={{
                            position: 'sticky',
                            top: 70 + stickyHeaderOffset,
                            zIndex: 3,
                            marginBottom: 2,
                            backgroundColor: '#fff'
                        }}
                    >
                        {Stepper}
                        {
                            step !== 3 &&
                            <Stack direction="row" alignItems="center" spacing={2}>
                                {
                                    step === 2 &&
                                    <>
                                        {
                                            tripAllData?.is_by_day_view &&
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                onClick={() => setOpenAggregateModal(true)}
                                                sx={{ textTransform: 'none' }}
                                            >
                                                {t('shared.circuit-aggregate-steps')}
                                            </Button>
                                        }
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            onClick={() => setOpenCopyModal(true)}
                                            sx={{ textTransform: 'none' }}
                                        >
                                            {t('shared.circuit-itinerary-duplicate')}
                                        </Button>
                                    </>
                                }
                                {
                                    showItineraryStepper &&
                                    <Stack direction="row" spacing={1} alignItems="flex-end">
                                        <Typography
                                            variant="caption"
                                            sx={{ whiteSpace: 'nowrap', fontWeight: 700 }}
                                        >
                                            {t('cart-material.cart-construction-day-by-day')}
                                        </Typography>
                                        <Switch
                                            size="small"
                                            checked={!tripAllData?.is_by_day_view}
                                            onChange={(_, checked) => onChangeContentsMode(!checked)}
                                        />
                                        <Typography
                                            variant="caption"
                                            sx={{ whiteSpace: 'nowrap', fontWeight: 700 }}
                                        >
                                            {t('cart-material.cart-construction-step-by-step')}
                                        </Typography>
                                    </Stack>
                                }
                                <ShowItineraryStepperButton onClick={() => dispatch(toggleShowItineraryStepper())}>
                                    {
                                        !showItineraryStepper ?
                                            t('cart-material.cart-construction-show-itinerary') :
                                            t('cart-material.cart-construction-hide-itinerary')
                                    }
                                </ShowItineraryStepperButton>
                            </Stack>
                        }
                    </Stack>
                </StepperWrapper>
                <Box sx={{ display: step === 3 ? 'block' : 'none' }}>
                    <CartCard
                        stickedActions={(actions) => {
                            if (step === 3 && actionButtonsNode) {
                                return ReactDOM.createPortal(
                                    actions,
                                    actionButtonsNode
                                );
                            }
                            return null;
                        }}
                    />
                </Box>
                <Box sx={{ paddingTop: 2 }}>
                    {
                        showItineraryStepper &&
                        step !== 3 &&
                        <>
                            <Box
                                ref={(element) => {
                                    setStepperNode(element);
                                }}
                                sx={{
                                    position: 'sticky',
                                    top: 70 + stickyHeaderOffset,
                                    backgroundColor: '#fff',
                                    zIndex: 2
                                }}
                            >
                                <CartConstructionItineraryContentStepper
                                    orientation="horizontal"
                                    step={stepperStep}
                                    steps={
                                        itineraryContent?.state === 'success' ?
                                            itineraryContent.data.content.map((item) => {
                                                return {
                                                    mode: itineraryContent.data.mode,
                                                    content: item
                                                };
                                            }) :
                                            []
                                    }
                                    setStep={(step) => dispatch(setStep(step))}
                                    alternativeLabel
                                    showStatus
                                />
                            </Box>
                        </>
                    }
                    <ContentWrapper
                        stepperOffset={(stepperNode?.getBoundingClientRect().height ?? 0)}
                        stickyHeaderOffset={stickyHeaderOffset}
                        stepper={Stepper}
                        actionButtonsNode={actionButtonsNode}
                    />
                </Box>
                <CartFullTripPrice />
                <CartAccommodationPriceDetail />
                <CartAccommodationBookingDetail />
                <CartAccommodationCancellationDetail />
                <CartCarContractDetail />
                <CartFlightPriceDetail />
                <CartManualProductPictures />
                <RefreshPrice />
                <PoiQuestion />
                <CartAutomaticProductsModal />
                <CartNewPriceModal />
                {
                    data_trip !== null && data_trip.trip !== undefined && (data_trip.trip.package_type || data_trip.trip.circuit_type) &&
                    <CartHandleVariantModal />
                }
                <CartScrollers />
            </Container>
            <CartConstructionContentsFormSync />
            {
                openCopyModal &&
                <CartConstructionItineraryContentCopyModal
                    onClose={() => setOpenCopyModal(false)}
                />
            }
            <ContentAggregateModalWrapper
                open={openAggregateModal}
                onClose={onCloseAggregateModal}
            />
            <SavingRouteBlock route={route} />
            <CartManualProductForm />
            <LoadingBackDrop open={loading} />
        </DndProvider>
    );
};

function StepperWrapper(props) {
    const step = useSelector(state => state.cart.step);
    const isSticked = useSelector((state) => {
        let page = '';

        switch (step) {
            case 1:
            case 4: {
                page = 'products';
                break;
            }
            case 2: {
                page = 'contents';
                break;
            }
        }

        return state.cartConstruction.isHeaderSticked[page];
    });

    if (isSticked) {
        return null;
    }

    return props.children;
}

const ContentWrapper = React.memo(
    function ContentWrapper(props) {
        const step = useSelector(state => state.cart.step);
        const extraStickedActions = useCallback(
            () => props.stepper,
            [props.stepper]
        );

        return (
            <>
                <Box
                    id="cart-construction-header-sticky-limiter"
                    sx={{
                        position: 'fixed',
                        top: 70 + props.stepperOffset + props.stickyHeaderOffset,
                        height: '1px',
                        zIndex: 99999999
                    }}
                />
                {
                    <Box sx={{ display: [1, 4].includes(step) ? 'block' : 'none' }}>
                        <CartConstructionProducts
                            stickedActions={(actions) => {
                                if (props.actionButtonsNode) {
                                    return ReactDOM.createPortal(
                                        actions,
                                        props.actionButtonsNode
                                    );
                                }
                                return null;
                            }}
                            extraStickedActions={extraStickedActions}
                            stickyOffset={props.stepperOffset + props.stickyHeaderOffset}
                            fullWidth
                            fullHeight
                            enableBooking={step === 4}
                            disableElevation
                        />
                    </Box>
                }
                <CartTextsConstructionWrapper>
                    <CartConstructionContents
                        stickedActions={(actions) => {
                            if (props.actionButtonsNode) {
                                return ReactDOM.createPortal(
                                    actions,
                                    props.actionButtonsNode
                                );
                            }
                            return null;
                        }}
                        extraStickedActions={extraStickedActions}
                        stickyOffset={props.stepperOffset + props.stickyHeaderOffset}
                    />
                </CartTextsConstructionWrapper>
                {
                    step === 3 &&
                    <CartQuotation />
                }
            </>
        );
    }
);

const ContentAggregateModalWrapper = React.memo(
    function ContentAggregateModalWrapper(props) {
        const versionString = GetCookie("trip_id_version");
        const version = versionString ? parseInt(versionString) : -1;
        const [inputs, setInputs] = useState([]);
        const itineraryContent = useSelector((state) => {
            return version ?
                state.itinerarySlice.content[version] :
                undefined;
        });
        const currentContent = useMemo(() => {
            return itineraryContent?.state === 'success' ?
                itineraryContent.data :
                null;
        }, [
            itineraryContent?.state,
            itineraryContent?.state === 'success' ? itineraryContent.data : null
        ]);

        useEffect(() => {
            if (currentContent?.mode === 'by-step') {
                setInputs(
                    currentContent.content.map((item) => {
                        return {
                            mode: 'by-step',
                            content: item
                        };
                    })
                );
            } else {
                setInputs(
                    currentContent?.content.map((item) => ({
                        mode: 'by-day',
                        content: item
                    })) ?? []
                );
            }
        }, [currentContent]);

        return (
            <>
                {
                    props.open &&
                    <CartConstructionItineraryContentAggregateModal
                        inputs={inputs}
                        onChangeInputs={setInputs}
                        onClose={props.onClose}
                    />
                }
            </>
        );
    }
);

const ShowItineraryStepperButton = styled('button')((props) => ({
    border: 'none',
    background: 'none',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: props.theme.typography.body2.fontSize
}));

export default Cart;
